.footer-sock {
  @include full-width;

  @media (min-width: $break-large) {
    margin-top: 6vw;
  }

  @media (min-width: $content-max) {
    margin-top: 7.5vw;
  }

  &:after {
    content: '';
    width: 100%;
    height: 140%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('../assets/img/paper-textured-black-ripped.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;

    @media (min-width: $break-medium) {
      height: 120%;
    }

    @media (min-width: $break-large) {
      height: 138%;
    }

    @media (min-width: $content-max) {
      height: 30vw;
      min-height: 700px;
    }
  }

  &__inner {
    position: relative;
    padding: 10.7rem 1.9rem 4.2rem;

    @media screen and (min-width: $break-medium) {
      display: flex;
      padding: 21.7rem 5rem 6.7rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 20rem 5rem 10.7rem;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__header {
    margin: 0 9rem 3rem 0;
    color: $color-white;

    @media screen and (max-width: $break-medium - 1) {
      position: relative;
    }

    @media screen and (min-width: $break-medium) {
      margin: auto 9rem 0 0;
    }

    @media screen and (min-width: $break-large) {
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      top: 87%;
      right: -8rem;
      width: 66px;
      height: 66px;
      background-image: url('../assets/img/footer-sock-arrow-perforated.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: translateY(-50%);

      @media screen and (min-width: $break-medium) {
        top: 14rem;
        right: 23rem;
        width: 145px;
        height: 145px;
        transform: rotate(-45deg);
      }

      @media screen and (min-width: $break-large) {
        top: -7.5rem;
        right: -4rem;
        width: 145px;
        height: 145px;
      }
    }

    span {
      color: $color-orange-headline;
    }
  }

  &__ctas {
    @media screen and (min-width: $break-medium) {
      flex: 0 0 21.3rem;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 26.3rem;
    }

    .cta {
      margin: 0;
      display: block;
      position: relative;
      background-color: $color-yellow;

      @media (min-width: $break-medium) {
        padding-right: 50px;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.8rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 0.8rem;
      }

      &:hover {
        background-color: $color-orange-button;
      }

      &:focus {
        @include dark-bg-focus;
      }

      .last-word {
        span {
          position: unset;

          &::before {
            top: calc(50% - 5px);
            right: 19px;
            left: unset;
          }
        }
      }
    }
  }
}