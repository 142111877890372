.foundation-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -2;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s z-index 0s,
              0.3s opacity 0s,
              0.3s visibility 0s;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: $color-black;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.js-active {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: z-index 0.3s,
                opacity 0.3s,
                visibility 0.3s;

    &::before {
      opacity: 0.65;
    }

    .foundation-overlay {
      &__wrapper {
        transform: translateX(0);
      }
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    background-image: url('./../assets/img/wrinkle-light.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
    transform: translateX(100%);
    transition: transform 0.3s;

    @media (min-width: $break-medium) {
      background-image: url('./../assets/img/rip-overlay.png');
    }

    @media (min-width: $break-large) {
      width: 92.5%;
    }
  }

  &__inner {
    padding: 20px 20px 35px;

    @media (min-width: $break-medium) {
      padding: 30px 22px 35px 150px;
      position: relative;
    }

    @media (min-width: $break-large) {
      padding: 43px 62px 43px 161px;
    }

    @media (min-width: $content-max) {
      margin: auto;
      max-width: $content-max;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -14px 0 10px -10px;
    width: 60px;
    height: 60px;
    background-color: $color-yellow;
    border: none;
    border-radius: 100%;
    appearance: none;
    transition: background-color 0.15s;

    @media (min-width: $break-medium) {
      margin: 0;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 30px;
      left: 40px;
    }

    @media (min-width: $break-large) {
      top: 58px;
      left: 30px;
    }

    svg {
      display: block;
      width: 23px;
      height: 23px;
      position: relative;
      top: 2px;
      left: 2px;

      @media (min-width: $break-medium) {
        width: 31px;
        height: 31px;
        top: 3px;
        left: 3px;
      }
    }

    &:hover {
      background-color: $color-orange-button;
    }

    &:focus {
      @include light-bg-focus;
    }
  }

  &__eyelid {
    margin: 0 0 12px;
    font-family: $font-matrice;
    font-size: 1.2rem;
    line-height: calc(21 / 12);
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: $break-medium) {
      margin: 0 0 10px;
      font-size: 1.6rem;
      line-height: calc(41 / 16);
    }

    @media (min-width: $break-large) {
      margin: 0 0 17px;
      font-size: 2.1rem;
      line-height: calc(41 / 21);
    }
  }

  &__heading {
    margin: 0 0 13px;

    @media (min-width: $break-medium) {
      margin: 0 0 21px;
    }

    h3 {
      margin: 0;
    }
  }

  &__main {
    &-img {
      margin: 0 0 14px;
      padding: 98.25% 0 0;
      position: relative;

      @media (min-width: $break-medium) {
        margin: 3px 0 20px 15px;
        padding: 48% 0 0;
        width: 49%;
        float: right;
      }

      @media (min-width: $break-large) {
        margin: -4px 0 25px 50px;
        padding: 49% 0 0;
        width: 50%;
      }
      
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border: solid 10px $color-white;
      }
    }

    &-content {
      &--bold {
        @include body-text-intro;
      }

      a:not(.cta) {
        @include body-link--thin;
        white-space: nowrap;
      }
    }
  }
}