.home-pathways {
  margin: 0 0 50px;
  padding: 43px 0 42px;
  position: relative;

  @media (min-width: $break-medium) {
    margin: 0 0 58px;
    padding: 35px 0 70px;
  }

  @media (min-width: $break-large) {
    margin: 0 0 122px;
    padding: 106px 0 110px;
  }

  &.js-active {
    .home-pathways__heading {
      &::before {
        opacity: 1;
      }
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  &::before {
    height: 100%;
    top: 0;
    z-index: -3;
    background-color: $color-orange;
  }

  &::after {
    height: 42px;
    bottom: 0;
    z-index: -2;
    background-color: $color-orange-dark;

    @media (min-width: $break-medium) {
      height: 155px;
    }

    @media (min-width: $break-large) {
      height: 227px;
    }
  }

  &__heading {
    margin: 0 0 16px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 14px;
    }

    @media (min-width: $break-large) {
      margin: 3px 0 21px -3px;
    }

    &::before {
      content: '';
      display: block;
      width: 112px;
      height: 96px;
      position: absolute;
      top: -13px;
      right: -11px;
      background-image: url('./../assets/icons/arrow-swirly-black--loop.svg');
      background-size: cover;
      opacity: 0;
      transition: opacity 0.15s;

      @media (min-width: $break-medium) {
        width: 205px;
        height: 165px;
        top: -29px;
        right: 23px;
      }

      @media (min-width: $break-large) {
        width: 315px;
        height: 255px;
        top: -57px;
        right: 136px;
      }
    }

    &--white {
      display: block;
      color: $color-white;
    }

    h2 {
      margin: 0;

      @media (min-width: $break-large) {
        line-height: 1;
      }
    }
  }

  &__subtitle {
    @include h4;
    margin: 0 0 26px;
    padding: 0 9px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 40px;
      padding: 0 20px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 44px;
      padding: 0 17px;
    }

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 30px;
      position: absolute;
      top: -7px;
      left: -1px;
      background-image: url('./../assets/icons/parentheses-left-white.svg');
      background-size: cover;

      @media (min-width: $break-medium) {
        width: 9px;
        height: 40px;
        top: -8px;
        left: 0;
      }

      @media (min-width: $break-large) {
        width: 13px;
        height: 56px;
        top: -13px;
        left: -5px;
      }
    }

    .last-word {
      span {
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 30px;
          position: absolute;
          top: -9px;
          right: -10px;
          background-image: url('./../assets/icons/parentheses-left-white.svg');
          background-size: cover;
          transform: scaleX(-1);

          @media (min-width: $break-medium) {
            width: 9px;
            height: 40px;
            top: -11px;
            right: -16px;
          }

          @media (min-width: $break-large) {
            width: 13px;
            height: 56px;
            top: -14px;
            right: -24px;
          }
        }
      }
    }
  }

  &__wrapper {
    padding: 14px 0 30px;
    position: relative;

    @media (min-width: $break-medium) {
      padding: 28px 27px 3px;
      background-color: $color-yellow-light;
    }

    @media (min-width: $break-large) {
      display: flex;
      gap: 47px;
      padding: 44px 0px 8px;
      background-color: unset;
    }

    &::before {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: -20px;
      z-index: -1;
      background-color: $color-yellow-light;

      @media (min-width: $break-medium) {
        content: none;
      }

      @media (min-width: $break-large) {
        content: '';
        width: 110%;
        left: -50px;
        z-index: -1;
      }
    }
  }

  &__content {
    margin: 0 0 35px;

    @media (min-width: $break-large) {
      margin: 0;
      width: 90.172%;
      max-width: 300px;
    }
  }

  &__intro {
    margin: 0 0 15px;

    @media (min-width: $break-medium) {
      margin: 0 0 19px;
      padding: 0 2px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 38px;
    }

    &--large {
      @include body-text-intro;
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $break-large) {
      padding: 0 0 0 2px;
    }

    .cta {
      margin: 0 0 10px;

      @media (min-width: $break-medium) {
        margin: 0 0 13px;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__columns {
    padding: 9px 0 0;
  }

  &__item {
    display: flex;
    gap: 11px;
    margin: 0 0 20px;

    @media (min-width: $break-medium) {
      flex-direction: column;
      margin: 0 0 25px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 32px;
    }

    &.column__col {
      @media (min-width: $break-medium) {
        flex-basis: calc(50% - 18px);
      }

      @media (min-width: $break-large) {
        flex-basis: calc((100% / 3) - 25px);
      }
    }

    .column__link {
      @media (min-width: $break-large) {
        width: 111%;
      }

      &:hover {
        @media (min-width: $break-medium) {
          width: 109%;
        }

        @media (min-width: $break-large) {
          width: 117%;
        }

        & + .column__title {
          h3 {
            span {
              &::before {
                @media (min-width: $break-large) {
                  width: calc(111% + 12px);
                }
              }

              &::after {
                @media (min-width: $break-medium) {
                  right: 1px;
                }

                @media (min-width: $break-large) {
                  right: -24px;
                }
              }
            }
          }
        }
      }
    }

    &:last-of-type {
      margin: 0;
    }

    &-img {
      flex-basis: 44%;
      padding: 26.627% 0 0;
      height: 0;
      position: relative;

      @media (min-width: $break-medium) {
        flex-basis: unset;
        padding: 54.2% 0 0;
        width: 92%;
      }

      @media (min-width: $break-large) {
        padding: 62.5% 0 0;
        width: 103%;
      }

      a {
        display: block;
        padding: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &:focus {
          @include light-bg-focus;
        }
      }

      img {
        padding: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &-title {
      flex-basis: 50%;
      flex: 1;

      &.column__title { // Pathways title on MD and LG screens
        margin-bottom: 0;

        @media (max-width: ($break-medium - 1)) {
          display: none;
        }

        h3 {
          padding: 12px 58px 12px 9.5%;

          @media (min-width: $break-large) {
            padding: 18px 58px 12px 10.75%;
            min-height: 66px;
          }

          span {
            &:hover {
              &::before {
                width: calc(99% + 12px);

                @media (min-width: $break-large) {
                  width: calc(111% + 12px);
                }
              }

              &::after {
                right: 1px;

                @media (min-width: $break-large) {
                  right: -24px;
                }
              }
            }

            &::before {
              top: 3px;
              height: 87%;
              width: 99%;

              @media (min-width: $break-large) {
                top: 9px;
                height: 77%;
                width: 111%;
              }
            }

            &::after {
              width: 32px;
              top: 39%;
              right: 13px;

              @media (min-width: $break-large) {
                width: 26px;
                height: 9px;
                top: 44%;
                right: -12px;
              }
            }
          }
        }
      }

      &:not(.column__title) { // Pathways title on SM screens
        @media (min-width: $break-medium) {
          display: none;
        }

        h3 {
          margin: 4px 0 0;

          span {
            padding: 0;
            background: none;

            &:focus {
              @include light-bg-focus;
            }

            .last-word {
              span {
                @include after-arrow;
                display: inline-block;

                &::before {
                  position: relative;
                  top: 2px;
                  left: 7px;
                }
              }
            }
          }
        }
      }

      h3 {
        @include tape-callouts;

        @media (min-width: $break-large) {
          @include tape-callouts-small;
        }

        span {
          @include tape-callouts;

          @media (min-width: $break-large) {
            @include tape-callouts-small;
          }
        }
      }
    }
  }
}