/**
 * Variables
 * Contains global variables available to the theme.
 */

//
// Breakpoints
//
$break-x-large: 1201px;
$break-large: 1025px;
$break-medium: 700px;

// Content
$content-max: 1800px;

//
// Colors
//
$color-black: #000000;
$color-white: #FFFFFF;
$color-gray: #767676;
$color-gray-2: #838383;
$color-gray-light: #EFEFEF;

$color-red-alert: #ED0505;
$color-yellow-alert: #FFE600;
$color-orange-alert: #FF922D;

$color-orange: #E8692C;
$color-orange-dark: #D15418;
$color-orange-headline: #EC5B34;
$color-orange-button: #FB8C57;
$color-orange-pattern-bg: #E76A2D;
$color-yellow: #F8CC53;
$color-yellow-light: #FEF5DD;
$color-crumbled-paper-beige: #F4F3F1;

//
// Fonts
//
@font-face {
  font-family: Matrice;
  font-weight: 900;
  font-style: normal;
  src: url('./../assets/fonts/matrice-black.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 900;
  font-style: italic;
  src: url('./../assets/fonts/matrice-blackitalic.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 800;
  font-style: normal;
  src: url('./../assets/fonts/matrice-extrabold.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 800;
  font-style: italic;
  src: url('./../assets/fonts/matrice-extrabolditalic.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 700;
  font-style: normal;
  src: url('./../assets/fonts/matrice-bold.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 700;
  font-style: italic;
  src: url('./../assets/fonts/matrice-bolditalic.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 600;
  font-style: normal;
  src: url('./../assets/fonts/matrice-semibold.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 600;
  font-style: italic;
  src: url('./../assets/fonts/matrice-semibolditalic.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 400;
  font-style: normal;
  src: url('./../assets/fonts/matrice-regular.otf');
}

@font-face {
  font-family: Matrice;
  font-weight: 400;
  font-style: italic;
  src: url('./../assets/fonts/matrice-regularitalic.otf');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  src: url('./../assets/fonts/roboto-bold.ttf');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  src: url('./../assets/fonts/roboto-bolditalic.ttf');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  src: url('./../assets/fonts/roboto-medium.ttf');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  src: url('./../assets/fonts/roboto-mediumitalic.ttf');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  src: url('./../assets/fonts/roboto-regular.ttf');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  src: url('./../assets/fonts/roboto-italic.ttf');
}

/// Matrice
$font-matrice: Matrice, Helvetica, sans-serif;
/// Roboto
$font-roboto: Roboto, Helvetica, sans-serif;