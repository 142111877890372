.home-hero {
  position: relative;

  &::before {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    background-color: $color-orange;
    transform: translateX(-50%);
  }

  &__main {
    position: relative;
    padding: 107.143% 0 0;

    @media (min-width: $break-medium) {
      padding: 60.334% 0 0;
    }

    @media (min-width: $break-large) {
      padding: 56% 0 0;
    }

    &::before {
      content: '';
      display: block;
      width: 100vw;
      height: 24%;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 1;
      background-image: url('./../assets/img/rip-orange-hero-home.png');
      background-size: cover;
      transform: translateX(-50%);

      @media (min-width: $break-medium) {
        height: 44%;
      }

      @media (min-width: $break-large) {
        height: 52%;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 100px;
      position: absolute;
      bottom: 0;
      left: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      transform: translateX(-50%);

      @media (min-width: $break-medium) {
        height: 150px;
      }

      @media (min-width: $break-large) {
        height: 250px;
      }
    }
  }

  &__video {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    object-fit: cover;
    transform: translateX(-50%);

    &.js-paused {
      & + .home-hero__video-toggle,
      & + * + .home-hero__video-toggle {
        svg.pause-white {
          display: none;
        }

        svg.play-white {
          display: block;
        }
      }
    }

    &-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: -1px;
      color: $color-white;
      background-color: $color-black;
      border: none;
      border-radius: 100%;
      appearance: none;
      transition: color 0.15s,
                  background-color 0.15s;

      @media (min-width: $break-medium) {
        top: 31px;
        right: -2px;
      }

      @media (min-width: $break-large) {
        top: 29px;
        right: -51px;
      }

      &:hover {
        color: $color-black;
        background-color: $color-orange-button;
      }

      &:focus {
        @include dark-bg-focus;
      }

      svg {
        display: block;
        width: 10px;
        height: 14px;

        &.play-white {
          display: none;
          width: 14px;
          position: relative;
          left: 2px;
        }
      }
    }
  }

  &__image {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    object-fit: cover;
  }

  &__title {
    width: 102%;
    position: absolute;
    bottom: -7px;
    left: 1px;
    z-index: 1;

    @media (min-width: $break-medium) {
      bottom: 10px;
    }

    @media (min-width: $break-large) {
      bottom: 5px;
      left: -7px;
    }

    h2 {
      margin: 0;
      font-size: 3.5rem;
      line-height: calc(38.5 / 35);
      font-weight: 900;
      letter-spacing: 0.02em;
      color: $color-white;

      @media (min-width: $break-medium) {
        font-size: 6rem;
        line-height: 1;
      }

      @media (min-width: $break-large) {
        font-size: 9rem;
        line-height: calc(90 / 87);
      }
    }

    &--second-line {
      @include h1;
      display: inline-block;
      padding: 15px 17px 8px 18px;
      position: relative;
      top: -8px;
      left: -14px;
      z-index: -1;
      transform: rotate(-4.35deg);

      @media (min-width: $break-medium) {
        padding: 16px 40px 15px 31px;
        left: -27px;
        transform: rotate(-3.45deg);
      }

      @media (min-width: $break-large) {
        padding: 38px 54px 17px 65px;
        top: -28px;
        left: -46px;
        font-size: 7rem;
        line-height: calc(87 / 70);
        transform: rotate(-3.55deg);
      }
      
      &::before {
        @include dark-tape-background;
        transform: rotate(-180deg);
      }
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    margin: 29px 0 0;
    position: relative;
    z-index: 1;

    @media (min-width: $break-medium) {
      width: 212px;
      margin: -63px 0 0 auto;
    }

    @media (min-width: $break-large) {
      width: 250px;
    }

    @media (min-width: $break-x-large) {
      margin: -232px -50px 0 auto;
    }

    .cta {
      margin: 0 0 8px;
      position: relative;

      @media (min-width: $break-medium) {
        margin: 0 0 5px;
        padding: 19px 50px 15px 15px;
      }

      @media (min-width: $break-large) {
        padding: 21px 50px 17px 18px;
        font-size: 1.8rem;
        line-height: 1;
      }

      &:last-of-type {
        margin: 0;
      }

      &:focus {
        @include dark-bg-focus;
      }

      .last-word {
        span {
          position: unset;

          &::before {
            top: 16px;
            right: 19px;
            left: unset;

            @media (min-width: $break-medium) {
              top: 19px;
              right: 12px;
            }

            @media (min-width: $break-large) {
              top: 23px;
              right: 17px;
            }
          }
        }
      }
    }
  }
}