.table {
  position: relative;
  margin: 2em 0;
  clear: both;

  &--scroll {
    overflow-x: auto;
    margin-right: -15px;
    margin-bottom: 4.8em;

    @media screen and (min-width: $break-medium) {
      margin: 2em 0 4.2em;
    }

    @media screen and (min-width: $break-large) {
      margin: 1.2em 0 3.6em;
    }

    &__btn {
      position: absolute;
      -webkit-appearance: none;
      background: none;
      border: 0;
      bottom: -33px;
      z-index: 1;

      > span {
       color: $color-orange;
       font-weight: 700;

        &:after {
          display: inline-block;
          margin-left: 5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 8.75px;
          border-color: transparent transparent transparent $color-black;
          content: '';

        }
      }

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
        display: none;

        > span {
          &:before {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            margin-right: 5px;
            border-width: 5px 8.7px 5px 0;
            border-color: transparent $color-black transparent transparent;
            content: '';
          }

          &:after {
            content: none;
          }
        }
      }

      &:hover {
        cursor: pointer;
      }

      @media (min-width: $break-medium) {
        display: none;
      }
    }

    &.js-scroll-left {
      margin-right: -20px;

      .left {
          display: none;
        }

      .right {
          display: block;
        }
      }

    &.js-scroll-right {
      margin-left: -20px;

      .right {
          display: none;
        }

      .left {
        display: block;
      }
    }

    &.js-scroll-right,
    &.js-scroll-left {
      .right,
      .left {
        @media (min-width: $break-medium) {
          display: none;
        }
      }
    }
  }

  table {
    tr, th {
      min-width: 200px;
    }

    th {
      text-align: left;
    }
  }
}