/**
 * @file
 * Calendar Email Event
 *
 * Contains styles for the Calendar email an event page.
 */

 .calendar-email {
   textarea {
    width: calc(100% - 100px);
    margin-left: 100px;
    float: left;
   }

   .output {
      display: block;
    }

   label,
   .label {
    clear: both;
    float: left;
    padding: 0 5px 0 0;
    width: 100px;
    text-align: right;
   }

   input, select, textarea {
    float: left;
    margin: 3px 0 5px 3px;
   }

   #capChk {
    display: block;
    float: left;
    margin: 0 0 0 5px;
   }
 }