/**
 * @file
 * Video
 *
 * Contains styles for inline video objects.
 */

.vid-inline {
  @include orange-background-bar;
  margin: 36px 0 42px;
  padding: 0 0 23px;

  @media (min-width: $break-medium) {
    margin: 48px 0 68px;
    padding: 0 0 27px;
  }

  @media (min-width: $break-large) {
    margin: 46px 0 100px;
    padding: 0 0 29px;
  }

  &__container {
    @include skewed-crumbled-paper-behind-image;
    margin: 0 0 9px;

    @media (min-width: $break-medium) {
      margin: 0 0 18px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 24px;
    }
  }

  &__cover {
    padding: 0 0 56.684%;
    position: relative;
    z-index: 2;
    opacity: 1;
    cursor: pointer;
    transition: 0.15s ease;

    @media (min-width: $break-medium) {
      padding: 0 0 56.634%;
    }

    @media (min-width: $break-large) {
      padding: 0 0 56.4289%;
    }

    &:focus {
      @include dark-bg-focus;
    }

    &:hover {
      &::after {
        background-color: $color-orange-button;
      }
    }

    &.js-loading {
      &:hover {
        &::before {
          opacity: 1;
        }
      }

      .vid-inline__cover-btn {
        opacity: 0;
      }
    }

    &.js-hide {
      display: block;
      z-index: 1;
      opacity: 0;
    }

    &:before {
      content: '';
      margin: 0;
      display: block;
      height: 60px;
      width: 60px;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns:svg=\'http://www.w3.org/2000/svg\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' version=\'1.0\' width=\'60px\' height=\'60px\' viewBox=\'0 0 128 128\' xml:space=\'preserve\'%3e%3cg%3e%3cpath d=\'M78.75 16.18V1.56a64.1 64.1 0 0 1 47.7 47.7H111.8a49.98 49.98 0 0 0-33.07-33.08zM16.43 49.25H1.8a64.1 64.1 0 0 1 47.7-47.7V16.2a49.98 49.98 0 0 0-33.07 33.07zm33.07 62.32v14.62A64.1 64.1 0 0 1 1.8 78.5h14.63a49.98 49.98 0 0 0 33.07 33.07zm62.32-33.07h14.62a64.1 64.1 0 0 1-47.7 47.7v-14.63a49.98 49.98 0 0 0 33.08-33.07z\' fill=\'%23000000\' fill-opacity=\'1\'/%3e%3canimateTransform attributeName=\'transform\' type=\'rotate\' from=\'0 64 64\' to=\'-90 64 64\' dur=\'800ms\' repeatCount=\'indefinite\'%3e%3c/animateTransform%3e%3c/g%3e%3c/svg%3e');
      background-position: center;
      background-size: contain;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.15s;
    }
    
    &-btn {
      display: block;
      margin: -22px 0 0 -21px;
      margin-top: -22px;
      margin-left: -21px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 42px;
      height: 42px;
      background-color: $color-yellow;
      border-radius: 100%;
      transition: background-color 0.15s;
      transition: opacity 0.15s;

      @media screen and (min-width: $break-medium) {
        margin: -30px 0 0 -29px;
        width: 60.22px;
        height: 60.22px;
      }

      @media screen and (min-width: $break-large) {
        margin: -40px 0 0 -40px;
        width: 80px;
        height: 80px;
      }

      svg {
        margin: -8px 0 0 -5px;
        width: 13px;
        height: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
  
        @media screen and (min-width: $break-medium) {
          margin: -8px 0 0 -6px;
          width: 17px;
          height: 18px;
        }
  
        @media screen and (min-width: $break-large) {
          margin: -11px 0 0 -8px;
          width: 21px;
          height: 22px;
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__embed {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;

    &.js-show {
      z-index: 2;
      opacity: 1;

      iframe {
        visibility: visible;
      }
    }
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 0;
    visibility: hidden;
  }

  &__caption {
    @include caption;
  }
}

// styling for pages that have a sidenav in the hero
.hero:not(.hero--no-sidenav) + .container {
  .vid-inline {
    &::before {
      @media (min-width: $break-large) {
        left: -300px;
        transform: unset;
      }

      @media (min-width: $content-max) {
        width: 200vw;
        left: -100vw;
      }
    }
  }
}