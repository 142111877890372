.home-sticky {
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s;
  z-index: 400;

  @media screen and (max-width: $break-large - 1) {
    display: none;
  }

  &__highlight {
    position: absolute;
    height: 100%;
    background-color: $color-yellow;
    z-index: 0;
    transition: width 0.15s linear, left 0.15s linear;
  }

  &__inner {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    padding: 0 46px;
    background-color: $color-black;
    color: $color-white;
    transition: top 0.5s ease;

    ul {
      display: flex;
      padding: 0;
    }

    li {
      margin: 0;

      &:before {
        content: none;
      }

      &.js-active,
      &:hover {
        a {
          color: $color-black;
        }
      }

      &:hover {
        a {
          background-color: $color-yellow;
        }
      }
    }

    a,
    p {
      font-family: $font-matrice;
      color: $color-white;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.6rem;
      margin: 0;
      padding: 0;
    }

    a {
      display: block;
      height: 100%;
      background-image: none;
      padding: 1rem 1.9rem;
      align-items: center;
    }

    p {
      position: relative;
      padding-right: 11.4%;

      &:after {
        content: "";
        position: absolute;
        top: calc(50% + 2px);
        right: 6px;
        transform: translateY(-50%);
        width: 88px;
        height: 18px;
        background-image: url('../assets/icons/arrow-straight-dashed.svg');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }


  &.js-slide {
    .home-sticky__inner {
      top: 143px;
    }
  }

  &.js-active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s;
  }
}