/**
 * @file
 * Print specific styliing
 */

@media print {
  .header,
  .footer {
    display: none;
  }
}
