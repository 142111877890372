/**
 * @file
 * News
 *
 * Contains styles for news and news view modes.
 */

 .news {
  &__title {
    font-size: 2rem;

    @media screen and (min-width: $break-medium) {
      font-size: 4rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 5.5rem;
    }
  }

  &__date {
    font-size: 1.4rem;
    font-family: $font-matrice;
    font-weight: 800;
    margin: 0 0 0.7rem;

    @media screen and (min-width: $break-medium) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $break-large) {
      margin: 0 0 1.7rem;
    }
  }

  &__image {
    @include orange-background-bar;
    @include skewed-crumbled-paper-behind-image;
    margin: 36px 0 2px;
    padding: 0 0 26px;

    @media (min-width: $break-medium) {
      margin: 48px 0 20px;
      padding: 0 0 32px;
    }

    @media (min-width: $break-large) {
      margin: 46px 0 20px;
      padding: 0 0 28px;
    }

    img {
      width: 100%;
      margin: 10px 0 0;
  
      @media (min-width: $break-medium) {
        margin: 13px 0 0;
      }
  
      @media (min-width: $break-large) {
        margin: 24px 0 0;
      }
    }
  }

  &__date {
    font-weight: 700;
  }

  &--full {
    @media screen and (min-width: $break-large) {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  // Listing
  &--listing {
    .news {
      &__list {
        margin: 0 0 25px;

        @media (min-width: $break-medium) {
          margin: 0 0 30px;
          padding: 0 0 12px;
          border-bottom: dashed 1px $color-black;
        }

        @media (min-width: $break-large) {
          padding: 0 0 42px;
        }
      }

      &__item {
        margin: 0 0 26px;

        @media (min-width: $break-medium) {
          display: flex;
          align-items: flex-start;
          gap: 23px;
          margin: 0 0 30px;
        }

        @media (min-width: $break-large) {
          gap: 30px;
          margin: 0 0 25px;
        }

        &:last-of-type {
          margin: 0;
        }
      }

      &__image {
        margin: 0 0 20px;
        padding: 75% 0 0;
        position: relative;

        @media (min-width: $break-medium) {
          margin: 0;
          padding: 36% 0 0;
          min-width: 48%;
        }

        @media (min-width: $break-large) {
          padding: 26.25% 0 0;
          min-width: 35%;
        }

        &::before {
          content: none;
        }

        a {
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;

          &:focus {
            @include light-bg-focus;
          }
        }

        img {
          margin: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }

      &__title {
        margin: 0 0 17px;

        @media (min-width: $break-medium) {
          margin: 0 0 14px;
        }

        @media (min-width: $break-large) {
          margin: 1px 0 13px;
        }

        h2 {
          @include h4;
          margin: 0;

          a {
            @include h4;
            padding: 0;
            background: none;

            &:hover {
              .last-word {
                span {
                  &::before {
                    left: 22px;

                    @media (min-width: $break-large) {
                      left: 12px;
                    }
                  }
                }
              }
            }

            &:focus {
              @include light-bg-focus;
            }
            
            .last-word {
              span {
                @include after-arrow;

                &::before {
                  top: 2px;
                  left: 10px;

                  @media (min-width: $break-medium) {
                    top: 4px;
                  }

                  @media (min-width: $break-large) {
                    top: 8px;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }

      &__date {
        @include body-text-intro;
        margin: 0;

        @media (min-width: $break-medium) {
          margin: 0 0 10px;
        }

        @media (min-width: $break-large) {
          margin: 0 0 14px;
        }
      }
    }
  }
 }