// styles for .section-nav when hero image exists
.hero-img+.hero-intro {
  .section-nav {
    position: absolute;
    top: calc(-100vw / 1.73);
    left: -20px;
    margin: 0;
    opacity: 0;

    @media screen and (min-width: $break-medium) {
      top: calc(-100vw * 0.38286 - 27px);
      left: -50px;
    }

    @media screen and (min-width: $break-large) {
      top: 0 !important;
      left: 0;
      opacity: 1;
    }
  }
}

.section-nav {
  font-family: $font-roboto;
  background-color: $color-yellow-light;
  margin: 0 -20px 3rem;
  width: 100vw;
  transition: margin-top 0.3s ease;

  @media screen and (min-width: $break-medium) {
    position: relative;
    width: 258px;
    margin: 0 0 3.4rem -50px;
  }

  @media screen and (min-width: $break-large) {
    margin: 0;
    width: 100%;
    min-width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    &:before {
      content: none;
    }
  }

  &__bttn {
    font-family: $font-roboto;
    padding: 1.2rem 5.3rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: left;
    color: $color-black;
    border: none;
    background: transparent;

    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
      padding: 1.2rem 2.2rem 1.2rem 5.3rem;
    }

    @media screen and (min-width: $break-large) {
      display: none;
    }

    span {
      position: relative;
      pointer-events: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -3.7rem;
        width: 20px;
        height: 2px;
        background-color: $color-black;
        transform: translateY(-50%);
        transition: opacity 0.3s ease;
        pointer-events: none;
      }

      &:after {
        transform: translateY(-50%)rotate(90deg);
      }
    }

    &[aria-expanded="true"] {
      span:after {
        display: none;
      }
    }
  }

  &__dropdown {
    padding: 0.9rem 20px 2.2rem;

    @media screen and (min-width: $break-medium) {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: $color-yellow-light;
      padding: 0.5rem 25px 2.2rem;
    }

    @media screen and (min-width: $break-large) {
      display: block !important;
      padding: 0.8rem 25px 2.2rem;
    }

    a {
      padding: 0;
      display: none;
      font-weight: 400;
      color: $color-black;
      background: none;
      transition: color 0.15s ease;

      &:hover {
        color: $color-orange-dark;
      }

      &:focus {
        @include light-bg-focus;
      }
    }

    li {
      margin: 0 0 0.7rem;
      line-height: 1.4;

      @media screen and (min-width: $break-medium) {
        margin: 0 0 1.4rem;
      }
    }

    > ul {
      display: block;

      &.active-trail {
        margin: 0;
      }
    }
  }

  .active-trail {
    margin-top: 1rem;

    @media screen and (min-width: $break-medium) {
      margin-top: 1.2rem;
    }

    @media screen and (min-width: $break-large) {
      margin-top: 1.5rem;
    }

    &--children {
      > li > a {
        display: block;
      }
    }
  }

  .item {
    &--back {
      display: block;

      >a {
        display: block;
        position: relative;
        font-size: 1.4rem;
        padding-left: 0.6rem;

        &:hover {
          color: $color-black;

          &::before {
            left: -15px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -5px;
          width: 4px;
          height: 4px;
          border-bottom: 1px solid $color-black;
          border-left: 1px solid $color-black;
          transform: translateY(-50%) rotate(45deg);
          transition: left 0.15s;
        }
      }
    }

    &--bold {
      margin: 0 -20px;
      padding: 0 20px 1.2rem;
      border-bottom: 3px solid $color-orange;

      @media screen and (min-width: $break-medium) {
        margin: 0 -25px 1.3rem;
        padding: 0 25px 1rem;
      }

      @media screen and (min-width: $break-large) {
        margin: 0 -25px 1.5rem;
        padding: 0 25px 1.5rem;
      }

      > a,
      > span {
        display: block;
        font-weight: 700;
      }
    }
  }
}