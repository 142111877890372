/**
 * @file
 * Pager
 *
 * Contains styles for the pager on listing pages.
 */
.pager {
  &__items {
    display: flex;
    justify-content: space-between;

    @media (min-width: $break-medium) {
      justify-content: flex-start;
    }
  }

  &__item {
    @include body-bold;
    padding: 0;
    margin: 0 10px;
    background: none;
    transition: font-weight 0.15s;

    @media (min-width: $break-medium) {
      margin: 0 12.5px;
    }

    &:not(.pager__item--active):not(.pager__item--dots):hover {
      font-weight: 400;
    }

    &--active {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: $color-yellow;
    }

    &--previous,
    &--next {
      svg {        
        display: block;
        width: 13px;
        height: 16px;
        position: relative;
        top: 8px;
      }
    }

    &--previous {
      @media (min-width: $break-medium) and (max-width: ($break-large - 1)) {
        margin-left: 0;
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    &--dots {
      & + .pager__item {
        @media (max-width: ($break-medium - 1)) {
          display: none;
        }

        & + .pager__item {
          @media (max-width: ($break-medium - 1)) {
            display: none;
          } 
        }
      }
    }

    &--disabled {
      opacity: 0.5;
    }
  }
}