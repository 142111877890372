/**
 * @file
 * Accordion
 *
 * Contains styles for accordion content object.
 */

.accordion {
  margin: 60px 0;

  @media (min-width: $break-medium) {
    margin: 54px 0 78px;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
  }

  @media (min-width: $content-max) {
    max-width: 1800px;
  }

  &__intro {
    margin: 20px 0 18px;

    @media (min-width: $break-medium) {
      margin: 19px 0 13px;
    }

    @media (min-width: $break-large) {
      margin: -6px 0 27px;
    }
  }

  &__all {
    margin: 0 0 41px;

    @media (min-width: $break-medium) {
      margin: 0 0 26px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 27px;
    }

    button {
      padding: 0 0 0 34px;
      position: relative;
      font-size: 1em;
      font-weight: 700;
      color: $color-black;
      background-color: transparent;
      border: 0;
      transition: 0.3s ease-in-out;

      &:hover {
        span {
          background-position-y: 24px;
        }
      }

      &:focus {
        @include light-bg-focus;
      }

      &.js-disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      span {
        @include body-link--thin;
        background-position-y: 20px;

        &:hover {
          background-position-y: 24px;
        }
      }
    }
  }

  // Expand all toggle
  &__expand-all {
    margin: 0 18px 0 0;
    cursor: pointer;

    @media (min-width: $break-medium) {
      margin: 0 15px 0 0;
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: $color-black;
      content: '';
      transition: 0.3s ease-in-out;
    }

    &:before {
      left: 11px;
      height: 16px;
      width: 2px;
    }

    &:after {
      left: 4px;
      height: 2px;
      width: 16px;
    }
  }

  // Collapse all toggle
  &__collapse-all {
    cursor: pointer;

    &:after {
      position: absolute;
      top: 47%;
      transform: translateY(-50%);
      background-color: $color-black;
      content: '';
      transition: 0.3s ease-in-out;
    }

    &:after {
      left: 4px;
      height: 2px;
      width: 16px;
    }
  }

  &__items {
    @include dark-tape-small;
  }

  // Accordion item
  &__item {
    margin: 0 0 10px;
    background: $color-yellow-light;

    @media (min-width: $break-large) {
      margin: 0 0 5px;
    }

    &:last-child {
      margin: 0;
    }
  }

  // Item toggle
  &__toggle {
    @include body-bold;
    display: block;
    padding: 18px 13px 20px 48px;
    width: 100%;
    position: relative;
    color: $color-black;
    background-color: $color-yellow-light;
    text-align: left;
    border: 0;
    cursor: pointer;
    transition: background-color 0.15s,
                outline-offset 0.15s;

    @media (min-width: $break-medium) {
      padding: 18px 28px 18px 84px;
    }

    @media (min-width: $break-large) {
      padding: 18px 42px 18px 84px;
    }

    &:hover {
      background-color: $color-orange-button;
    }

    &:focus {
      @include light-bg-focus;
    }

    // Expanded toggle styling
    &.js-expanded {
      background-color: $color-yellow-light;

      &:focus {
        outline-offset: -6px;
      }

      .accordion__icon:before {
        height: 0;
      }
    }
  }

  &__icon {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 22px;
    left: 16px;

    @media (min-width: $break-medium) {
      top: 50%;
      left: 33px;
      transform: translateY(-50%);
    }

    // Psuedo elements to make up +/-
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: $color-black;
      content: '';
      transition: 0.3s ease-in-out;
    }

    &:before {
      left: calc(0.5em - 1.5px);
      height: 100%;
      width: 2px;
    }

    &:after {
      height: 2px;
      width: 100%;
    }
  }

  &__content {
    display: none;
    margin: 0;
    padding: 0 13px 20px 48px;
    position: relative;

    @media (min-width: $break-medium) {
      padding: 0 28px 18px 84px;
    }

    @media (min-width: $break-large) {
      padding: 0 42px 35px 84px;
    }

    .views-element-container {
      width: 100%;
    }
  }

  &__copy {
    width: 100%;

    .table--wysiwyg {
      margin: 0;
      width: 100%;

      @media (max-width: $break-medium - 1px) {
        margin: 0 1em;
        width: calc(100% + 2em);
      }
    }
  }

  &__top {
    position: relative;
    margin: 0;
    padding: 0 0 0 38px;
    font-weight: 700;
    color: $color-black;
    background: transparent;
    border: 0;

    @media (min-width: $break-medium) {
      padding: 0 0 0 36px;
    }

    @media (min-width: $break-large) {
      padding: 10px 0 0 38px;
    }

    &:hover {
      span {
        background-position-y: 25px;
      }
    }

    &:focus {
      @include light-bg-focus;
    }

    &::before {
      content: '';
      height: 12px;
      width: 12px;
      position: absolute;
      top: 50%;
      left: 4px;
      border-style: solid;
      border-width: 2px 0 0 2px;
      border-color: $color-black;
      transform: rotate(45deg) translateY(-50%);

      @media (min-width: $break-medium) {
        left: 2px;
      }

      @media (min-width: $break-large) {
        left: 4px;
      }
    }

    span {
      @include body-link--thin;
      position: relative;
      top: -2px;
      background-position-y: 21px;

      @media (min-width: $break-medium) {
        top: 0;
      }

      @media (min-width: $break-large) {
        top: -6px;
      }

      &:hover {
        background-position-y: 25px;
      }
    }
  }
}
