/**
 * @file
 * Program List
 *
 * Styles for Program List display object.
 */

.program-list {
  margin: 48px 0 0;

  @media (min-width: $break-medium) {
    margin: 60px 0 0;
  }

  @media (min-width: $break-large) {
    margin: 97px 0 0;
  }

  &__header {
    margin: 0 0 27px;

    @media (min-width: $break-large) {
      margin: 0 0 18px;
    }
  }

  &__filters {
    display: flex;
    gap: 21px;
    margin: 0 0 23px;
    padding: 0 0 0 10px;
    border-bottom: dashed 1px $color-black;

    @media (min-width: $break-medium) {
      gap: 40px;
      margin: 0 0 22px;
      padding: 0 0 0 16px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 32px;
      padding: 0 0 0 15px;
    }
  }

  &__filter {
    @include body-link--thin;
    @include body-text-intro;
    padding: 0 0 4px;
    position: relative;
    font-weight: 400;
    color: $color-black;
    background-color: transparent;
    border: none;
    appearance: none;
    background-position-y: 21px;

    @media (min-width: $break-medium) {
      padding: 0 0 8px;
      top: -2px;
      background-position-y: 22px;
    }

    @media (min-width: $break-large) {
      padding: 0 0 10px;
      top: 0;
      background-position-y: 21px;
    }

    &:hover {
      background-position-y: 25px;

      @media (min-width: $break-medium) {
        background-position-y: 26px;
      }

      @media (min-width: $break-large) {
        background-position-y: 25px;
      }
    }

    &:focus {
      @include light-bg-focus;
    }

    &.js-selected {
      font-weight: 800;
      background: none;

      @media (min-width: $break-medium) {
        top: 1px;
      }

      @media (min-width: $break-large) {
        top: 0;
      }

      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 0;
      }
    }

    &::before {
      content: '';
      display: block;
      height: 3px;
      width: calc(100% + 20px);
      position: absolute;
      bottom: -1px;
      left: 50%;
      background-color: $color-black;
      opacity: 0;
      transform: translateX(-50%);
      transition: opacity 0.15s;

      @media (min-width: $break-medium) {
        width: calc(100% + 30px);
        bottom: 0;
      }

      @media (min-width: $break-large) {
        bottom: -2px;
      }
    }

    &::after {
      transition: bottom 0.15s ease,
                  opacity 0.15s;
    }
  }

  &__intro {
    margin: 0 0 78px;

    @media (min-width: $break-medium) {
      margin: 0 0 80px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 66px;
      padding: 0 225px 0 0;
    }

    &-content {
      display: none;

      &[aria-current="true"] {
        display: block;
      }

      *:first-child {
        margin-top: 0;
      }

      h3, 
      h4, 
      h5, 
      h6 {
        margin: 11px 0;
        
        @media (min-width: $break-large) {
          margin: 18px 0;
        }
      }

      h3 {
        @include h4;
      }

      h4 {
        @include h5;
      }

      h5 {
        @include h6;
      }
    }
  }

  &__wrapper {
    padding: 42px 0 136px;
    position: relative;

    @media (min-width: $break-medium) {
      padding: 64px 0 236px;
    }

    @media (min-width: $break-large) {
      padding: 76px 0 296px;
    }

    &::before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: -20px;
      z-index: -1;
      background-image: url('./../assets/img/wrinkle-light.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: $break-medium) {
        left: 50%;
        transform: translateX(-50%);
      }

      @media (min-width: $break-x-large) {
        height: 134%;
      }
    }
  }

  &__counter {
    position: absolute;
    top: -32px;

    @media (min-width: $break-medium) {
      top: -29px;
    }

    span {
      display: block;
      @include h3;
      padding: 18px 41px 16px 30px;
      position: relative;
      color: $color-white;

      @media (min-width: $break-medium) {
        padding: 25px 105px 16px 22px;
        font-size: 2.1rem;
        line-height: 1;
      }

      @media (min-width: $break-large) {
        padding: 25px 105px 16px 27px;
      }
      
      &::before {
        @include dark-tape-background;
        width: 108%;
        height: 106%;
        top: -2px;
        left: -9px;

        @media (min-width: $break-medium) {
          width: 100%;
          height: 110%;
          left: -28px;
        }
      }
    }
  }

  &__legend {
    margin: 0 0 18px;
    
    @media (min-width: $break-medium) {
      margin: 0;
      position: absolute;
      top: 10px;
      right: -1px;
    }

    @media (min-width: $break-large) {
      right: 1px;
    }

    p {
      display: flex;
      gap: 12px;

      @media (min-width: $break-medium) {
        gap: 15px;
      }
    }
  }

  &__columns {
    @media (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &::after {
      content: '';
      display: flex;
      flex-basis: calc((100% / 3) - 33px);
    }
  }

  &__item {
    margin: 32px 0 0;
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.15s, opacity 0.15s;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 12px);
      margin: 37px 0 0;
    }

    @media (min-width: $break-large) {
      flex-basis: calc((100% / 3) - 33px);
      margin: 52px 0 0;
    }

    &:nth-of-type(1) {
      margin: 0;
    }

    &:nth-of-type(2) {
      @media (min-width: $break-medium) {
        margin: 0;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: $break-large) {
        margin: 0;
      }
    }

    &.js-show {
      opacity: 1;
      transform: translateY(0);
    }

    &.js-hide {
      display: none;
    }
  }

  &__program {
    margin: 0 0 -7px;

    @media (min-width: $break-medium) {
      margin: 0 0 -5px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 -7px;
    }

    .cta {
      @include programs-names;
      margin: 0;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      &:hover {
        .last-word {
          span {
            &::before {
              left: 23px;
            }
          }
        }
      }

      .last-word {
        span {
          @include after-arrow;

          &::before {
            left: 11px;

            @media (min-width: $break-medium) {
              top: 5px;
            }

            @media (min-width: $break-large) {
              top: 3px;
            }
          }
        }
      }
    }
  }

  &__type {
    margin: 0 0 11px;

    @media (min-width: $break-medium) {
      margin: 0 0 9px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 12px;
    }

    span {
      margin: 0 13px 0 0;
      font-family: $font-matrice;
      font-size: 1.2rem;
      line-height: calc(28 / 12);
      font-weight: 700;
      text-transform: uppercase;

      @media (min-width: $break-medium) {
        margin: 0 12px 0 0;
      }

      &.js-deselected {
        color: $color-gray-2;
      }

      img {
        display: inline-block;
        position: relative;
        top: 6px;
      }
    }
  }

  &__image {
    padding: 77% 0 0;
    position: relative;

    @media (min-width: $break-medium) {
      padding: 77.75% 0 0;
    }

    @media (min-width: $break-large) {
      padding: 75.25% 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

.program {
  &-finder { 
    font-family: $font-roboto;

    @media screen and (min-width: $break-medium) {
      margin: 0;
    }

    @media screen and (min-width: $break-large) {
      margin: 0 -100px;
      padding: 0 30px;
    }

    @media (min-width: $break-large) {
      display: flex;
      align-items: flex-start;
    }

    &__filter {
      position: relative;

      @media screen and (max-width: $break-medium - 1) {
        margin: 0 -20px;
      }

      @media screen and (min-width: $break-medium) {
        width: 258px;
        margin-left: -50px;
      }

      @media screen and (min-width: $break-large) {
        margin-left: 0;
        width: 238px;
      }
    }

    label {
      font-weight: 700;
    }

    .results-bar__box,
    .clear-filters {
      position: sticky;
      left: 0;
      bottom: 0;
      margin-left: -24px;
      width: calc(100% + 48px);

      @media screen and (min-width: $break-large) {
        display: none;
      }

      button {
        width: 100%;
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;

        .last-word span:before {
          content: none;
        }
      }
    }
  }

  &__filters {
    &__open {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      width: 100%;
      padding: 1rem 52px;
      background-color: $color-yellow-light;
      border: none;

      @media (min-width: $break-large) {
        display: none;
      }

      &:focus {
        @include light-bg-focus;
      }

      span {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -37px;
          width: 42%;
          height: 2px;
          background-color: $color-black;
          transform: translateY(-50%);
          transition: opacity 0.3s ease;
          pointer-events: none;
        }
        
        &:after {
          transform: translateY(-50%)rotate(90deg);
        }
      }

      &[aria-expanded="true"] {
        span:after {
          content: none;
        }
      }
    }
  }
}