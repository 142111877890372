/**
 * @file
 * Generic Block: 3 Column
 *
 * Styles for Generic Block: 3 Column object.
 */

.gen3col {
  margin: 48px 0 62px;

  @media (min-width: $break-medium) {
    margin: 43px 0 54px;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
  }

  &__intro {
    @media (min-width: $break-large) {
      margin-bottom: 30px;
    }
  }

  .column {
    &__col {
      @media (min-width: $break-medium) {
        margin: 0 0 25px;
      }

      @media (min-width: $break-large) {
        margin: 0;
      }

      &:last-of-type {
        @media (min-width: $break-medium) {
          margin: 0;
        }
      }
    }

    &__img {
      @media (min-width: $break-large) {
        width: 87.25%;
      }
    }

    &__title {
      @media (min-width: $break-medium) {
        margin: -37px 0 32px;
      }

      @media (min-width: $break-large) {
        margin: -30px 0 21px;
      }

      h3 {
        @media (min-width: $break-large) {
          @include tape-callouts-small;
          padding: 17px 55px 12px 9.5%;
          min-height: 66px;
          width: 94%;
        }

        a {
          &:hover {
            &::after {
              right: -2px;
            }
          }

          &::after {
            @media (min-width: $break-large) {
              height: 9px;
              width: 31px;
              right: 10px;
            }
          }
        }
      }
    }

    &__subtitle {
      @media (min-width: $break-medium) {
        @include h6;
        margin: 0 0 8px;
      }
    }

    &__body {
      @media (min-width: $break-medium) {
        padding: 0 10px 0 0;
      }

      @media (min-width: $break-large) {
        padding: 0 5px 0 0;
      }
    }

    &__link {
      width: 104%;
      height: 102%;

      @media (min-width: $break-medium) {
        width: 100%;
      }

      @media (min-width: $break-large) {
        width: 94%;
      }

      &:hover {
        width: calc(104% + 12px);

        @media (min-width: $break-medium) {
          width: 107%;
        }

        @media (min-width: $break-large) {
          width: 104%;
        }

        & + .column__title {
          .dark-tape-link {
            span {
              &::before {
                width: calc(104% + 12px);

                @media (min-width: $break-medium) {
                  width: calc(100% + 12px);
                }
              }
            }
          }
        }
      }
    }
  }
}