.i-am-a {
  margin-bottom: 5rem;

  &__top {
    margin-bottom: 2.5rem;

    @media screen and (min-width: $break-large) {
      margin-bottom: 2.6rem;
    }

    h2 {
      @media screen and (min-width: $break-large) {
        margin: 0;
      }
    }
  }

  &__section-heading {
    @include h4;
    margin: 0 0 1.6rem;
    font-weight: 600;
    font-size: 1.2rem;

    @media screen and (min-width: $break-medium) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2.1rem;
      margin: 0 0 2.5rem;
    }
  }

  &__title {
    margin: 0 0 1.2rem;
    font-size: 1.8rem;

    @media screen and (min-width: $break-medium) {
      font-size: 3rem;
      margin: 0 0 2rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 4rem;
    }
  }

  &__img {
    display: block;
    padding: 10px;
    background: $color-white;
    margin: 0 0 1.6rem;

    @media screen and (min-width: $break-medium) {
      float: right;
      width: 260px;
      margin-left: 17px;
    }

    @media screen and (min-width: $break-large) {
      position: absolute;
      bottom: 50%;
      right: 0;
      width: 50%;
      transform: translateY(50%);
    }

    @media screen and (min-width: $content-max) {
      width: 635px;
    }
  }

  &__body {
    font-size: 1.6rem;

    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      @include clearfix;
    }

    strong {
      font-family: $font-matrice;
      font-weight: 800;
      font-size: 1.4rem;
      text-transform: uppercase;

      @media screen and (min-width: $break-medium) {
        font-size: 1.6rem;
      }
    }
  }

  &__items {
    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      flex-wrap: wrap;
    }
  }

  &__contents {
    @media screen and (min-width: $break-large) {
      position: relative;
      padding-right: 55.5%;
      max-width: 1800px;
    }
  }

  .quote {
    @media screen and (min-width: $break-medium) {
      margin: 3rem 0 0.8rem;
    }
  }

  .item {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 2.6rem;

      @media screen and (min-width: $break-medium) {
        margin-bottom: 4.6rem;
      }
    }

    @media screen and (min-width: $break-medium) {
      flex: 0 0 48%;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 29%;
      margin: 0;
    }

    &__img {
      width: 100%;
      margin-bottom: 1.3rem;

      @media screen and (min-width: $break-medium) {
        margin-bottom: 1.9rem;
      }
    }

    &__header {
      margin: 0 0 0.5rem;

      button {
        @include patternAfterArrow(14.5px, 14.5px, 14.5px);
        margin: 0;

        @media screen and (min-width: $break-medium) {
          font-size: 1.8rem;
        }

        @media screen and (min-width: $break-large) {
          font-size: 2.1rem;
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .cta__inner {
          .last-word {
            pointer-events: none;
          }
        }

        &:hover .last-word span:before {
          left: 12px;

          @media screen and (min-width: $break-medium) {
            left: 18px;
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: -1px;
    left: 0;
    width: 100vw;
    height: calc(100vh + 2px);
    background-color: rgba($color-black, 0.65);
    background-image: url('../assets/img/modal-bg-sm.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: 100000;

    @media screen and (min-width: $break-medium) {
      background-image: url('./../assets/img/rip-overlay.png');
      background-repeat: no-repeat;
      background-size: cover;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
    }

    @media screen and (min-width: $break-large) {
      background-position-x: 85px;
    }

    a:not(.cta) {
      @include body-link--thin;
      white-space: nowrap;
    }

    &__inner {
      position: relative;
      padding: 9rem 20px 1rem;

      @media screen and (min-width: $break-medium) {
        padding: 29px 40px 40px 150px;
      }

      @media screen and (min-width: $break-large) {
        padding: 44px 48px 40px 251px;
        max-width: 1800px;
        margin: auto;
      }
    }

    &__close {
      position: absolute;
      top: 1.75rem;
      left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      padding: 0;
      position: absolute;
      top: 17px;
      left: 10px;
      background-color: $color-yellow;
      border: none;
      border-radius: 100%;
      appearance: none;
      transition: background-color 0.15s;
  
      @media (min-width: $break-medium) {
        width: 80px;
        height: 80px;
        top: 30px;
        left: 40px;
      }
  
      @media (min-width: $break-large) {
        top: 13px;
        left: -131px;
      }
  
      &:hover {
        background-color: $color-orange-button;
      }
  
      &:focus {
        @include light-bg-focus;
      }
  
      svg {
        display: block;
        width: 24px;
        height: 24px;
        position: relative;
        top: 2px;
        left: 2px;
  
        @media (min-width: $break-medium) {
          width: 32px;
          height: 32px;
          top: 3px;
          left: 3px;
        }
      }
    }
  }
}