/**
 * @file
 * Generic Block: 2 Column
 *
 * Styles for Generic Block: 2 Column object.
 */

.gen2col {
  margin: 48px 0;

  @media (min-width: $break-medium) {
    margin: 43px 0;
  }

  @media (min-width: $break-large) {
    margin: 46px 0 100px;
  }

  .orange {
    color: $color-orange;
  }

  &__header {
    margin: 0 0 7px;

    @media (min-width: $break-medium) {
      margin: 0 0 8px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 17px;
    }
  }

  .column {
    &__link {
      width: 103%;
      height: 102%;

      @media (min-width: $break-medium) {
        width: 100%;
      }

      &:hover {
        width: calc(103% + 12px);

        @media (min-width: $break-medium) {
          width: calc(100% + 12px);
        }
      }
    }
  }
}