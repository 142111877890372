/**
 * @file
 * Faculty
 *
 * Contains styles for faculty detail and view modes.
 */

.faculty {
  &__title {
    @include body-text-intro;
    margin: 4px 0 0;

    @media (min-width: $break-medium) {
      margin: 14px 0 0;
    }
  }

  &__info {
    margin: 0 0 42px;
    padding: 0 0 32px;
    position: relative;

    @media (min-width: $break-medium) {
      display: flex;
      align-items: flex-end;
      gap: 22px;
      margin: 0 0 85px;
      padding: 0 0 53px;
    }

    @media (min-width: $break-large) {
      gap: 26px;
      margin: 0 0 99px;
      padding: 0 0 48px;
    }

    &::before {
      content: '';
      display: block;
      width: 100vw;
      height: 120%;
      position: absolute;
      bottom: 0;
      left: -20px;
      z-index: -2;
      background-image: url('./../assets/img/wrinkle-light.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: $break-medium) {
        height: 135%;
        left: 50%;
        transform: translateX(-50%);
      }

      @media (min-width: $break-large) {
        height: 140%;
      }

      @media (min-width: $content-max) {
        height: 150%;
      }
    }
  }

  &__image {
    @include cowley-sticker;
    margin: 0 0 24px;
    padding: 100% 0 0;

    @media (min-width: $break-medium) {
      flex-basis: 48.25%;
      margin: 0;
      padding: 48.25% 0 0;
    }

    @media (min-width: $break-large) {
      flex-basis: 46.75%;
      padding: 46.75% 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__email,
  &__phone,
  &__office {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 0 0 12px;

    @media (min-width: $break-medium) {
      margin: 0 0 24px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 25px;
    }

    &:last-child {
      margin: 0;
    }

    a {
      margin-top: 10px;
      line-height: 0.9;
    }
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: $color-yellow;
    border-radius: 100%;

    @media (min-width: $break-medium) {
      width: 52px;
      height: 52px;
    }
  }

  &__email {
    &::before {
      background-image: url('./../assets/icons/mail-black.svg');
    }
  }

  &__phone {
    &::before {
      background-image: url('./../assets/icons/phone-black.svg');
    }
  }

  &__office {
    &::before {
      background-image: url('./../assets/icons/pin-black.svg');
    }
  }

  // List
  &.faculty--listing {
    .faculty {
      &__item {
        display: flex;
        align-items: flex-start;
        margin: 0 0 2em;
      }

      &__image {
        flex: 0 0 auto;
        margin-right: 2em;
      }
    }
  }

  // Directory
  &.faculty--directory {
    .faculty__list{
      padding: 0 0 2.5em;

      @media (min-width: $break-medium) {
        padding: 0 0 0.4em;
      }

      .dt-buttons {
        @media (min-width: $break-medium) {
          display: none;
        }
      }

      .filter-btn-drop {
        display: block;
        padding: 8px 52px;
        margin: 0;
        width: 100vw;
        position: relative;
        left: -20px;
        background: $color-yellow-light;
        border: none;
        appearance: none;
        text-align: left;
        color: $color-black;

        @media (min-width: $break-large) {
          display: none;
        }

        &:focus {
          @include light-bg-focus;
        }

        &.expanded {
          span {
            &::before {
              height: 2px;
              top: 11px;
              background-image: url('./../assets/icons/minus-black.svg');
            }
          }
        }

        span {
          display: block;
          position: relative;
          font-family: $font-roboto;
          font-size: 1.6rem;
          line-height: 1.5;
          font-weight: 700;

          &::before {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            position: absolute;
            top: 1px;
            left: -37px;
            background-image: url('./../assets/icons/plus-black.svg');
          }
        }
      }
    }
  }

  .heading-table {
    margin: 0 0 21px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 36px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 16px;
    }

    &::before {
      @media (min-width: $break-medium) {
        content: '';
        display: block;
        width: 100vw;
        height: calc(100% + 36px);
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        background-color: $color-white;
        transform: translateX(-50%);
      }

      @media (min-width: $break-large) {
        height: calc(100% + 16px);
      }
    }

    &-filter {
      &[aria-hidden="false"] {
        &::before {
          content: '';
        }
      }

      &::before {
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: -1;
        background-color: $color-yellow-light;
      }
    }

    .toolbar{
      position: relative;
      padding: 2px 0 0;

      @media (min-width: $break-medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @media (min-width: $break-large) {
        display: grid;
        grid-template-columns: repeat(4, calc(25% - 11px));
        gap: 11px;
        padding: 0;
      }
    }

    .clear-table {
      display: none;
    }

    .filter-box {
      line-height: 1;
      position: relative;
      margin: 0 0 13px;

      @media (min-width: $break-medium) {
        flex-basis: calc((100% / 3) - 15px);
      }

      &:nth-child(3),
      &:nth-child(4){
        display: none;
      }
    }

    .form__label {
      @include body-bold;
      display: block;
      margin: 0 0 4px;
      position: relative;
      text-transform: capitalize;

      @media (min-width: $break-medium) {
        margin: 0 0 9px;
      }
    }

    select {
      width: 100%;
    }

    .dataTables_filter {
      position: relative;
      margin: -2px 0 0;
      flex-grow: 1;

      @media (min-width: $break-medium) {
        margin: 3px 0 0;
      }
      
      @media (max-width: $break-large - 1px) {
        flex-basis: 100%;
      }

      @media (min-width: $break-large) {
        margin: 0;
      }

      label {
        @include body-bold;
      }
    }

    input[type="search"]{
      @include placeholder {
        color: $color-black;
      }
    }

    .search__wrapper {
      margin: 5px 0 0;

      @media (min-width: $break-medium) {
        margin: 9px 0 0;
      }
    }
  }

  #faculty-table_wrapper {
    position: relative;
    padding: 0 0 32px;

    @media (min-width: $break-medium) {
      padding: 0 0 4px;
    }

    @media (min-width: $break-large) {
      padding: 0 0 32px;
    }

    &::before {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      background-image: url('./../assets/img/wrinkle-light.jpg');
      background-size: contain;
      background-repeat: repeat-y;
      transform: translateX(-50%);

      @media (min-width: $break-large) {
        height: 135%;
      }
    }
  }

  .wrapper-table {
    margin: 0 0 18px;

    @media (min-width: $break-medium) {
      padding: 42px 0 0;
      position: relative;
    }

    @media (min-width: $break-large) {
      margin: 0 0 26px;
      padding: 45px 0px 0;
    }

    .table-holder {
      @media (min-width: $break-large) {
        overflow: hidden;
        margin-right: 0;
      }
    }

    table {
      width: 100% !important;
      border-collapse: collapse;
      border-spacing: 0;
      display: flex;

      @media (min-width: $break-large) {
        display: table;
        min-width: 0;
      }
    }

    th {
      @media (min-width: $break-medium) {
        padding: 0 0 18px;
        text-align: left;
      }

      @media (min-width: $break-large) {
        padding: 0 0 7px 3px;
      }

      &:last-child {
        @media (min-width: $break-large) {
          display: none;
        }
      }
    }

    td {
      padding: 0;
      display: block;
      margin: 0 0 20px;

      @media (min-width: $break-medium) {
        margin: 0 0 14px;
      }
      
      @media (min-width: $break-large) {
        display: table-cell;
        margin: 0;
        padding: 24px 20px 20px 4px;
      }

      &:last-child {
        display: none;
      }

      &:nth-of-type(4) {
        @media (min-width: $break-medium) and (max-width: ($break-large - 1)) {
          position: relative;
          top: -30px;
        }
      }
    }

    th,
    td {
      &:first-of-type {
        @media (min-width: $break-large) {
          width: 407px;
        }
      }
    }

    tbody {
      @media screen and (max-width: $break-large - 1) {
        display: block;
        width: 100%;
      }

      tr {
        @media (min-width: $break-medium) {          
          display: grid;
          grid-template-columns: 52% auto;
          grid-template-rows: auto auto;
          grid-auto-flow: column;
        }

        @media (min-width: $break-large) {
          display: table-row;
        }

        &:first-of-type {
          border-top: none;
        }
      }
    }

    thead {
      display: none;

      @media (min-width: $break-large) {
        display: table-header-group;
        background-color: transparent;
      }

      tr {
        @media (min-width: $break-large) {
          border-bottom: 2px solid $color-orange;
        }
      }
    }

    tr {
      border-bottom: 1px dashed $color-black;
      display: block;
      padding: 0 0 19px;
      margin: 0 0 26px;

      @media (min-width: $break-medium) {
        padding: 0 0 7px;
      }

      @media (min-width: $break-large) {
        display: table-row;
        padding: 0;
        margin: 0;
      }

      &:last-child{
        margin: 0;
      }
    }

    .row-section {
      display: block;
    }

    .row-label {
      @include body-bold;
      display: block;
      margin: 0;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 41%;
      max-width: 120px;

      @media (min-width: $break-large) {
        display: none;
      }
    }

    .name {
      margin: 10px 0 -2px;

      @media (min-width: $break-medium) {
        margin: 0 0 -7px;
      }

      &.cta {
        @include patternAfterArrow(14.5px, 14.5px, 14.5px);

        @media (min-width: $break-medium) {
          @include programs-names;
        }
      }

      &:not(.cta) {
        @include programs-names;
        display: inline-block;
        margin: 5px 0 4px;

        @media (min-width: $break-medium) {
          margin: 0;
          position: relative;
          top: -4px;
        }

        & + .title {
          @media (min-width: $break-medium) {
            margin: -3px 0 0;
          }
        }
      }
    }

    .title {
      display: block;
      padding: 0;
    }

    .type {
      display: none;
    }

    .email,
    .tel {
      @include body-link--thin;
    }
  }

  .footer-table {
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 0 48px;
      position: relative;
    }

    @media (min-width: $break-large) {
      padding: 0;
    }

    .table-info {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;

      @media (min-width: $break-medium) {
        flex-direction: column-reverse;
      }
    }

    .dataTables_length {
      position: relative;

      @media (min-width: $break-medium) {
        position: absolute;
        left: 0;
        bottom: 0;
      }

      @media (min-width: $break-large) {
        top: -6px;
        right: 3px;
        bottom: unset;
        left: unset;
      }
    }

    .table-length-wrap {
      display: flex;
      align-items: center;
    }

    .select__wrapper {
      margin: 0 4px 0 0;

      @media (min-width: $break-medium) {
        margin: 0 12px 0 0;
      }

      select {
        padding-right: 41px;

        @media (min-width: $break-medium) {
          padding-right: 51px;
        }

        @media (min-width: $break-large) {
          padding-right: 58px;
        }
      }
    }

    .dataTables_info {
      margin: 0 11px 0 0;

      @media (min-width: $break-medium) {
        margin: 8px 0 0 17px;
      }

      @media (min-width: $break-large) {
        margin: 1px 0 0 30px;
      }
    }
  }

  .heading-table-filter {
    display: none;
    padding: 0 0 30px;

    @media (min-width: $break-medium) {
      display: block !important;
      padding: 0;
    }
  }

  .dataTables_paginate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 21px auto 20px;
    max-width: 344px;

    @media (min-width: $break-medium) {
      max-width: 384px;
      margin: 7px 0 21px;
    }

    @media (min-width: $break-large) {
      max-width: 344px;
      margin: 0;
    }

    .ellipsis {
      margin: 4px 12px;

      @media (min-width: $break-medium) {
        margin: 0 24px 0 0;
      }

      @media (min-width: $break-large) {
        margin: 0 25px 0 0;
      }
    }

    > span {
      padding: 0 10px;

      @media (min-width: $break-medium) {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }

      a {
        @include body-bold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: none;
        padding: 0;
        margin: 0 0.5rem;
  
        &.current {
          font-weight: 400;
          background-color: $color-yellow;
          padding: 0;
        }
      }
    }

    .previous,
    .next {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      padding: 0;
      top: 0;
      font-size: 0;
      line-height: 0;
      transition: opacity 0.15s;
      background-image: none;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('./../assets/icons/chevron-right-black.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &.previous {
        &::before {
          transform: rotate(-180deg);
        }
      }

      &.next {
        left: -7px;

        @media (min-width: $break-medium) {
          left: -9px;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .table-length-wrap {
      display: flex;
    }
  }
}