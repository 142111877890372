header {
  height: 72px;

  @media (min-width: $break-medium) {
    height: 85px;
  }

  @media (min-width: $break-large) {
    height: 143px;
  }
}

.header {
  width: 100%;
  z-index: 100000;
  background-color: $color-white;
  transform: translateY(0);
  transition: background-color 0.15s,
    transform 0.5s;

  @media (min-width: $break-large) {
    position: relative;
  }

  &.js-fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  &.js-hide {
    transform: translateY(-100%);
  }

  &.js-active-nav {
    background-color: $color-yellow-light;
    position: fixed;

    &::before {
      z-index: 11;
      opacity: 1;
    }
  }

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    border-bottom: dashed 1px $color-black;
    opacity: 0;
    transition: opacity 0.15s;
  }

  &__inner {
    padding: 14px 18px 14px 17px;

    @media (min-width: $break-medium) {
      padding: 12px 43px 20px 40px;
    }

    @media (min-width: $break-large) {
      padding: 17px 49px 24px 48px;
    }

    @media (min-width: $content-max) {
      margin: auto;
      max-width: $content-max;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    a {
      display: block;
      padding: 0;
      background: none;

      &:focus {
        @include light-bg-focus;
      }
    }

    img {
      margin: 2px 0 0;
      width: 161px;

      @media (min-width: $break-medium) {
        width: 213px;
      }

      @media (min-width: $break-large) {
        margin: 6px 0 0;
      }
    }
  }

  // .header__ancillary
  &__ancillary {
    @media (min-width: $break-large) {
      display: flex;
      align-items: flex-start;
      position: relative;
    }

    &-menu {
      display: none;

      @media (min-width: $break-large) {
        display: block;
        margin: 0 27px 0 0;
        padding: 0 0 20px;
        border-bottom: dashed 1px $color-black;
      }

      .header__menu {

        // .header__ancillary-menu .header__menu-item--with-children
        &-item--with-children {
          &:hover {
            & > a,
            & > button {
              color: $color-orange-dark;

              svg {
                color: $color-black;
                transform: rotate(-90deg); 
              }
            }

            .header__menu--sub {
              z-index: 1;
              opacity: 1;
              visibility: visible;
            }
          }

          &.js-disabled {
            & > a {
              .last-word {
                span {
                  &::before {
                    transform: rotate(90deg) !important;
                  }
                }
              }
            }
          }

          & > a {
            padding: 0 6px 0 0;
            position: relative;

            &::before,
            &::after {
              content: '';
              display: block;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }

            &::before {
              height: 100%;
              z-index: 1;
            }

            &::after {
              height: 24px;
            }

            .last-word {
              span {
                margin: 0 0 0 10px;

                &::before {
                  content: '';
                  display: inline-block;
                  width: 8px;
                  height: 10px;
                  background-image: url('./../assets/icons/chevron-black.svg');
                  background-size: cover;
                  transform: rotate(90deg);
                  transition: transform 0.15s;
                }
              }
            }
          }
        }

        // .header__ancillary-menu .header__menu--sub
        &--sub {
          padding: 7px 31px 11px 20px;
          min-width: 189px;
          top: 32px;
          left: 0;

          li {
            margin: 0 0 -4px;

            &:last-child {
              margin: 0;
            }

            a {
              font-family: $font-roboto;
              font-size: 1.4rem;
              line-height: calc(16 / 14);
              font-weight: 400;
            }
          }
        }

        // .header__ancillary-menu .header__menu-focus-toggle
        &-focus-toggle {
          &:not(:first-child) {
            margin: 0 0 0 -16px;
            top: 9px;
          }

          &:first-child {
            width: auto;
            position: relative;
            font-family: $font-roboto;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: calc(16 / 14);
            color: $color-black;

            &::before {
              content: '';
              display: block;
              width: 104%;
              height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }

            svg {
              display: inline-block;
              margin: 0 0 0 7px;
              width: 8px;
              height: 10px;
              background-image: url('./../assets/icons/chevron-black.svg');
              background-size: cover;
              transform: rotate(90deg);
              transition: transform 0.15s;
            }
          }
        }

        &>li {
          margin: 0 20px 0 0;

          &:last-child {
            margin: 0;
          }

          &>a {
            font-family: $font-roboto;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: calc(16 / 14);

            &:hover {
              color: $color-orange-dark;
            }
          }
        }
      }
    }

    // .header__ancillary-btns
    &-btns {
      display: flex;
      gap: 4px;

      @media (min-width: $break-medium) {
        gap: 14px;
        margin: 9px 0 0;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 44px;
        height: 44px;
        position: relative;
        color: $color-black;
        background-color: $color-orange-button;
        border: none;
        transition: background-color 0.15s;

        &:hover {
          background-color: $color-yellow;
        }

        &:focus {
          @include light-bg-focus;
        }

        &::before {
          content: '';
          display: block;
          background-repeat: no-repeat;
          background-size: cover;
        }

        &.header__toggle--menu {
          &[aria-expanded="true"] {
            svg.hamburger-black {
              display: none;
            }

            svg.close-black {
              display: block;
            }
          }

          &::before {
            display: none;
          }

          svg.hamburger-black {
            width: 20px;
            height: 18px;
          }

          svg.close-black {
            display: none;
            width: 20px;
            height: 20px;
            position: relative;
            left: 2px;
            top: 1px;
          }
        }

        &.header__toggle--search {
          @media (min-width: $break-large) {
            top: -1px;
          }
        }
      }
    }
  }

  // .header__main
  &__main {
    display: none;

    @media (min-width: $break-large) {
      display: block;
      margin: 19px 0 0;
      padding: 0 0 0 2px;
    }

    .header__menu {

      // .header__main .header__menu-item--with-children
      &-item--with-children {
        margin: 0 30px 0 0;

        @media (min-width: $break-x-large) {
          margin: 0 55px 0 0;
        }

        &:hover {
          & > a {
            &::before {
              bottom: -6px;
              opacity: 1;
            }

            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 64px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .header__menu--sub {
            z-index: 1;
            opacity: 1;
            visibility: visible;
          }
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
          .header__menu--sub {
            right: 0;
            left: unset;
          }
        }

        &>a {
          position: relative;
          font-family: $font-matrice;
          font-size: 1.6rem;
          line-height: 1;
          font-weight: 700;
          text-transform: uppercase;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url('./../assets/icons/underline-black.svg');
            background-size: cover;
            opacity: 0;
            transition: bottom 0.15s,
              opacity 0.15s;
          }
        }
      }

      // .header__main .header__menu--sub
      &--sub {
        padding: 20px 30px 28px 29px;
        top: calc(100% + 15px);
        column-count: 2;

        li {
          margin: 0 2px 11px 0;
          min-width: 231px;

          a {
            font-family: $font-roboto;
            font-size: 1.8rem;
            line-height: 1.33;
            font-weight: 400;
          }
        }
      }

      // .header__main .header__menu-focus-toggle
      &-focus-toggle {
        margin: 0 0 0 5px;
        top: 9.5px;

        @media (min-width: $break-x-large) {
          margin: 0 0 0 10px;
        }

        &[aria-expanded="true"] {
          svg.plus-black {
            display: none;
          }

          svg.minus-black {
            display: block;
          }
        }

        svg.minus-black {
          display: none;
        }
      }
    }
  }

  &__menu {
    display: flex;
    padding: 0;

    &-item--with-children {
      position: relative;

      &.js-active {
        .header__menu--sub {
          z-index: 1;
          opacity: 1;
          visibility: visible;
        }

        .header__menu-focus-toggle {
          &:not(:first-child) {
            width: 15px;
            height: 15px;
            clip: unset;
          }
        }
      }

      &.js-disabled {
        .header__menu--sub {
          z-index: -10 !important;
          opacity: 0 !important;
          visibility: hidden !important;
        }
      }
    }

    &--sub {
      padding: 0;
      position: absolute;
      z-index: -10;
      background-color: $color-yellow-light;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0px 3px 10px rgba($color-black, 0.25);
      transition: opacity 0.15s,
                  visibility 0.15s;

      a {
        transition: color 0.15s;

        &:hover {
          color: $color-orange-dark;
        }
      }
    }

    &-focus-toggle {
      &:first-child {
        padding: 0;
        background-color: transparent;
        appearance: none;
        border: none;
      }

      &:not(:first-child) {
        @include element-invisible;
        padding: 2px; 
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $color-yellow;

        // element should only be visible on focus to assist header keyboard navigation
        &:focus {
          width: 15px;
          height: 15px;
          z-index: 1;
          clip: unset;
        }
  
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:focus {
        @include light-bg-focus;
      }
    }

    li {
      &::before {
        content: none;
      }

      a {
        padding: 0;
        background: none;

        &:focus {
          @include light-bg-focus;
        }
      }
    }
  }


  &--foundation {
    .header__logo {
      @media (max-width: ($break-medium - 1)) {
        display: flex;  
        align-items: center;
      }

      img {
        width: 149px;
        
        @media (max-width: ($break-medium - 1)) {
          margin-top: 0;
        }

        @media screen and (min-width: $break-medium) {
          width: 264px;
        }
      }
    }

    .header__ancillary-menu li:last-of-type {
      a {
        @include body-link--thin;
        font-weight: 800;
      }
    }
  }
}

.primary-nav {
  width: 100%;
  height: calc(100vh - 72px);
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 10;
  background-color: $color-yellow-light;
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: opacity 0.15s,
  visibility 0.15s;

  @media (min-width: $break-medium) {
    height: calc(100vh - 85px);
    top: 85px;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;

    .primary-nav__inner {
      opacity: 1;
      transition-delay: 0.15s;
    }
  }

  &__wrapper {
    @media (min-width: $break-large) {
      display: none;
    }
  }

  &__inner {
    padding: 20px 20px 30px;
    position: relative;
    opacity: 0;
    transition: opacity 0.15s;

    @media (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      padding: 38px 50px;
    }

    ul {
      padding: 0;

      li {
        &::before {
          content: none;
        }

        a {
          background: none;

          &:focus {
            @include light-bg-focus;
          }
        }
      }
    }
  }

  // .primary-nav__main
  &__main {
    margin: 0 0 28px;

    @media (min-width: $break-medium) {
      flex-basis: 65%;
      margin: 0;
    }

    &>li {
      margin: 0 0 15px;
      padding: 0 47px 16px 0;

      @media (min-width: $break-medium) {
        padding: 0 47px 14px 0;
      }

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -2px;
        left: 0;
        border-bottom: dashed 1px $color-black;

        @media (min-width: $break-medium) {
          bottom: -1px;
        }
      }

      & > a {
        display: block;
        padding: 12px 0 5px;
        font-family: $font-matrice;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1;
        text-transform: uppercase;

        @media (min-width: $break-medium) {
          font-size: 1.6rem;
        }
      }

      button {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 2px;
        right: 1px;
        background-color: $color-yellow;

        @media (min-width: $break-medium) {
          top: 1px;
          right: 0;
        }

        &:hover {
          background-color: $color-orange-button;
        }

        &::before {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        svg {
          width: 20px;
          height: 20px;
          
          &.minus-black {
            display: none;
          }
        }

        &[aria-expanded="true"] {
          svg.plus-black {
            display: none;
          }

          svg.minus-black {
            display: block;
          }
        }
      }

      ul {
        padding: 10px 0 0;

        @media (min-width: $break-medium) {
          padding: 5px 0 0;
        }

        li {
          margin: 0 0 5px;

          @media (min-width: $break-medium) {
            margin: 0 0 2px;
          }

          a {
            padding: 0;
            font-family: $font-roboto;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: calc(20 / 14);
          }
        }
      }
    }
  }

  // .primary-nav__ancillary
  &__ancillary {
    margin: 0;

    @media (min-width: $break-medium) {
      flex-basis: 30%;
    }

    &>li {
      margin: 0 0 6px;

      @media (min-width: $break-medium) {
        margin: 0 0 2px;
      }

      & > a,
      & > button {
        padding: 0;
        font-family: $font-roboto;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: calc(16 / 14);
        color: $color-black;
      }

      button {
        &:first-child {
          display: inline-flex;
          width: unset;
          height: unset;
          background-color: transparent;
          position: relative;

          &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }

          svg {
            margin: 0 0 0 8px;
          }
        }

        &:not(:first-child) {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 0 5px;
          padding: 0;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 2px;
          background-color: transparent !important;
          transition: margin 0.15s;
  
          @media (min-width: $break-medium) {
            top: 6px;
          }
        }

        svg {
          display: block;
          width: 10px;
          height: 12px;
          transform: rotate(90deg);
          transition: transform 0.15s;
        }

        &[aria-expanded="true"] {
          margin: 1px 0 0 3px;

          @media (min-width: $break-medium) {
            margin: 2px 0 0 3px;
          }

          svg {
            transform: rotate(-90deg);
          }
        }
      }

      ul {
        padding: 6px 0 0 20px;

        li {
          margin: 0 0 6px;

          @media (min-width: $break-medium) {
            margin: 0 0 2px;
          }

          a {
            padding: 0;
            font-family: $font-roboto;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: calc(16 / 14);
          }
        }
      }
    }
  }
}

.header-search {
  padding: 0 17px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 14px;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: z-index 0s,
    opacity 0.15s,
    0.15s visibility 0s;

  @media (min-width: $break-medium) {
    padding: 0;
    width: 40.75%;
    top: 21px;
    right: 43px;
    left: unset;
  }

  @media (min-width: $break-large) {
    width: 107.7%;
    top: 8px;
    right: 0;
  }

  &[aria-hidden="false"] {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: 0.15s z-index 0s,
      opacity 0.15s,
      0.15s visibility 0s;
  }

  &::before {
    @media (min-width: $break-large) {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      top: -10px;
      left: 0;
    }
  }

  button.header__toggle--search {
    min-width: 44px;

    @media (min-width: $break-large) {
      top: 0;
    }

    svg {
      position: relative;
      top: 2px;
      left: 2px;

      @media (min-width: $break-large) {
        left: 3px;
      }
    }
  }

  .header-search__input {
    padding-right: 49px;

    @media (min-width: $break-medium) {
      padding-right: 47px;
    }

    @include iOSSafari {
      appearance: none;
    }
  }

  button.header-search__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    background-color: transparent;

    @media (min-width: $break-medium) {
      right: -2px;
    }

    svg {
      display: block;
      width: 23px;
      height: 23px;
      background-size: cover;
    }
  }
}