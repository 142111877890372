/**
 * @file
 * Statistic: 3 up feature
 *
 * Styles for Statistick: 3 up feature object.
 */

.stat3up {
  margin: 52px 0 48px;

  @media (min-width: $break-medium) {
    margin: 95px 0 91px;
  }

  &__intro {
    margin: 20px 0;

    @media (min-width: $break-medium) {
      margin: 19px 0;
    }

    @media (min-width: $break-large) {
      margin: -5px 0 20px;
    }
  }

  &__cta.section__cta {
    margin: 0 0 56px;

    @media (min-width: $break-medium) {
      margin: 0 0 63px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 73px;
    }
  }

  &__wrapper {
    @include dark-tape-small;
    padding: 33px 20px 46px;
    background-color: $color-yellow-light;

    @media (min-width: $break-medium) {
      padding: 30px 30px 34px;
    }

    @media (min-width: $break-large) {
      padding: 30px 1px 27px;
    }

    &::after {
      @media (min-width: $break-large) {
        content: '';
        display: block;
        height: 100%;
        width: calc(100% + 58px);
        position: absolute;
        top: 0;
        left: -29px;
        z-index: -1;
        background-color: $color-yellow-light;
      }
    }
  }

  &__item.column__col {
    margin: 0 0 34px;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 13px);
    }

    @media (min-width: $break-large) {
      flex-basis: calc((100% / 3) - 13px);
      margin: 0;
    }

    &:last-child {
      margin: 0
    }
  }

  &__number {
    @include h3;
    margin: 0 0 9px;

    @media (min-width: $break-medium) {
      margin: 0 0 7px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 3px;
    }
  }

  &__cta {
    margin: 10px 0 0;

    @media (min-width: $break-medium) {
      margin: 12px 0 0;
    }

    @media (min-width: $break-large) {
      margin: 21px 0 0;
    }

    .cta {
      margin: 0;
    }
  }
}