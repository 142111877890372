.foundation-hero {
  margin-bottom: 4rem;

  &__main {
    position: relative;
    padding: 107.143% 0 0;

    &::before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      background-color: $color-orange;
      transform: translateX(-50%);
    }

    @media (min-width: $break-medium) {
      padding: 60.334% 0 0;
    }

    @media (min-width: $break-large) {
      padding: 56% 0 0;
    }

    &::after {
      content: '';
      display: block;
      width: 100vw;
      height: 100px;
      position: absolute;
      bottom: 0;
      left: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      transform: translateX(-50%);

      @media (min-width: $break-medium) {
        height: 150px;
      }

      @media (min-width: $break-large) {
        height: 250px;
      }
    }
  }

  &__video {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    object-fit: cover;
    transform: translateX(-50%);

    &.js-paused {
      & + .foundation-hero__video-toggle,
      & + * + .foundation-hero__video-toggle {
        svg {
          &.pause-white {
            display: none;
          }

          &.play-white {
            display: block;
          }
        }
      }
    }

    &-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: -1px;
      color: $color-white;
      background-color: $color-black;
      border: none;
      border-radius: 100%;
      appearance: none;
      transition: color 0.15s,
                  background-color 0.15s;

      @media (min-width: $break-medium) {
        top: 31px;
        right: -2px;
      }

      @media (min-width: $break-large) {
        top: 29px;
        right: -51px;
      }

      &:hover {
        color: $color-black;
        background-color: $color-orange-button;

        // svg {
        //   filter: invert(0);
        // }
      }

      &:focus {
        @include dark-bg-focus;
      }


      svg.play-white {
        display: none;
        width: 13px;
        position: relative;
        left: 1px;
      }
    }
  }

  &__image {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    object-fit: cover;
  }

  &__title {
    width: 102%;
    position: absolute;
    bottom: 16px;
    left: 1px;
    z-index: 1;

    @media (min-width: $break-medium) {
      bottom: 33px;
    }

    @media (min-width: $break-large) {
      bottom: 69px;
      left: -7px;
    }

    h2 {
      margin: 0;
      font-size: 3rem;
      line-height: calc(38.5 / 35);
      font-weight: 900;
      letter-spacing: 0.02em;
      color: $color-white;

      @media (min-width: $break-medium) {
        font-size: 6rem;
        line-height: 0.9;
      }

      @media (min-width: $break-large) {
        font-size: 9rem;
        line-height: calc(82 / 87);
      }
    }

    &--second-line {
      @include h1;
      font-size: 1.8rem;
      display: inline-block;
      padding: 11px 0 8px;
      z-index: -1;

      @media (min-width: $break-medium) {
        padding: 16px 0 15px;
        font-size: 3rem;
      }

      @media (min-width: $break-large) {
        padding: 12px 0 0;
        font-size: 5rem;
        line-height: calc(87 / 70);
      }
    }
  }

  &__ctas {
    display: block;
    margin: 29px 0 0;
    padding: 14px 20px;
    position: relative;
    z-index: 1;

    &::before {
      @include dark-tape-background;
      transform: rotate(-181.5deg);
    }

    @media (min-width: $break-medium) {
      width: 371px;
      padding: 20px 33px;
      margin: -89px -83px 0 auto;
    }

    @media (min-width: $break-large) {
      width: 484px;
      padding: 33px 43px;
      margin: -128px -60px 0 auto;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: 800;
      color: $color-white;
      text-transform: uppercase;
      font-family: $font-matrice;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
        margin-bottom: 1.2rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }
    }

    .cta {
      margin: 0 0 8px;
      position: relative;

      @media (min-width: $break-medium) {
        width: 250px;
        font-size: 1.8rem;
        margin: 0 0 5px;
        padding: 19px 50px 15px 15px;
      }

      @media (min-width: $break-large) {
        padding: 21px 50px 17px 18px;
        line-height: 1;
      }

      &:last-of-type {
        margin: 0;
      }

      &:focus {
        @include dark-bg-focus;
      }

      .last-word {
        span {
          position: unset;

          &::before {
            top: 16px;
            right: 19px;
            left: unset;

            @media (min-width: $break-medium) {
              top: 19px;
              right: 12px;
            }

            @media (min-width: $break-large) {
              top: 23px;
              right: 17px;
            }
          }
        }
      }
    }
  }
}