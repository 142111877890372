/**
 * @file
 * Carousel
 *
 * Contains styles for carousel object.
 */

.carousel {
  margin: 60px 0 71px;

  @media (min-width: $break-medium) {
    margin: 78px 0 93px;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
  }

  &__intro {
    margin: 20px 0 46px;

    @media (min-width: $break-medium) {
      margin: 20px 0 39px;
    }

    @media (min-width: $break-large) {
      margin: -5px 0 50px;
    }
  }

  &__wrapper {
    @include skewed-crumbled-paper-behind-image;
    padding: 0 0 19px;

    @media (min-width: $break-medium) {
      padding: 0 0 31px;
    }

    &::before {
      padding: 54.25% 0 0;
      height: 0;
      z-index: -1;
    }

    &::after {
      content: '';
      display: block;
      width: 200vw;
      left: 50%;
      height: 84%;
      position: absolute;
      bottom: 0;
      z-index: -2;
      transform: translateX(-50%);
      background-color: $color-orange-pattern-bg;

      @media (min-width: $break-medium) {
        height: 78.75%;
      }

      @media (min-width: $break-large) {
        height: 74.15%;
      }
    }
  }

  &__item {
    &.js-hide {
      .carousel__img {
        z-index: -3;
        transform: rotate(-2deg),
          scale(0.9),
          translateX(-10px),
          translateY(-10px);
      }

      .carousel__caption {
        opacity: 0;
      }
    }
  }

  &__img {
    padding: 56.5% 0 0;
    width: 100%;
    height: 0;
    position: relative;
    top: -10px;
    left: -17px;
    z-index: 2;
    transform: rotate(-2deg) scale(0.99);
    transition: z-index 0.4s,
      transform 0.4s,
      top 0.4s ease,
      left 0.4s ease;

    a {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      &:focus {
        @include dark-bg-focus;
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }

  &__caption {
    @include caption;
    margin: 10px 0 0;
    opacity: 0;
    transition: opacity 0.2s;

    @media (min-width: $break-medium) {
      margin: 14px 0 0;
    }

    @media (min-width: $break-large) {
      margin: 18px 0 0;
    }

    &.js-show {
      opacity: 1;
    }

    a {
      @include caption;
      padding: 0;
      position: relative;
      font-weight: 500;
      color: $color-black;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        &::before {
          bottom: -5px;

          @media (min-width: $break-large) {
            bottom: -6px;
          }
        }
      }

      &:focus {
        @include dark-bg-focus;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-image: url('./../assets/icons/underline-black.svg');
        background-size: cover;
        transition: bottom 0.15s;

        @media (min-width: $break-large) {
          bottom: -3px;
        }
      }
    }
  }

  .slick-slide {
    opacity: 0 !important;

    &.slick-active {
      opacity: 1 !important;

      .carousel__img {
        top: 0;
        left: 0;
        transform: rotate(0deg);
      }
    }

    &.js-active {
      opacity: 1 !important;
    }
  }
}