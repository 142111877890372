.pathway {
  .icon-list {
    @media (min-width: $break-medium) {
      margin-top: -100px;
    }

    @media (min-width: $break-large) {
      margin-top: -150px;
    }

    &__top {
      padding-bottom: 30px;

      @media (min-width: $break-large) {
        padding-bottom: 6.4rem;
      }

      &::after {
        @media (min-width: $break-large) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__header {
      .black {
        display: block;
      }
    }

    &__cards {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__card {
      .card__body {
        p {
          margin-top: 14px;

          @media (min-width: $break-medium) {
            margin-top: 15px;
          }
        }
      }

      .card__links {
        align-items: flex-start;
        
        .cta {
          @include patternAfterArrow(14.5px, 14.5px, 14.5px);

          .last-word {
            span {
              position: absolute;
            }
          }
        }
      }
    }
  }
}

.more-pathways {
  margin: 56px 0;

  @media (min-width: $break-medium) {
    margin: 57px 0;
  }

  @media (min-width: $break-large) {
    margin: 91px 0;
  }

  &__heading {
    margin: 0 0 23px;

    @media (min-width: $break-medium) {
      margin: 0 0 32px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 46px;
    }

    h2 {
      margin: 0;
    }
  }

  .column--three {
    &::after {
      @media (min-width: $break-large) {
        content: '';
        display: flex;
        flex-basis: calc((100% / 3) - 30px);
      }
    }
  }

  &__item {
    margin: 0 0 45px;

    @media (min-width: $break-medium) {
      margin: 40px 0 0;
    }

    @media (min-width: $break-large) {
      margin: 45px 0 0;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      @media (min-width: $break-medium) {
        margin: 0;
      }
    }

    &:nth-of-type(3) {
      @media (min-width: $break-large) {
        margin: 0;
      }
    }

    &.column__col {
      @media (min-width: $break-medium) {
        flex-basis: calc(50% - 12px);
      }

      @media (min-width: $break-large) {
        flex-basis: calc((100% / 3) - 30px);
      }

      .column__link {
        &:hover {
          @media (min-width: $break-medium) {
            width: 104%;
          }
        }
      }
    }

    &-img {
      width: 83.5%;

      @media (min-width: $break-medium) {
        width: 92.5%;
      }

      @media (min-width: $break-large) {
        width: 92.75%;
      }

      a {
        padding: 60.5% 0 0;
        position: relative;

        @media (min-width: $break-large) {
          padding: 61% 0 0;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }

    &-title {
      margin: -36px 0 15px;
      width: 86.5%;

      @media (min-width: $break-medium) {
        margin: -36px 0 27px;
        width: 100%;
      }

      @media (min-width: $break-large) {
        margin: -36px 0 6px;
      }

      h3.dark-tape-link {
        padding: 24px 40px 19px 10%;

        @media (min-width: $break-medium) {
          padding: 26px 40px 19px 9.5%;
        }

        @media (min-width: $break-large) {
          padding: 4px 58px 20px 10.5%;
        }

        span {
          @include tape-callouts;

          @media (min-width: $break-large) {
            @include tape-callouts-medium;
          }

          &::before {
            height: 88%;
            top: 5px;

            @media (min-width: $break-medium) {
              height: 98%;
              top: 2px;
            }

            @media (min-width: $break-large) {
              height: 84%;
              top: -2px;
            }
          }

          &::after {
            width: 32px;
            top: 42%;
            right: 0;

            @media (min-width: $break-medium) {
              right: 15px;
            }

            @media (min-width: $break-large) {
              width: 34px;
              top: 33%;
              right: 20px;
            }
          }
        }
      }
    }

    &-body {
      @media (min-width: $break-large) {
        padding: 0 28px 0 0;
      }
    }
  }
}