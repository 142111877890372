// styles for .breadcrumbs when hero image exists
.hero-img + .hero-intro {
  .breadcrumbs {
    @media (min-width: $break-large) {
      margin: 0 0 50px;
      padding: 0 1px 5px;
    }
  }
}

// styles for .breadcrumbs when hero image does not exist
.hero-intro {
  .breadcrumbs {
    margin: 0 0 60px;
    padding: 0 0 5px;
  }
}

.breadcrumbs {
  display: none;

  @media (min-width: $break-large) {
    display: block;
    font-family: $font-roboto;
    font-size: 1.4rem;
    line-height: 1.5;
    border-bottom: 1px dashed #000000;
  }

  a {
    color: $color-black;
    font-weight: 700;

    &:focus {
      @include dark-bg-focus;
    }

    &:not(:last-of-type) {
      position: relative;
      margin-right: 2.8rem;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: -2rem;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
        background-image: url('../assets/icons/chevron-black.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}