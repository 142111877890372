.home-highlights {
  margin: 0 0 30px;

  &.js-active {
    .home-highlights__item {
      opacity: 1;
      transform: translateY(0);
      
      @media (min-width: $break-medium) {
        &:nth-of-type(2) {
          transition-delay: 0.15s;
        }

        &:nth-of-type(3) {
          transition-delay: 0.3s;
        }
      }
    }
  }

  &__heading {
    margin: 0 0 10px;

    @media (min-width: $break-medium) {
      margin: 0 0 16px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 31px;
    }

    &--orange {
      color: $color-orange;
    }

    h2 {
      margin: 0;
    }
  }

  &__subtitle {
    margin: 0 0 23px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 33px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 36px;
    }

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 28px;
      position: absolute;
      top: -7px;
      left: -1px;
      background-image: url('./../assets/icons/parentheses-left-black.svg');
      background-size: cover;

      @media (min-width: $break-medium) {
        width: 9px;
        height: 35px;
        top: -6px;
      }

      @media (min-width: $break-large) {
        width: 13px;
        height: 49px;
        top: -10px;
        left: 0;
      }
    }

    div {
      @include h4;
      display: inline-block;
      margin: 0;
      padding: 0 0 0 7px;

      @media (min-width: $break-medium) {
        padding: 0 0 0 18px;
      }

      .last-word {
        span {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 8px;
            height: 28px;
            position: absolute;
            top: -9px;
            left: 0;
            background-image: url('./../assets/icons/parentheses-left-black.svg');
            background-size: cover;
            transform: scaleX(-1);

            @media (min-width: $break-medium) {
              width: 9px;
              height: 35px;
              left: 8px;
            }

            @media (min-width: $break-large) {
              width: 13px;
              height: 49px;
              top: -11px;
              left: 0;
            }
          }
        }
      }
    }
  }

  &__wrapper {
    position: relative;

    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &::before {
      content: '';
      display: block;
      width: 162px;
      height: 40px;
      position: absolute;
      top: 57.25%;
      left: -75px;
      z-index: 1;
      background-image: url('./../assets/img/tape-black.png');
      background-size: cover;
      transform: rotate(160deg);

      @media (min-width: $break-medium) {
        content: none;
      }
    }
  }

  &__item {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.15s,
                transform 0.15s;

    &:nth-of-type(odd) {
      @media (min-width: $break-medium) {
        flex-basis: calc(55% - 10px);  
      }
    }

    &:nth-of-type(even) {
      @media (min-width: $break-medium) {
        flex-basis: calc(45% - 8px);  
      }
    }

    &:nth-of-type(1) {    
      @media (min-width: $break-large) {
        flex-basis: calc((100% / 3) - 10px);
      }

      .home-highlights__item-img {
        @media (min-width: $break-medium) {
          width: 95.5%;
          padding: 95.5% 0 0;
        }

        @media (min-width: $break-large) {
          width: 115.5%;
          padding: 115.5% 0 0;
        }

        &::before {
          @media (min-width: $break-medium) {
            content: '';
            display: block;
            width: 162px;
            height: 58px;
            position: absolute;
            bottom: 43px;
            left: -93px;
            z-index: 1;
            background-image: url('./../assets/img/tape-black.png');
            background-size: cover;
            transform: rotate(160deg);
          }

          @media (min-width: $break-large) {
            bottom: 87px;
            left: -164px;
          }
        }

        img {
          @media (min-width: $break-large) {
            left: -50px;
          }
        }
      }
    }

    &:nth-of-type(2) {
      @media (min-width: $break-medium) {
        @include cowley-sticker;
      }

      @media (min-width: $break-large) {
        flex-basis: calc((100% / 3) - 23px)
      }

      &::after {
        @media (min-width: $break-medium) {
          width: 87px;
          height: 87px;
          top: -13px;
          right: 10px;
          left: unset;
        }

        @media (min-width: $break-large) {
          top: -15px;
          right: 9px;
          z-index: 2;
        }
      }

      .home-highlights__item-img {
        @media (min-width: $break-medium) {
          width: 124.25%;
          margin: 23px 0 9px -19.25%;
          padding: 91% 0 0;
          border: solid 10px $color-white;
        }

        @media (min-width: $break-large) {
          width: 126.75%;
          margin: 31px 0 22px -13.25%;
          padding: 94% 0 0;
          position: relative;
          z-index: 1;
        }
      }

      .home-highlights__item-title {
        @media (min-width: $break-medium) {
          margin: 0 0 12px;
        }

        @media (min-width: $break-large) {
          margin: 0 0 21px;
        }
      }
    }

    &:nth-of-type(3) {
      @media (min-width: $break-medium) {
        margin: 69px 0 0;
      }

      @media (min-width: $break-large) {
        flex-basis: calc((100% / 3) - 10px);
        margin: -58px 0 0;
        position: relative;
        right: -42px;
      }

      .home-highlights__item-img {
        @media (min-width: $break-medium) {
          padding: 85% 0 0;
          width: 90.5%;
        }

        @media (min-width: $break-large) {
          margin: 0 0 28px;
          padding: 108% 0 0;
          width: 115.75%;
        }

        &::before {
          content: '';
          display: block;
          height: 109%;
          width: 97%;
          position: absolute;
          top: -18px;
          left: 28px;
          z-index: -1;
          background-image: url('./../assets/img/wrinkle-light.jpg');
          background-size: cover;
          transform: rotate(2deg);

          @media (min-width: $break-large) {
            height: 109.5%;
            width: 96.5%;
            top: -24px;
            left: -7px;
          }
        }

        img {
          @media (min-width: $break-large) {
            left: -43px;
          }
        }
      }
    }

    &-img {
      margin: 0 0 15px;
      padding: 100% 0 0;
      position: relative;

      @media (min-width: $break-medium) {
        margin: 0 0 19px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 22px;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  
    &-title {
      @include story-titles;
      margin: 0 0 15px;

      @media (min-width: $break-medium) {
        margin: 0 0 20px;
      }
    }

    &-cta {
      .cta {
        margin: 0;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  .slick-slider {
    .slick-slide {
      padding: 0 20px;
    }

    .slick-arrow {
      top: 35.5%;
    }
  }
}

.highlight {
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $color-black;
    opacity: 0;
    transition: opacity 0.8s;
  }

  &.js-active {
    &::before {
      z-index: 0;
      opacity: 0.65;
    }
    
    .highlight__overlay {
      transform: translateX(0);
    }
    
    .highlight__inner {
      visibility: visible;
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-image: url('./../assets/img/wrinkle-light.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translateX(100%);
    transition: transform 0.8s;
    z-index: 1000;

    @media (min-width: $break-medium) {
      background-image: url('./../assets/img/rip-overlay.png');
    }

    @media (min-width: $break-large) {
      background-position-x: 85px;
    }

    a:not(.cta) {
      @include body-link--thin;
      white-space: nowrap;
    }
  }

  &__inner {
    padding: 20px 20px 40px;
    position: relative;
    visibility: hidden;

    @media (min-width: $break-medium) {
      padding: 29px 40px 40px 150px;
    }

    @media (min-width: $break-large) {
      padding: 44px 48px 40px 251px;
    }

    @media (min-width: $content-max) {
      max-width: 1800px;
      margin: auto;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0;
    position: absolute;
    top: 17px;
    left: 10px;
    color: $color-black;
    background-color: $color-yellow;
    border: none;
    border-radius: 100%;
    appearance: none;
    transition: background-color 0.15s;

    @media (min-width: $break-medium) {
      width: 80px;
      height: 80px;
      top: 30px;
      left: 40px;
    }

    @media (min-width: $break-large) {
      top: 57px;
      left: 120px;
    }

    &:hover {
      background-color: $color-orange-button;
    }

    &:focus {
      @include light-bg-focus;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 100%;
    }

    svg {
      display: block;
      width: 24px;
      height: 24px;
      position: relative;
      top: 2px;
      left: 2px;

      @media (min-width: $break-medium) {
        width: 32px;
        height: 32px;
        top: 3px;
        left: 3px;
      }
    }
  }

  &__name {
    margin: 0 0 -6px;
    font-family: $font-matrice;
    font-size: 1.2rem;
    line-height: calc(41 / 12);
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: $break-medium) {
      margin: 0 0 10px;
      font-size: 1.6rem;
      line-height: calc(41 / 16);
    }

    @media (min-width: $break-large) {
      margin: 0 0 16px;
      font-size: 2.1rem;
      line-height: calc(41 / 21);
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media (min-width: $break-medium) {
        display: block;
      }

      .highlight__img {
        @media (min-width: $break-medium) {
          display: none;
        }
      }
    }
  }

  &__img {
    flex-basis: 44%;
    padding: 36% 0 0;
    width: 100%;
    position: relative;
    top: -3px;

    @media (min-width: $break-medium) {
      flex-basis: unset;
      padding: 25.7% 0 0;
      width: 162px;
      top: -15px;
      right: -12px;
      float: right;
    }

    @media (min-width: $break-large) {
      padding: 17.7% 0 0;
      width: 199px;
      top: -9px;
      right: -11px;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: solid 10px $color-white;
      object-fit: cover;
    }
  }

  &__main {
    margin: 0 0 35px;

    @media (min-width: $break-medium) {
      margin: 0 0 62px;
    }

    .highlight__img {
      @media (max-width: ($break-medium - 1)) {
        display: none;
      }
    }
  }

  &__title {
    margin: 16px 0 9px;

    @media (min-width: $break-medium) {
      margin: 0 0 16px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 14px;
    }

    h3 {
      margin: 0;
    }
  }

  &__body {
    margin: 0 0 14px;

    @media (min-width: $break-medium) {
      margin: 0 0 22px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 32px;
    }

    &--bold {
      @include body-text-intro;
    }
  }

  &__cta {
    .cta {
      margin: 0;
    }
  }

  &__carousel {
    @media (min-width: $break-large) {
      width: calc(100% - 103px);
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: $break-large) {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    &-nav {
      display: flex;
      gap: 36px;
      margin: 0 0 19px;
      padding: 0 0 2px;
      border-bottom: dashed 1px $color-black;

      @media (min-width: $break-medium) {
        gap: 25px;
        margin: 0 0 25px;
      }

      @media (min-width: $break-large) {
        flex-direction: column;
        gap: 18px;
        padding: 0 0 0 22px;
        width: 74px;
        border-bottom: none;
        border-left: dashed 1px $color-black;
      }

      &-item {
        padding: 0;
        position: relative;
        font-family: $font-matrice;
        font-size: 1.4rem;
        line-height: calc(28 / 14);
        font-weight: 400;
        text-transform: uppercase;
        color: $color-black;
        background-color: transparent;
        background-image: url('./../assets/icons/underline-orange-thin.svg');
        background-repeat: no-repeat;
        background-position-y: 21px;
        border: none;
        appearance: none;
        transition: background-position-y 0.15s;

        @media (min-width: $break-medium) {
          font-size: 1.6rem;
          line-height: calc(28 / 16);
        }

        &:hover {
          background-position-y: 25px;
        }

        &:focus {
          @include light-bg-focus;
        }

        &.js-active {
          @include body-text-intro;
          background-image: none;

          &::before {
            opacity: 1;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: -4px;
          left: 0;
          background-color: $color-black;
          opacity: 0;
          transition: opacity 0.2s;

          @media (min-width: $break-large) {
            width: 3px;
            height: 143%;
            bottom: -6px;
            left: -23px;
          }
        }
      }
    }
  }

  &__item {
    @media (min-width: $break-large) {
      display: flex !important;
      gap: 53px;
      justify-content: space-between;
    }

    &-content {
      margin: 0 0 30px;

      @media (min-width: $break-medium) {
        margin: 0 0 11px;
      }

      @media (min-width: $break-large) {
        margin: 0;
        padding: 8px 2px 0 3px;
      }

      &--ancillary {
        @media (min-width: $break-medium) {
          display: flex;
          align-items: flex-start;
          gap: 23px;
        }

        @media (min-width: $break-large) {
          display: block;
        }

        .quote,
        .highlight__item-img--secondary {
          @media (max-width: ($break-medium - 1)) {
            display: none;
          }

          @media (min-width: $break-large) {
            display: none;
          }
        }

        .highlight__item-img:not(.highlight__item-img--secondary) {
          @media (min-width: $break-medium) {
            min-width: 258px;
          }

          @media (min-width: $break-large) {
            min-width: 300px;
            top: 0;
          }

          @media (min-width: $break-x-large) {
            min-width: 441px;
          }
        }

        .highlight__item-img--secondary {
          @media (min-width: $break-medium) {
            min-width: 233px;
          }
        }
      }

      .quote {
        margin: 0;

        @media (min-width: $break-medium) {
          display: none;
          padding: 25px 27px 2px 14px;
        }

        @media (min-width: $break-large) {
          display: block;
          padding: 22px 18px 14px;
        }

        &::before,
        &::after {
          @media (min-width: $break-medium) {
            top: 7px;
          }

          @media (min-width: $break-large) {
            top: -1px;
          }
        }

        &::before {          
          @media (min-width: $break-medium) {
            left: -16px;
          }
        }

        &::after {
          @media (min-width: $break-large) {
            right: -6px;
          }
        }

        &__copy {
          @media (min-width: $break-large) {
            line-height: calc(32 / 20);
          }

          &::before {
            @media (min-width: $break-medium) {
              top: -7px;
              left: -32px;
            }
          }

          &::after {
            @media (min-width: $break-medium) {
              top: -2px;
              left: -40px;
            }
          }
        }
      }

      .highlight__item-img--secondary {
        @media (min-width: $break-medium) {
          display: none;
        }

        @media (min-width: $break-large) {
          display: block;
        }
      }
    }

    &-body {
      margin: 0 0 14px;

      @media (min-width: $break-large) {
        margin: 0 0 34px;
      }

      &--bold {
        font-family: $font-matrice;
        font-size: 1.4rem;
        line-height: calc(28 / 14);
        font-weight: 800;
        text-transform: uppercase;

        @media (min-width: $break-medium) {
          @include body-text-intro;
        }
      }

      *:last-child {
        margin: 0;
      }

      p {
        margin: 0 0 10px;
      }

      a {
        &:focus {
          @include light-bg-focus;
        }
      }
    }

    &-img {
      padding: 98% 0 0;
      position: relative;

      @media (min-width: $break-medium) {
        padding: 49.1% 0 0;
        top: -2px;
        right: -2px;
      }

      @media (min-width: $break-large) {
        padding: 98.75% 0 0;
        top: -16px;
      }

      &--secondary {
        padding: 77% 0 0;
        transform: rotate(-2deg);

        @media (min-width: $break-medium) {
          padding: 36% 0 0;
          top: 3px;
          right: unset;
          left: -7px;
        }

        @media (min-width: $break-large) {
          padding: 87% 0 0;
          width: 110%;
          top: -4px;
          left: -12px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: solid 10px $color-white;
        object-fit: cover;
      }
    }
  }
}