.directory-embed {
  margin: 48px 0;

  @media (min-width: $break-medium) {
    margin: 91px 0;
  }

  &__header {
    margin: 0 0 20px;

    @media (min-width: $break-medium) {
      margin: 0 0 19px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 -5px;
    }
  }

  &__intro {
    margin: 0 0 24px;

    @media (min-width: $break-medium) {
      margin: 0 0 35px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 32px;
    }
  }

  &__item.column__col {
    margin: 0 0 39px;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 10px);
      margin: 0 0 36px;
    }

    @media (min-width: $break-large) {
      flex-basis: calc((100% / 3) - 14px);
    }
  }

  &__img {
    margin: 0 0 16px;
    padding: 100% 0 0;
    height: 0;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 22px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 16px;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__cta {
    margin: 0 0 3px;

    @media (min-width: $break-medium) {
      margin: 0 0 4px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 7px;
    }

    &-wrapper {
      position: relative;
    }

    h3 {
      margin: 0
    }

    .cta {
      @include story-titles;
      @include patternAfterArrow(14.5px, 14.5px, 14.5px);
      margin: 0;
      position: unset;

      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}