.scholarship-list {
  &__list {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100vw;
      height: calc(100% - 35px);
      position: absolute;
      top: 35px;
      left: 50%;
      z-index: -2;
      background-image: url(../assets/img/paper-textured-white.png);
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(-50%);
    }
  }

  &__hero-content {
    @media screen and (min-width: $break-medium) {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .hero-intro__text {
      @media screen and (min-width: $break-medium) {
        flex: 0 0 calc(100% - 288px - 20px);
      }
    }
  }

  &__hta {
    padding: 2rem;
    background-color: $color-black;
    background-image: url('../assets/img/paper-textured-black.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: $break-medium - 1) {
      margin: 3rem 0 0;
    }

    @media screen and (min-width: $break-medium) {
      flex: 0 0 288px;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 290px;
    }

    p {
      font-family: $font-matrice;
      font-size: 1.2rem;
      font-weight: 800;
      text-transform: uppercase;
      color: $color-white;
      margin: 0 0 0.6rem;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }
    }

    .cta {
      margin: 0;
      background-color: $color-yellow;

      @media screen and (min-width: $break-medium) {
        font-size: 1.8rem;
      }

      &:hover {
        background-color: $color-orange-button;
      }

      &:focus {
        @include dark-bg-focus;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__form-wrapper {
    position: relative;
    color: $color-black;
    font-size: 1.8rem;
    padding: 0.55rem 25px 0.55rem 14px;
    background-color: $color-white;
    border: solid 1px $color-gray;
  
    @media (min-width: $break-medium) {
      padding: 7px 25px 5px 14px;
    }
  }

  &__form-button {
    position: absolute;
    top: 5px;
    right: 3px;
    appearance: none;
    width: 30px;
    height: 30px;
    z-index: 2;
    border: none;
    background: transparent;
  
    &:focus {
      @include light-bg-focus;
    }

    &:after {
      content: '';
      position: absolute;
      top: 11px;
      left: 11px;
      width: 5px;
      height: 5px;
      border-top: 2px solid $color-black;
      border-right: 2px solid $color-black;
      transform: rotate(135deg);
    }

    span {
      @include element-invisible;
      pointer-events: none;
    }

    &[aria-expanded="true"] {
      &:after {
        top: 12px;
        transform: rotate(-45deg);
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    background-color: $color-yellow-light;
    padding: inherit;
    z-index: 1;
  }

  &__filters {
    margin-bottom: 5.5rem;

    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }

    @media screen and (min-width: $break-large) {
      justify-content: flex-start;
      margin-bottom: 1.5rem;
    }

    > div {
      position: relative;
      margin-bottom: 1.6rem;

      @media screen and (min-width: $break-medium) {
        width: 31%;
      }

      @media screen and (min-width: $break-large) {
        width: 22.5%;
        margin-right: 3rem;
      }
    }
  }

  &__top {
    margin-bottom: 4rem;

    @media screen and (max-width: $break-medium - 1) {
      text-align: center;
    }

    @media screen and (min-width: $break-medium) {
      margin-bottom: 5.1rem;
    }

    @media screen and (min-width: $break-large) {
      margin-bottom: 6.6rem;
    }
  }

  &__counter {
    position: relative;
    display: inline-block;
    line-height: 1;
    font-size: 1.8rem;
    padding: 1.8rem 1.2rem;
    font-family: $font-matrice;
    font-weight: 800;
    color: $color-white;
    text-transform: uppercase;

    @media screen and (min-width: $break-medium) {
      padding: 2.5rem 2.1rem;
      font-size: 2.1rem;
    }


    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 20px);
      height: 100%;
      background: url('../assets/img/tape-black.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  &__items {
    padding: 0;

    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    li {
      &:before {
        content: none;
      }
    }
  }

  .scholarship-item {
    margin-bottom: 3.5rem;

    @media screen and (min-width: $break-medium) {
      flex: 0 0 48%;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 47%;
      margin-bottom: 8.5rem;
    }

    &__header {
      margin: 0 0 1.45rem;

      @media screen and (min-width: $break-medium) {
        font-size: 2rem;
        line-height: 1.3;
      }

      @media screen and (min-width: $break-large) {
        font-size: 2.4rem;
      }
    }

    &__info {
      padding: 0;
      border-top: 3px solid $color-orange;
      margin-bottom: 1.9rem;

      li {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        padding: 0.7rem 0;
        margin: 0;
        border-bottom: 1px dashed $color-black;

        @media screen and (min-width: $break-large) {
          font-size: 1.8rem;
          padding: 0.5rem 0;
        }

        span {
          &:nth-child(1) {
            font-weight: 700;
          }
        }
      }
    }

    &__more {
      &__header {
        font-size: 1.4rem;
        margin: 0 0 0.7rem;

        @media screen and (min-width: $break-medium) {
          font-size: 1.6rem;
          margin: 0 0 0.3rem;
        }
      }
    }
  }
}