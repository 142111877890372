/**
 * @file
 * Section
 *
 * Base styles for all section content.
 */

.section {
  margin: 2rem 0;

  @media (min-width: $break-large) {
    margin: 3rem 0;
  }

  &__header {
    h2 {
      margin: 0;
    }
  }

  &__intro {
    margin: 20px 0 34px;

    @media (min-width: $break-medium) {
      margin: 20px 0 44px;
    }

    @media (min-width: $break-large) {
      margin: -5px 0 40px;
    }
  }

  &__cta {
    @include cta;
    text-align: center;
  }
}
