/**
 * @file
 * Slick
 *
 * Slick carousel styles.
 */
.slick {
  position: relative;

  &-list {
    overflow: hidden;
  }
}

.slick-slide {
  position: relative;
}

.slick-arrow {
  @include ir;
  padding: 0;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 27.5%;
  z-index: 3;
  background-color: $color-yellow;
  border-radius: 100%;
  transform: translateY(-50%);
  transition: background-color 0.15s;

  @media (min-width: $break-medium) {
    width: 46px;
    height: 46px;
    top: 37%;
  }

  @media (min-width: $break-large) {
    top: 40.5%;
  }

  &:hover {
    background-color: $color-orange-button;
  }

  &:focus {
    @include dark-bg-focus;
  }

  svg {
    width: 9px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url('./../assets/icons/chevron-black.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    transform: translate(-50%, -50%);
  }
}

.slick-prev {
  left: -10px;

  @media (min-width: $break-medium) {
    left: -26px;
  }

  @media (min-width: $break-large) {
    left: -23px;
  }

  svg {
    transform: scale(-1, -1);
    top: 33%;
    left: 42%;
  }

  &-icon {
    display: none;
  }
}

.slick-next {
  right: -10px;

  @media (min-width: $break-medium) {
    right: -26px;
  }

  @media (min-width: $break-large) {
    right: -23px;
  }

  &-icon {
    display: none;
  }
}