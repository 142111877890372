/**
 * @file
 * Program
 *
 * Contains styles for programs and program view modes.
 */
.program__filters {
  display: block;
  background-color: $color-yellow-light;
  padding: 1.7rem 24px 0;
  margin-right: 20px;

  @media screen and (max-width: $break-large - 1) {
    display: none;
    overflow: auto;
    height: 50vh;
    max-height: 350px;
  }

  @media screen and (min-width: $break-medium) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  @media (min-width: $break-large) {
    position: relative;
    margin-bottom: 0 !important;
  }

  &__fieldset {
    border: 0;
    padding: 0;
    margin-bottom: 2rem;

    legend {
      font-weight: 700;
      margin-bottom: 0.7rem;
    }
  }

  &__field {
    position: relative;
    display: flex;
    margin-bottom: 0.9rem;

    &-wrapper {
      position: relative;

      input {
        &:checked {
          & + svg {
            opacity: 1;
          }
        }
      }

      svg {
        position: absolute;
        top: 8px;
        left: 2px;
        opacity: 0;
        transition: opacity 0.15s;

        @media (min-width: $break-medium) {
          top: 11px;
        }
      }
    }

    label {
      font-weight: 400;
    }

    input[type="radio"]:checked+label:after {
      content: "";
      width: 7px;
      height: 7px;
      background: $color-black;
      position: absolute;
      top: 8px;
      left: 5px;
      border-radius: 100px;

      @media (min-width: $break-large) {
        top: 12px;
      }
    }

    input[type="checkbox"] {
      margin-right: 10px;
      top: 1px;
    }

    input[type="radio"]:focus+label {
      outline: 1px solid;
    }
  }
}

.program--list {
  margin-top: 2.7rem;

  @media screen and (min-width: $break-medium) {
    margin-top: 4.8rem;
  }

  @media screen and (min-width: $break-large) {
    margin-top: 0;
    margin-left: 30px;
  }

  &__title {
    @include body;

    @media (max-width: ($break-medium - 1)) {
      line-height: calc(30 / 16);
    }
  }

  &__sort {
    position: relative;
    margin-bottom: 6.6rem;

    @media screen and (min-width: $break-medium) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 600px;
      margin-right: auto;
      margin-bottom: 3.67rem;
    }

    @media (min-width: $break-large) {
      margin-bottom: 5rem;
    }

    &:after {
      content: '';
      position: absolute;
      right: 15px;
      bottom: 20px;
      width: 5px;
      height: 5px;
      border-top: 2px solid $color-black;
      border-right: 2px solid $color-black;
      transform: rotate(135deg);
      pointer-events: none;

      @media screen and (min-width: $break-medium) {
        left: calc(100% - 333px);
      }
    }

    label {
      @media screen and (min-width: $break-medium) {
        flex: 0 0 auto;
        margin-right: 15px;
      }
    }

    select {
      @media screen and (min-width: $break-medium) {
        flex: 0 0 220px;
      }
    }
  }

  h2 {
    margin: 0 0 10px;
    font-size: 1.5rem;

    @media screen and (min-width: $break-medium) {
      font-size: 2rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2.8rem;
    }
  }

  &__block {
    margin-bottom: 5rem;

    @media screen and (min-width: $break-large) {
      margin-bottom: 3.3rem;
    }
  }

  &__items {
    padding: 0;
  }

  &__item {
    > div {
      @include h3;
      font-size: 1.6rem;
      text-transform: none;

      @media screen and (min-width: $break-medium) {
        font-size: 1.8rem;
        margin: 1.2rem 0 2.3rem;
      }

      a,span {
        font-family: $font-roboto;
        color: $color-black;
      }

      a > span {
        font-weight: 700;
      }

      a {
        @include body-link--thin;
      }

      span {
        font-size: 1.6rem;
        font-weight: 400;

        @media screen and (min-width: $break-medium) {
          font-size: 1.8rem;
        }
      }
    }

    &:before {
      content: none;
    }
  }
}