/**
 * @file
 * Events 3 Up Feature
 *
 * Contains styles for Events 3 Up content object.
 */

.events3up {
  margin: 42px 0;

  @media (min-width: $break-medium) {
    margin: 70px 0;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
  }

  &__header {
    margin: 0 0 25px;
    padding: 0 65px 0 0;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 8px;
      padding: 0;
    }

    @media (min-width: $break-large) {
      margin: 0 0 11px;
    }

    &--orange {
      display: block;
      color: $color-orange;

      @media (min-width: $break-large) {
        margin: -4px 0 0;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 28px;
      height: 60px;
      position: absolute;
      top: 8px;
      right: 34px;
      background-image: url('./../assets/icons/arrow-swirly-black.svg');
      background-size: cover;

      @media (min-width: $break-medium) {
        content: none;
      }
    }

    h2 {
      margin: 0;
    }
  }

  &__intro {
    margin: 0 0 38px;

    @media (min-width: $break-medium) {
      margin: 0 0 32px;
      padding: 0 75px 0 0;
      position: relative;
    }

    @media (min-width: $break-large) {
      margin: 0 0 28px;
    }

    &::before {
      @media (min-width: $break-medium) {
        content: '';
        display: block;
        width: 57px;
        height: 121px;
        position: absolute;
        top: -24px;
        right: -3px;
        background-image: url('./../assets/icons/arrow-swirly-black.svg');
        background-size: cover;
      }

      @media (min-width: $break-large) {
        width: 65px;
        height: 138px;
        top: -22px;
        right: -44px;
      }
    }
  }

  &__items {
    margin: 0 0 17px;

    @media (min-width: $break-medium) {
      margin: 0 0 26px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 20px;
    }
  }

  &__item {
    display: flex;
    margin: 0 0 17px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 26px;
    }
    
    @media (min-width: $break-large) {
      margin: 0 0 20px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92px;
    min-width: 92px;
    background-color: $color-yellow;

    @media (min-width: $break-medium) {
      width: 104px;
      min-width: 104px;
    }

    @media (min-width: $break-large) {
      width: 107px;
      min-width: 107px;
    }
  }

  &__day {
    @include number-callout;
    margin: 10px 0 0;

    @media (min-width: $break-medium) {
      margin: -7px 0 0;
      font-size: 4.5rem;
      line-height: 1;
    }

    @media (min-width: $break-large) {
      margin: 0;
    }
  }

  &__month {
    @include date-tag;

    @media (min-width: $break-large) {
      margin: -6px;
    }
  }

  &__content {
    padding: 15px 10px 19px 10px;
    flex: 1;
    background-image: url('./../assets/img/wrinkle-light.jpg');
    background-size: cover;

    @media (min-width: $break-medium) {
      padding: 21px 19px;
    }

    @media (min-width: $break-large) {
      padding: 20px 19px 21px 15px;
    }

    h3 {
      @include h6;
      display: inline-block;
      margin: 0 0 4px;

      @media (min-width: $break-medium) {
        margin: 0 0 3px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 1px;
      }
      
      a {
        @include h6;
        padding: 0;
        background: none;

        &:hover {
          span::before {
            opacity: 1;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        
        span {
          @include h6;
          display: inline-block;
          position: relative;
    
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            position: absolute;
            bottom: -3px;
            left: 0;
            background-image: url('./../assets/icons/underline-black--repeating.svg');
            background-size: contain;
            background-repeat: repeat-x;
            opacity: 0;
            transition: opacity 0.15s;
          }
        }
      }
    }
  }

  &__data {
    @include date-tag;

    @media (min-width: $break-medium) {
      display: flex;
      font-size: 1.6rem;
      line-height: calc(30 / 16);
    }
  }

  &__time {
    font-weight: 700;

    @media (min-width: $break-medium) {
      margin: 0 12px 0 0;
    }
  }

  &__location {

  }

  &__cta {
    margin: 0;

    .cta {
      margin: 0;
    }
  }
}