/**
 * @file
 * Calendar Submit
 *
 * Contains styles for the Calendar Submit Event page
 */

 .calendar-submit {
   textarea {
    width: 100%;
    margin: 0.625em 0;
   }

   input[type=checkbox] {
    appearance: checkbox;
    margin: 0 5px 0 0;
   }

   input[type=radio] {
    appearance: radio;
    margin: 0 5px 0 0;
   }

   select {
    appearance: auto;
   }

  label {
    display: block;
  }

  label[for="eventDate"] {
    display: inline-block;
  }

  .onote {
    display: block;
  }

  .catCol {
    .sub {
      margin-left: 1rem;
    }
  }
 }