.annual-events {
  margin: 55px 0;

  @media (min-width: $break-large) {
    margin: 101px 0;
  }

  &__heading {
    margin: 0 0 9px;

    @media (min-width: $break-medium) {
      margin: 0 0 4px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 6px;
    }

    &--orange {
      color: $color-orange;
    }

    h2 {
      margin: 0;
    }
  }

  &__intro {
    margin: 0 0 77px;

    @media (min-width: $break-medium) {
      margin: 0 0 42px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 34px;
    }
  }

  &__wrapper {
    margin: 0 0 35px;

    @media (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      gap: 23px;
      margin: 0 0 32px;
    }

    @media (min-width: $break-large) {
      gap: 40px;
      margin: 0 0 33px;
    }
  }

  &__item {
    background-color: $color-orange;
    margin: 43px 0 80px;
    position: relative;
    transition: background-color 0.3s ease;

    @media (min-width: $break-medium) {
      flex-basis: 50%;
    }

    &:hover {
      background-color: $color-orange-dark;

     .annual-events__item-img {
      opacity: 0.2;
     } 
    }

    &-date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 104px;
      height: 97px;
      position: absolute;
      left: 0;
      top: -43px;
      z-index: 2;
      background-color: $color-yellow;

      @media (min-width: $break-large) {
        width: 107px;
        height: 99px;
      }
    }

    &-day {
      margin: -2px 0 0;
      font-family: $font-matrice;
      font-size: 4.5rem;
      line-height: 1;
      font-weight: 800;

      @media (min-width: $break-large) {
        margin: 10px 0 0;
        line-height: calc(30 / 45);
      }
    }

    &-month {
      font-size: 1.4rem;
      line-height: 1.5;
      font-weight: 400;

      @media (min-width: $break-large) {
        margin: 2px 0 0;
        font-size: 1.6rem;
        line-height: calc(30 / 16);
      }
    }

    &-img {
      padding: 47.25% 0 0;
      position: relative;
      transition: opacity 0.3s ease;

      @media (min-width: $break-large) {
        padding: 57.5% 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &-title {
      padding: 19px 17px 21px 17px;

      @media (min-width: $break-medium) {
        padding: 24px 17px 19px 17px;
      }

      @media (min-width: $break-large) {
        padding: 42px 27px;
      }

      h3 {
        @include h4;
        margin: 0;

        @media (min-width: $break-large) {
          line-height: calc(36 / 28);
        }
      }
    }

    &-link {
      @include h4;
      padding: 0;
      background: none;

      @media (min-width: $break-large) {
        line-height: calc(36 / 28);
      }
      
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  &__cta {
    .cta {
      margin: 0;
    }
  }
}