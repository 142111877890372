/**
 * @file
 * Story Display
 *
 * Contains styles for Story Display object.
 */

.story-display {
  position: relative;
  margin-bottom: 6rem;

  @media screen and (min-width: $break-medium) {
    padding: 7.6rem 0;
  }

  &:after {
    @media screen and (min-width: $break-medium) {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background-image: url('../assets/img/paper-textured-white.png');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      @media screen and (min-width: $break-large) {      
        left: -300px;
        transform: none;
      }

      @media screen and (min-width: $content-max) {
        left: calc(-300px - ((100vw - #{$content-max}) / 2));
      }
    }
  }

  &__wrap {
    @media (min-width: $break-medium) {
      display: flex;
    }
  }

  &__img {
    position: relative;
    margin: 0 0 5.1rem;

    @media (min-width: $break-medium) {
      flex: 0 0 48.5%;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 46.5%;
    }

    a {
      display: inline-block;
      padding: 0;

      &:focus {
        @include light-bg-focus;
      }
    }

    img {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: 20px;
      left: -16px;
      width: 68px;
      height: 68px;
      background-image: url('../assets/img/cowley-sticker.png');
      background-repeat: no-repeat;
      background-size: 100%;

      @media screen and (min-width: $break-medium) {
        left: -25px;
      }

      @media screen and (min-width: $break-large) {
        top: 25px;
        left: -32px;
        width: 86px;
        height: 86px;
      }
    }
  }

  &__content {
    position: relative;

    @media (min-width: $break-medium) {
      flex: 0 0 51.5%;
      padding-left: 2.3rem;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 53.5%;
      padding-left: 6.1rem;
    }
  }

  &__symbol {
    position: absolute;
    top: 16px;
    left: -11px;
    width: 30px;
    height: 30px;
    z-index: 2;

    @media screen and (min-width: $break-medium) {
      top: 12px;
      left: 8px;
      width: 36px;
      height: 36px;
    }

    @media screen and (min-width: $break-large) {
      left: 39px;
      top: 12px;
    }

    &::before {
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      background-color: $color-crumbled-paper-beige;
      position: absolute;
      top: -3px;
      left: -3px;
      border-radius: 100%;

      @media (min-width: $break-medium) {
        top: 0;
        left: 0;
      }

      @media (min-width: $break-large) {
        width: 5px;
        left: 16px;
        border-radius: 0;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      content: '';
      background-image: url('../assets/icons/quote-quotation-mark.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: 2;
      transform: translate(-50%,calc(-50% + 3px));

      @media screen and (min-width: $break-medium) {
        width: 80%;
        height: 80%;
      }
    }
  }

  &__quote {
    position: relative;
    font-family: $font-matrice;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
    margin: 0 0 2rem;
    line-height: 1.5;
    padding: 1.1rem 2.9rem;

    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2rem;
      margin: 0 0 4.4rem;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -13px;
      left: -1px;
      display: block;
      width: 10px;
      height: calc(100% + 26px);
      background-image: url('../assets/icons/bracket-left--small.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;

      @media screen and (min-width: $break-medium) {
        top: -27px;
        width: 13px;
        height: calc(100% + 54px);
      }

      @media screen and (min-width: $break-large) {
        left: -6px;
        top: -10px;
        width: 17px;
        height: calc(100% + 20px);

      }
    }

    &:after {
      left: auto;
      right: 0;
      transform: rotate(180deg);

      @media screen and (min-width: $break-large) {
        right: -6px;
      }
    }
  }

  &__title {
    font-family: $font-matrice;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin: 0 0 0.525rem;

    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 0 30px;
      font-size: 2.1rem;
    }

    a {
      @include body-link;
      @include patternAfterArrow(1.4rem, 1.4rem, 1.4rem);
      font-family: $font-matrice;
      font-weight: 800;

      &:focus {
        @include light-bg-focus;
      }
    }
  }

  &__subtitle {
    margin: 0 0 2rem;
    font-size: 1.6rem;
    font-weight: 400;

    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 0 30px;
      margin: 0 0 3.5rem;
    }
  }

  &__caption {
    @media screen and (min-width: $break-medium) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 0 30px;
    }
  }
}