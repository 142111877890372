/**
 * @file
 * Hero
 *
 * Contains styles for hero object.
 */

.hero {
  margin: 0 0 36px;
  transition: padding-top 0.3s ease;

  @media (min-width: $break-medium) {
    margin: 0 0 73px;
  }

  @media (min-width: $break-large) {
    margin: 0 0 100px;
  }

  &--no-image {
    margin: 0 0 62px;

    @media (min-width: $break-medium) {
      margin: 0 0 122px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 136px;
    }

    @media (min-width: $content-max) {
      margin: 0 0 210px;
    }

    .hero-intro__aside {
      position: absolute;
      top: 0;
      left: 20px;

      @media screen and (min-width: $break-medium) {
        left: 50px;
      }

      @media screen and (min-width: $break-large) {
        position: static;
        top: 0;
        left: 0;
      }

      & + .hero-intro__main {
        h1 {
          @media (max-width: ($break-medium - 1)) {
            margin-top: 57px;
          }
        }
      }
    }
  }

  &--no-sidenav {
    .hero-intro__inner {
      @media screen and (min-width: $break-large) {
        padding: 44px 100px 65px 100px !important;
      }
    }
  }

  &.hero--no-image.hero--no-sidenav {
    .hero-intro__inner {
      padding: 31px 20px 24px;
      
      @media (min-width: $break-medium) {
        padding: 54px 50px 35px;
      }
      
      @media (min-width: $break-large) {
        flex-direction: column;
        padding: 45px 100px 35px;
      }
    }
  }

  &--landing-page {
    .hero-img__wrapper {
      @media (min-width: $break-large) {
        padding: 0 0 44.5%;
      }
    }
  }

  &-img {
    position: relative;
    background-color: $color-black;

    h1 {
      position: relative;
      margin: 0;
      padding: calc(100vw * 0.387)  21px 34px;
      width: 100%;
      z-index: 1;
      color: $color-white;
      opacity: 0;
      transition: opacity 0.15s;

      @media (min-width: $break-medium) {
        padding: calc(100vw * 0.265) 50px 34px;
      }

      @media (min-width: $break-large) {
        padding: calc(100vw * 0.265) 100px 73px;
      }
      
      @media (min-width: $content-max) {
        max-width: $content-max;
        margin: 0 calc((100% - 1800px) / 2);
      }

      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 60%;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: linear-gradient(180deg, rgba($color-black, 0) 0%, rgba($color-black, 0.7) 100%);
        opacity: 0.9;
        z-index: -1;
        transform: translateX(-50%);
      }
    }

    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        object-fit: cover;
        transition: opacity 0.15s;
      }
    }

    // styling for .hero-intro when hero image exists
    & + .hero-intro {
      padding: 0 0 40px;
  
      @media (min-width: $break-medium) {
        padding: 27px 0 48px;
      }
  
      @media (min-width: $break-large) {
        padding: 17px 0 70px;
      }
  
      &::before,
      &::after {
        content: '';
        display: block;
        width: 100vw;
        position: absolute;
        left: 0;
      }
  
      &::before {
        height: 80px;
        top: -80px;
        background-image: url('./../assets/img/rip-orange-hero.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 2;
  
        @media (min-width: $break-x-large) {
          height: 100px;
          top: -99px;
        }
      }
  
      &::after {
        height: 40px;
        bottom: 0;
        background-color: $color-orange-dark;
  
        @media (min-width: $break-medium) {
          height: 48px;
        }
  
        @media (min-width: $break-large) {
          height: 70px;
        }
      }
  
      .hero-intro__inner {
        padding: 0 20px 15px;
        position: relative;
        z-index: 2;
  
        @media (min-width: $break-medium) {
          padding: 0 50px 23px;
        }
  
        @media (min-width: $break-large) {
          padding: 0 100px 70px 30px;
        }
      }

      .hero-intro__aside {
        position: relative;
        padding: 0;
      }
    }
  }

  // default styling for .hero-intro and styles for element when hero image does not exist
  &-intro {
    position: relative;
    background-color: $color-orange-pattern-bg;

    &::before {
      content: '';
      display: block;
      height: 60px;
      width: 100%;
      position: absolute;
      bottom: -60px;
      left: 0;
      background-image: url('./../assets/img/rip-orange-hero-no-image.png');
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: $break-medium) {
        height: 85px;
        bottom: -82px;
      }

      @media (min-width: $break-large) {
        height: 100px;
        bottom: -100px;
      }

      @media (min-width: $break-x-large) {
        height: 160px;
        bottom: -155px;
        z-index: -1;
      }

      @media (min-width: $content-max) {
        height: 260px;
        bottom: -255px;
      }
    }

    &__inner {
      padding: 14px 20px 28px;

      @media (min-width: $break-medium) {
        padding: 78px 45px 17px 50px;
      }

      @media (min-width: $break-large) {
        padding: 44px 100px 65px 30px;
        display: flex;
        gap: 30px;
      }

      @media (min-width: $content-max) {
        max-width: $content-max;
        margin: auto;
      }
    }

    &__text {
      @include intro-paragraph;
      margin: 0 0 16px;

      @media (min-width: $break-medium) {
        margin: 0 0 20px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 27px;
      }
  
      &--large {
        @include intro-first-line;
  
        @media (min-width: $break-medium) {
          line-height: 1.1;
        }
      }
    }

    &__aside {
      @media (min-width: $break-large) {
        display: block;
        padding: 2px 0 0;
        min-width: 240px;
      }
    }

    h1 {
      margin: 0;
    }
  }
}

.hero-fade-in {
  opacity: 1 !important;
}