/**
 * @file
 * Columns
 *
 * Base styles for all column layouts.
 */

.column {
  @media (min-width: $break-medium) {
    display: flex;
  }

  &__col {
    margin: 0 0 40px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0;
      flex: 0;
    }
  }

  &__img {
    width: 95.5%;

    @media (min-width: $break-medium) {
      width: 93%;
    }

    @media (min-width: $break-large) {
      width: 93.25%;
    }

    a {
      display: block;
      background-image: none;

      &:focus {
        @include light-bg-focus;
      }
    }
  }

  &__title {
    margin: -37px 0 22px;

    @media (min-width: $break-medium) {
      margin: -40px 0 31px;
    }

    @media (min-width: $break-large) {
      margin: -47px 0 30px;
    }

    h3 {
      @include dark-tape-behind-heading;
    }
  }

  &__subtitle {
    @include body-text-intro;
    margin: 0 0 5px;

    @media (min-width: $break-medium) {
      margin: 0 0 -4px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 14px;
    }
  }

  &__body {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: width 0.15s;

    &:hover {
      & + .column__title {
        .dark-tape-link {
          span {
            &::before {
              width: calc(104% + 12px);

              @media (min-width: $break-medium) {
                width: calc(100% + 12px);
              }
            }

            &::after {
              right: -2px;

              @media (min-width: $break-medium) {
                right: 9px;
              }

              @media (min-width: $break-large) {
                right: 13px;
              }
            }
          }
        }
      }
    }

    &-wrapper {
      position: relative;
    }
  }
}

.column--two {
  @media (min-width: $break-medium) {
    justify-content: space-between;
  }

  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 10px); // 50% - half container gutter
    }

    @media (min-width: $break-large) {
      flex-basis: calc(50% - 25px); // 50% - half container gutter
    }
  }
}

.column--three {
  @media (min-width: $break-medium) {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .column__col {
    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 11px);
    }

    @media (min-width: $break-large) {
      flex-basis: calc((100% / 3) - 8px); // 33.33% - 1/3 container gutter
    }
  }

  &.slick-slider {
    display: block;
  }
}