/**
 * Base theme styles
 * This file contains theme base styles.
 *
 * For example:
 * - WYSIWYG styles and layouts
 * - Images and Videos
 * - Tables
 */

html {
  font-size: 10px;
}

body {
  @include body;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  overflow-x: hidden;
  font-size: 1.6rem;

  @media (min-width: $break-medium) {
    font-size: 1.8rem;
  }

  //** Fixed class to prevent scrolling
  &.js-fixed {
    overflow: hidden;
  }
}

main {
  width: 100vw;
  overflow: clip;
}

//## Remove margin on last elements
* >  {
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
}

//## Visually hidden
.visually-hidden {
  @include element-invisible;
}

//## Skip to content
a.skip-link {
  position: absolute;
  left: 0;
  top: 0;
  color: $color-black;
  background: $color-white;

  &.visually-hidden {
    @include element-focusable;

    &:focus {
      @include light-bg-focus;
      background-color: $color-white;
      padding: 3px 10px;
      position: absolute !important;
      z-index: 1000000;
    }
  }
}

//## Floated content
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//## Links
a {
  cursor: pointer;
  color: $color-black;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.15s;

  &:focus {
    @include light-bg-focus;
  }
}

.container  {
  .wysiwyg {
    a:not(.cta--button):not(.cta--link):not(.paginate_button) {
      @include body-link--thin;
    }
  } 
}

.section,
.news__content,
.faculty__content {
  p > a,
  .twitter__body > a {
    @include body-link--thin;
  }
}

.cta {
  @include cta;

  &--button {
    @include cta-button;

    &--yellow {
      @include cta-button;
      background-color: $color-yellow;

      &:hover {
        background-color: $color-orange-button;
      }
    }
  }

  &--link {
    @include body-link;
    font-size: 1.4rem;
    background: none;
  
    @media (min-width: $break-medium) {
      font-size: 1.6rem;
    }
  }
}

//## Images
img {
  display: block;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

figcaption {
  @include caption;
}

//## Inline images
.img-inline {
  &--full {
    @include orange-background-bar;
    margin: 36px 0 42px;
    padding: 0 0 26px;

    @media (min-width: $break-medium) {
      margin: 48px 0 68px;
      padding: 0 0 32px;
    }

    @media (min-width: $break-large) {
      margin: 46px 0 100px;
      padding: 0 0 28px;
    }

    figcaption {
      margin: 10px 0 0;
  
      @media (min-width: $break-medium) {
        margin: 13px 0 0;
      }
  
      @media (min-width: $break-large) {
        margin: 24px 0 0;
      }
    }
  }

  &--float {
    margin: 27px 0;

    &.float-right,
    &.float-left {
      display: table;
      float: unset;
    }

    &.float-right {
      @media (min-width: $break-medium) {
        margin: 0 0 20px 30px;
        float: right;
      }
    }

    &.float-left {
      @media (min-width: $break-medium) {
        margin: 0 30px 20px 0;
        float: left;
      }
    }

    figcaption {
      display: table-caption;
      caption-side: bottom;
      margin: 7px 0 0;
  
      @media (min-width: $break-medium) {
        margin: 12px 0 0;
      }
  
      @media (min-width: $break-large) {
        margin: 11px 0 0;
      }
    }
  }

  &__img-wrapper {
    @include skewed-crumbled-paper-behind-image;
  }
}

// styling for pages that have a sidenav in the hero
.hero:not(.hero--no-sidenav) + .container {
  .img-inline--full {
    &::before {
      @media (min-width: $break-large) {
        left: -300px;
        transform: unset;
      }

      @media (min-width: $content-max) {
        width: 200vw;
        left: -100vw;
      }
    }
  }
}

//## Tables
table {
  clear: both;
  max-width: 100%;
  display: block;
  border-collapse: collapse;

  td,
  th {
    text-align: left;
    min-width: 12.9rem;
    padding: 1rem 1rem 1.1rem 0;
    vertical-align: top;
    
    @media (min-width: $break-medium) {
      min-width: 13.9rem;
      padding: 1.4rem 1rem 1.4rem 0;
    }

    @media (min-width: $break-large) {
      min-width: 22.5rem;
      padding: 0.7rem 1rem 0.7rem 0;
    }

    &:first-child {
      padding: 1rem 1rem 1rem 1.2rem;
    }
  }

  th {
    @media (min-width: $break-medium) {
      line-height: 1.38;
    }

    @media (min-width: $break-large) {
      line-height: 2.8rem;
    }
  }

  td {
    @media (min-width: $break-medium) {
      line-height: 1.55;
    }
  }

  .table-scroller {
    overflow: auto;
    display: block;
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: $color-white;
    z-index: 100;
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid $color-orange;
    }

    th {
      &:first-child {
        @media (min-width: $break-medium) {
          padding: 1.4rem 1rem 0.5rem 1.2rem;
        }
    
        @media (min-width: $break-large) {
          padding: 0.7rem 1rem 0.7rem 2.2rem;
        }
      }

      @media (min-width: $break-medium) {
        padding: 1.4rem 1rem 0.5rem 0;
      }
  
      @media (min-width: $break-large) {
        padding: 0.7rem 1rem 0.7rem 0;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px dashed $color-black;
    }

    td,
    th {
      padding: 2.1rem 1rem 2.5rem 0;

      @media (min-width: $break-large) {
        padding: 2.1rem 1.5rem 2.3rem 0;
      }

      @media (min-width: $break-large) {
        line-height: 2.8rem;
        padding: 1.5rem 2rem 1.5rem 0;
      }

      &:first-child {
        padding: 2.1rem 1rem 2.5rem 1.2rem;
  
        @media (min-width: $break-large) {
          padding: 1.5rem 1.5rem 1.5rem 2.2rem;
        }
      }
    }
  }
}

.table-block {
  &__wrapper{
    position: relative;
    padding: 0;
    margin: 1.5rem -2rem 7rem 0;

    @media (min-width: $break-medium) {
      margin: 1.1rem 0 6rem;
      clear: both;
    }

    @media (min-width: $break-large) {
      margin: 1.2rem 0 7rem;
    }
  }
  &__holder {
    &.js-scroll--right {
      .table-block__next {
        display: block;
      }
    }
    &.js-scroll--left {
      .table-block__prev {
        display: block;
      }
    }
  }
  &__prev,
  &__next {
    display: none;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.6rem;
    width: 4.6rem;
    height: 4.6rem;
    border: none;
    padding: 0;
    background: $color-yellow;
    background-image: url("/_resources/assets/icons/chevron-right-black.svg");
    background-repeat: no-repeat;
    background-position: calc(50% + 0.2rem) 50%;
    border-radius: 50%;
    transition: background-color .3s ease-in-out;

    &:hover {
      background-color: $color-orange;
    }
  }
  &__next {
    left: auto;
    right: 1.6rem;

    @media (min-width: $break-medium) {
      right: 0.8rem;
    }
  }
  &__prev {
    transform: translateY(-50%) scaleX(-1);
  }
}

//## Lists
ul {
  margin: 0 0 33px;
  padding: 0 0 0 32px;
  list-style-type: none;

  @media (min-width: $break-medium) {
    margin: 0 0 34px;
  }

  @media (min-width: $break-large) {
    margin: 0 0 37px;
    padding: 0 0 0 35px;
  }

  li {
    margin: 0 0 10px;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      height: 7px;
      width: 7px;
      position: absolute;
      top: 8px;
      left: -12px;
      background-color: $color-orange;
      border-radius: 100%;

      @media (min-width: $break-medium) {
        top: 10px;
      }

      @media (min-width: $break-large) {
        top: 11px;
        left: -15px;
      }
    }

    &:last-of-type {
      margin: 0
    }
  }
}

ol {
  margin: 0 0 35px;
  padding: 0 0 0 39px;
  list-style-type: none;
  counter-reset: olCounter;

  @media (min-width: $break-medium) {
    padding: 0 0 0 40px;
  }

  @media (min-width: $break-large) {
    padding: 0 0 0 32px;
  }

  li {
    margin: 0 0 10px;
    position: relative;
    counter-increment: olCounter;
    
    &::before {
      @include body;
      content: counter(olCounter) ".";
      display: inline-block;
      position: absolute;
      top: 0;
      left: -20px;
    }
    
    &:last-of-type {
      margin: 0;
    }
  }
}

//## Quotes
.quote {
  margin: 0 0 37px;
  padding: 21px 27px 14px 33px;
  position: relative;

  @media (min-width: $break-medium) {
    margin: 0 0 65px;
  }

  @media (min-width: $break-large) {
    margin: 0 0 100px;
    padding: 21px 29px 14px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 96%;
    width: 14px;
    position: absolute;
    top: 0;
    background-image: url('./../assets/icons/bracket-left--small.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: 2px;

    @media (min-width: $break-medium) {
      width: 12px;
      top: 2px;
    }

    @media (min-width: $break-large) {
      height: 97%;
      top: -1px;
    }
  }

  &::before {
    left: 0;

    @media (min-width: $break-medium) {
      left: -3px;
    }

    @media (min-width: $break-large) {
      left: -5px;
    }
  }

  &::after {
    right: 0;
    transform: scaleX(-1);

    @media (min-width: $break-medium) {
      right: -3px;
    }

    @media (min-width: $break-large) {
      right: -4px;
    }
  }

  &__wrapper {
    margin: 0 0 37px;
    padding: 33px 0 27px;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 65px;
      padding: 41px 0 37px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 100px;
      padding: 78px 0 67px;
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 100vw;
      position: absolute;
      top: 0;
      left: -20px;
      background-image: url('./../assets/img/wrinkle-light-blockquote.jpg');
      background-size: cover;
      z-index: -1;

      @media (min-width: $break-medium) {
        left: -50px;
      }

      @media (min-width: $break-large) {
        left: -300px;
      }
    }

    .quote {
      margin: 0;
    }
  }

  &__copy {
    @include quote;
    margin: 0 0 5px;
    position: relative;

    @media (min-width: $break-large) {
      margin: 0 0 11px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      height: 36px;
      width: 36px;
      top: -11px;
      left: -46px;
      background-color: $color-crumbled-paper-beige;
      border-radius: 100%;

      @media (min-width: $break-medium) {
        top: -5px;
        left: -49px;
      }

      @media (min-width: $break-large) {
        width: 5px;
        top: -6px;
        left: -32px;
        border-radius: 0;
      }
    }

    &::after {
      height: 22px;
      width: 29px;
      top: -5px;
      left: -43px;
      background-image: url('./../assets/icons/quote-quotation-mark.svg');
      z-index: 1;

      @media (min-width: $break-medium) {
        top: 2px;
        left: -46px;
      }

      @media (min-width: $break-large) {
        top: 0;
        left: -41px;
      }
    }
  }

  &__footer {
    display: flex;
  }

  &__attr,
  &__info {
    display: inline-block;
  }

  &__info {
    &::before {
      @include body;
      content: ', ';
    }
  }
}

// styling for pages that have a sidenav in the hero
.hero:not(.hero--no-sidenav) + .container {
  .quote__wrapper {
    &::before {
      @media (min-width: $break-large) {
        left: -300px;
        transform: unset;
      }

      @media (min-width: $content-max) {
        width: 200vw;
        left: -100vw;
      }
    }
  }
}