/**
 * @file
 * Blog
 *
 * Contains styles for Event list.
 */
 .event-list {
  &__items {
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 2rem;
  }

  &__date {
    width: 80px;
    height: 80px;
    border: 1px solid;
    float: left;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__data {
    overflow: hidden;
  }

  &__time {
    font-weight: 700;
  }

  &__title {
    margin-top: 0;
  }

  &__location {
    font-style: italic;
  }
}