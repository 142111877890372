/**
 * @file
 * Twitter
 *
 * Contains styles for twitter object.
 */

 .twitter {
  &__top {
    position: relative;
    margin-bottom: 2.5rem;

    .cta {
      background-color: $color-yellow;

      &:hover {
        background-color: $color-orange-button;
      }

      @media screen and (min-width: $break-large) {
        margin: 0;
      }
    }
  }

  &__header {
    margin-bottom: 0.5rem;

    @media screen and (min-width: $break-large) {
      margin-bottom: 1.8rem;
    }
  }

  &__bottom {
    position: relative;
    padding: 5.1rem 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 40px;
      background-image: url('../assets/icons/bracket-top--large.svg');
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 10000;

      @media screen and (min-width: $break-medium) {
        width: 24px;
        height: 100%;
        background-image: url('../assets/icons/bracket-left--large.svg');
        background-size: auto 100%;
      }

      @media screen and (min-width: $break-large) {
        left: -28px;
      }
    }

    &:after {
      top: auto;
      bottom: 0;
      transform: rotate(180deg);

      @media screen and (min-width: $break-medium) {
        left: auto;
        right: 0;
      }

      @media screen and (min-width: $break-large) {
        right: -28px;
      }
    }
  }

  &__account {
    display: none;
  }

  &__time {
    display: block;
    margin-top: 1rem;
  }

  &__feed {
    @media screen and (min-width: $break-medium) {
      padding: 23px 30px 0;
    }

    @media screen and (min-width: $break-large) {
      display: flex;
      justify-content: space-between;
      padding: 6px 0 0;
    }
  }

  &__item {
    font-family: $font-roboto;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 1.5rem;

    @media screen and (min-width: $break-medium) {
      font-size: 18px;
    }

    @media screen and (min-width: $break-large) {
      padding-bottom: 0;
      flex: 0 0 calc(33% - 16px);
    }

    &:not(:last-of-type) {
      @media screen and (max-width: $break-large - 1) {
        border-bottom: 1px solid $color-black;
        margin-bottom: 2.1rem;
      }
    }

    &:nth-child(2) {
      position: relative;

      &:before,
      &:after {
        @media screen and (min-width: $break-large) {
          content: '';
          position: absolute;
          top: 0;
          left: -16px;
          width: 1px;
          height: 100%;
          background-color: $color-black;
        }
      }

      &:after {
        left: auto;
        right: -16px;
      }
    }

    a {
      &:focus {
        @include light-bg-focus;
      }
    }
  }
 }