.footer {
  padding: 2.6rem 1.9rem 0;

  @media screen and (min-width: $break-medium) {
    padding: 2.6rem 5rem 0;
  }

  a {
    color: $color-black;
    background-image: none;
    transition: color 0.15s;

    &:hover {
      color: $color-orange-dark;
    }

    &:focus {
      @include light-bg-focus;
    }
  }

  &__top {
    padding-bottom: 1.9rem;
    margin-bottom: 1.4rem;
    border-bottom: 1px dashed $color-black;

    @media screen and (min-width: $break-medium) {
      display: flex;
      margin-bottom: 2.3rem;
    }

    @media screen and (min-width: $break-large) {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.8rem;
    }
  }

  &__mid {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 3rem;

    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.9rem;
    }

    @media screen and (min-width: $break-large) {
      margin-bottom: 0;
    }

    &__col {
      display: flex;
      margin-bottom: 1.9rem;
      justify-content: space-between;

      &:first-of-type {
        @media screen and (min-width: $break-large) {
          flex: 0 0 72.5%;
        }
      }

      &__left {
        @media screen and (min-width: $break-large) {
          display: flex;

        }
      }

      &__right {
        flex: 0 0 64px;

        @media (max-width: ($break-medium - 1)) {
          margin-left: 30px;
        }

        @media screen and (min-width: $break-large) {
          margin-top: -0.6rem;
        }

        a {
          display: inline-block;
          padding: 0;
        }
      }
    }
  }

  &__bottom {
    margin: 0 -1.9rem;
    padding: 2.3rem 1.9rem;
    background: $color-yellow-light;
    font-size: 1.4rem;

    @media screen and (min-width: $break-medium) {
      margin: 0 -5rem;
      padding: 1.6rem 5rem;
    }

    a {
      display: block;
      padding: 0;
    }

    p,
    a {
      margin-bottom: 0.5rem;

      @media screen and (min-width: $break-medium) {
        display: inline-block;
        margin-right: 1.7rem;
        margin-bottom: 0;
      }

      @media screen and (min-width: $break-large) {
        margin-bottom: 0.5rem;
      }
    }

    p {
      @media screen and (min-width: $break-large) {
        margin-right: 3.4rem;
      }
    }
  }

  &__logo {
    width: 161px;

    @media screen and (max-width: $break-medium - 1) {
      margin-bottom: 2.3rem;
    }

    @media screen and (min-width: $break-medium) {
      width: 213px;
      margin-right: 3rem;
    }
  }

  &__links {
    @media screen and (min-width: $break-medium) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      column-count: 2;
      column-gap: 5.6rem;
    }

    @media screen and (min-width: $break-large) {
      
    }

    ul {
      padding: 0;

      @media screen and (min-width: $break-large) {
        display: flex;
      }
    }

    li {
      margin-bottom: 0.54rem;

      @media screen and (min-width: $break-medium) {
        margin-bottom: 0.5rem;
      }

      @media screen and (min-width: $break-large) {
        margin-right: 3.1rem;
        margin-bottom: 0;
      }

      &:before {
        content: none;
      }
    }

    a {
      @media screen and (min-width: $break-large) {
        padding-bottom: 0;
      }
    }
  }

  &__address {
    font-style: normal;
    line-height: 1.4;
    margin-bottom: 1rem;

    @media screen and (min-width: $break-large) {
      margin-right: 7.2rem;
    }
  }

  &__info {
    @media (max-width: ($break-medium - 1)) {
      display: flex;
      flex-direction: column;
    }

    a {
      margin: 0;
      padding: 0;

      @media screen and (min-width: $break-medium) {
        display: block;
      }

      @media screen and (min-width: $break-large) {
        line-height: 1.4;
      }
    }
  }

  &__social {
    ul {
      display: flex;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 0;

      &:before {
        content: none;
      }

      &:not(:last-of-type) {
        margin-right: 0.8rem;
      }

      a {
        position: relative;
        display: block;
        width: 42px;
        height: 42px;
        background-color: $color-orange-button;
        transition: background-color 0.15s;

        &:hover {
          background-color: $color-yellow;

          svg {
            path {
              fill: $color-black;
            }
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &--foundation {
    .footer__logo {
      @media screen and (min-width: $break-medium) {
        width: 262px;
      }
    }

    .footer__links {
      a {
        font-weight: 800;
      }

      li:first-of-type {
        a {
          // background-image: url('./../assets/icons/underline-orange-thin.svg');
          // background-repeat: no-repeat;
          // background-position-y: 19px;
          // padding-bottom: 10px;
          @include body-link--thin;
        }
      }
    }
  }
}