
.alert {
  padding: 27px 20px 29px;

  @media (min-width: $break-medium) {
    padding: 28px 50px 30px 123px;
  }

  @media (min-width: $break-large) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 23px 48px 13px 143px;
    min-height: 130px;
    position: relative;
  }

  &__content {
    @media (min-width: $break-large) {
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }
  }
  
  &__message {
    margin: 0 0 12px;

    @media (min-width: $break-medium) {
      margin: 0 0 10px;
    }
  }

  &__title {
    @include h6;
    margin: 0 0 6px;
    padding: 0 45px 0 0;
    position: relative;

    @media (min-width: $break-medium) {
      margin: 0 0 2px;
      padding: 0;
    }

    @media (min-width: $break-large) {
      margin: 0;
      position: unset;
    }
  }

  &__icon {
    &:before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 3px;
      right: 1px;
      background-color: $color-black;
      background-image: url('/assets/icons/exclamation-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 4px;
      border-radius: 100%;
      transform: translateY(-50%);

      @media (min-width: $break-medium) {
        width: 55px;
        height: 55px;
        top: 24px;
        right: unset;
        left: -73px;
        background-size: 7.5px;
      }

      @media (min-width: $break-large) {
        width: 80px;
        height: 80px;
        top: 65px;
        left: 48px;
        background-size: 11px;
      }
    }
  }

  &__cta {
    margin: 0;

    @media (min-width: $break-large) {
      align-self: center;
      position: relative;
      top: -5px;
      white-space: nowrap;
    }
  }

  &--minor {
    background-color: $color-orange-alert;

    .alert__cta {
      background-color: $color-yellow-alert;

      &:hover {
        background-color: $color-orange-button;
      }
    }
  }
  
  &--medium {
    background-color: $color-yellow-alert;
  }
  
  &--major {
    background-color: $color-red-alert;
    color: $color-white;

    .alert__icon {
      &::before {
        background-color: $color-white;
        background-image: url('./../assets/icons/exclamation-black.svg');
      }
    }

    .alert__cta {
      background-color: $color-yellow-alert;

      &:hover {
        background-color: $color-orange-button;
      }

      &:focus {
        @include dark-bg-focus;
      }
    }
  }
}