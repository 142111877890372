/**
 * @file
 * Instagram
 *
 * Contains styles for instagram object.
 */

.instagram {
  margin-bottom: 11.3rem;

  @media screen and (min-width: $break-medium) {
    margin-bottom: 23.6rem;
  }

  @media screen and (max-width: $break-large - 1) {
    @include full-width;
  }

  @media screen and (min-width: $break-large) {
    margin-bottom: 22rem;
  }

  &__account {
    position: relative;
    text-align: right;
    padding: 9rem 20px 3rem;

    @media screen and (min-width: $break-medium) {
      padding: 14rem 50px 3.5rem;
    }

    @media screen and (min-width: $break-large) {
      padding: 20rem 0 3.5rem;
    }

    @media screen and (min-width: 1600px) {
      padding: calc(100vw * 0.15) 0 3.5rem;
    }


    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      width: calc(100% + 40px);
      height: 100%;
      background-image: url('../assets/img/rip-orange-hero-home.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
      z-index: -1;

      @media screen and (min-width: $break-large) {
        left: -300px;
        width: 100vw;
      }

      @media screen and (min-width: $content-max) {
        left: calc(-300px - ((100vw - #{$content-max}) / 2));
      }
    }

    .cta {
      margin: 0;
      background: $color-yellow;

      &:hover {
        background: $color-orange-button;
      }

      &:focus {
        @include dark-bg-focus;
      }
    }
  }

  &__header {
    margin: 0 0 2.3rem auto;
    width: 200px;

    @media screen and (min-width: $break-medium) {
      margin: 0 0 1.5rem auto;
      width: 420px;
    }

    @media screen and (min-width: $break-large) {
      margin: 0 0 1.8rem auto;
      width: 530px;
    }
  }

  &__feed {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 7.1rem 20px 0;
    background: $color-orange-dark;

    @media screen and (max-width: $break-medium - 1) {
      flex-wrap: wrap;
    }

    @media screen and (min-width: $break-medium) {
      padding: 10.3rem 50px 0;
    }

    @media screen and (min-width: $break-large) {
      padding: 3.7rem 0 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 3rem;
      left: 0.7rem;
      width: 151px;
      height: 46px;
      background: url('../assets/img/tape-black.png');
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(157deg);
      z-index: 1;

      @media screen and (min-width: $break-medium) {
        top: 5.5rem;
        left: 1.7rem;
        width: 220px;
        height: 55px;
        transform: rotate(160.5deg);
      }

      @media screen and (min-width: $break-large) {
        top: -1.1rem;
        left: -5.3rem;
        transform: rotate(159.5deg);
      }
    }

    &:after {
      @media screen and (min-width: $break-large) {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200vw;
        height: 100%;
        background: $color-orange-dark;
        z-index: -1;
      }
    }
  }

  &__tile {
    position: relative;
    display: block;
    width: calc((100vw - 40px) / 2 - 0.3rem);
    height: calc((100vw - 40px) / 2 - 0.3rem);
    padding: 0;
    margin: 0 0 0.6rem;
    opacity: 0;
    top: 30px;
    transition: opacity 0.15s ease, top 0.15s ease;

    @media screen and (min-width: $break-medium) {
      width: calc((100vw - 100px) / 3 - 1.4rem);
      height: calc((100vw - 100px) / 3 - 1.4rem);
      margin-bottom: -14.5rem;
    }

    @media screen and (min-width: $break-large) {   
      width: auto;
      flex: 0 0 calc(100% / 3 - 3.6rem);
      height: calc((100vw - 400px) / 3 - 3.6rem);
      margin-bottom: -10.2rem;
    }

    @media screen and (min-width: $content-max) {
      flex: 0 0 431px;
      height: 431px;
    }

    &:nth-child(2) {
      transition-delay: 0.15s;
    }

    &:nth-child(3) {
      transition-delay: 0.3s;
    }

    &:last-of-type {
      @media screen and (max-width: $break-medium - 1) {
        margin-bottom: -6rem;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-size: cover;
    background-position: 0;

    &:hover {
      background-position: 0;
    }

    &:focus {
      @include dark-bg-focus;
    }

    span {
      @include ir;
    }
  }

  &.js-active {
    .instagram__tile {
      opacity: 1;
      top: 0;
    }
  }
}

.hero--no-sidenav {
  + .container {
    .instagram__account:after {
      @media screen and (min-width: $break-large) {
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
      }
    }

    .instagram__tile {
      @media screen and (min-width: $break-large) {
        height: calc((100vw - 200px) / 3 - 3.6rem);
      }
    }
  }
}