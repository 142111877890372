/**
 * @file
 * Typography
 * Base typography for the theme
 */

//## Headers
h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

//## Paragraphs
p {
  margin: 0 0 1em;

  &:empty {
    display: none;
  }
}

strong {
  font-weight: 700;
}
