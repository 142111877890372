.impact {
  margin: 55px 0;
  padding: 46px 0;
  position: relative;

  @media (min-width: $break-medium) {
    padding: 78px 0 40px;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
    padding: 106px 0 129px;
  }

  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    background-image: url('./../assets/img/wrinkle-light.jpg');
    background-size: cover;
    transform: translateX(-50%);
  }

  &__heading {
    margin: 0 0 51px;

    @media (min-width: $break-large) {
      margin: 0 0 65px;
    }

    &--orange {
      color: $color-orange;
    }

    h2 {
      margin: 0;

      @media (min-width: $break-large) {
        font-size: 5.5rem;
        line-height: 1;
      }
    }
  }

  &__wrapper {
    @media (min-width: $break-large) {
      display: flex;
      gap: 64px;
    }
  }

  &__stats {
    margin: 0 0 40px;
    padding: 0 19px 37px 26px;
    background-color: $color-yellow-light;

    @media (min-width: $break-medium) {
      display: flex;
      gap: 22px;
      margin: 0 0 50px;
      padding: 0 27px 34px 29px;
    }

    @media (min-width: $break-large) {
      display: block;
      margin: 0 0 0 -31px;
      padding: 0 31px 52px 55px;
      width: 371px;
    }
  }

  &__stat {
    margin: 0;

    @media (min-width: $break-medium) {
      flex-basis: 50%;
    }
    
    &:first-child {
      position: relative;
      top: -28px;

      @media (min-width: $break-large) {
        top: -30px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 4px;
      }
    }
    
    &:last-child {
      .impact__stat-icon {
        @media (min-width: $break-medium) and (max-width: ($break-large - 1)) {
          margin-left: -1px;
        }
      }
    }
    
    &:first-child,
    &:last-child {
      @media (min-width: $break-medium) and (max-width: ($break-large - 1)) {
        margin-bottom: -35px;
        position: relative;
        top: -35px;
      }
    }

    &-icon {
      margin: 0 0 18px -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background-color: $color-yellow;
      border-radius: 100%;

      @media (min-width: $break-medium) {
        margin: 0 0 19px -10px;
        width: 79px;
        height: 79px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 17px;
        width: 80px;
        height: 80px;
        position: relative;
        left: -26px;
      }

      img {
        max-width: 28px;
        max-height: 28px;

        @media (min-width: $break-medium) {
          max-width: 37px;
          max-height: 37px;
        }
      }
    }

    &-number {
      @include h3;
      margin: 0 0 8px;

      @media (min-width: $break-medium) {
        margin: 0 0 7px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 30px;
      }
    }

    &-text {
      margin: 0 0 8px;

      @media (min-width: $break-medium) {
        margin: 0 0 30px;
        max-width: 80%;
      }
    }

    &-cta {
      .cta {
        margin: 0;
      }
    }
  }

  &__stories {
    @media (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media (min-width: $break-large) {
      display: block;
      width: 60%;
    }
  }

  &__story {
    margin: 0 0 49px;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 11px);
      margin: 0 0 40px;
    }

    @media (min-width: $break-large) {
      display: flex;
      gap: 36px;
      margin: 0 0 37px;
    }

    &:last-child {
      margin: 0;
    }

    &-img {
      margin: 0 0 16px;
      padding: 75.5% 0 0;
      position: relative;

      @media (min-width: $break-medium) {
        margin: 0 0 21px;
      }

      @media (min-width: $break-large) {
        margin: 0;
        padding: 36% 0 0;
        min-width: 48%;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    &-content {

    }

    &-headline {
      margin: 0 0 24px;

      @media (min-width: $break-medium) {
        margin: 0 0 14px;
      }

      @media (min-width: $break-large) {
        margin: 0 0 19px;
      }

      h3 {
        @include story-titles;
        margin: 0;
      }
    }

    &-cta {
      .cta {
        margin: 0;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
}