.tall-cards {
  &__top {
    position: relative;
    margin-bottom: 5.4rem;

    @media screen and (min-width: $break-medium) {
      margin-bottom: 11.8rem;
    }

    @media screen and (min-width: $break-large) {
      margin-bottom: 4.5rem;
    }

    &:after {
      content: '';
      position: absolute;
      right: -2px;
      bottom: -37px;
      width: 118px;
      height: 118px;
      background: url('../assets/img/arrow--tall-card.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(-16deg);
      opacity: 0;
      transition: opacity 0.15s;

      @media screen and (min-width: $break-medium) {
        right: -42px;
        bottom: -89px;
        width: 220px;
        height: 220px;
        transform: rotate(-4deg);
      }

      @media screen and (min-width: $break-large) {
        right: -85px;
        bottom: -65px;
        transform: rotate(0);
      }
    }
  }

  &__header {
    line-height: 1;
    margin: 0 0 1.63rem;
    max-width: 340px;

    @media screen and (min-width: $break-medium) {
      line-height: 1.1;
      margin: 0 0 1.3rem;
      max-width: calc(100% - 170px);
    }
  }

  &__subtitle {
    position: relative;
    font-family: $font-matrice;
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.3;
    display: inline-block;
    width: 176px;
    padding: 0 11px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: calc(100% + 10px);
      transform: translateY(-50%);
      background-image: url('../assets/icons/bracket-tall-cards--left.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media screen and (min-width: $break-medium) {
        width: 17px;
        height: calc(100% + 20px);
      }

      @media screen and (min-width: $break-large) {
        width: 11px;
        height: calc(100% + 14px);    
      }
    }

    &:after {
      left: auto;
      right: 0;
      background-image: url('../assets/icons/bracket-tall-cards--right.svg');
    }

    @media screen and (min-width: $break-medium) {
      width: auto;
      max-width: calc(100% - 170px);
      font-size: 2rem;
      padding: 0 22px;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2.8rem;
      padding: 0 17px;
    }
  }

  &__cards {
    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .orange {
    color: $color-orange;
  }

  .card {
    display: block;
    margin-bottom: 1rem;
    position: relative;
    opacity: 0;
    transition: opacity 0.15s;

    @media screen and (min-width: $break-medium) {
      flex: 0 0 48.5%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 2rem;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 46.8%;
      margin-bottom: 4rem;
      position: relative;
    }

    &:nth-child(2) {
      transition-delay: 0.15s;
    }

    &:nth-child(3) {
      transition-delay: 0.3s;
    }

    &:nth-child(4) {
      transition-delay: 0.45s;
    }

    &:hover {
      .card {
        &__top {
          background-color: $color-orange-dark;
        }

        &__header .last-word span:before {
          left: 10px;
  
          @media screen and (min-width: $break-medium) {
            left: 14px;
          }
        }

        &__img {
          &::before {
            @media (min-width: $break-large) {
              opacity: 0.2;
            }
          }
        }

        &__content {
          @media (min-width: $break-large) {
            bottom: 0;
          }
        }

        &__stat {
          @media (min-width: $break-large) {
            opacity: 1;
          }
        }
      }
    }

    &__inner {
      position: relative;

      @media (min-width: $break-medium) {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      @media (min-width: $break-large) {
        overflow: hidden;
      }
    }

    &__link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }

    &__top {
      padding: 3.5rem 20px 2rem;
      background-color: $color-orange;

      @media screen and (min-width: $break-large) {
        padding: 4.5rem 30px 3.7rem;
        transition: background-color 0.15s;
      }
    }

    &__header {
      @include patternAfterArrow(18px, 18px, 22.5px);
      margin: 0 0 1.6rem;

      @media screen and (min-width: $break-medium) {
        font-size: 2rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 2.4rem;
        margin: 0 0 1rem;
      }
    }

    &__img {
      @media (min-width: $break-large) {
        position: relative;
      }

      &::before {
        @media (min-width: $break-large) {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color-orange;
          opacity: 0;
          transition: opacity 0.15s;
        }
      }

      img {
        width: 100%;
      }
    }

    &__content {
      position: relative;
      background-color: $color-black;
      padding: 1.1rem 20px 2.6rem;

      @media screen and (min-width: $break-medium) {
        flex: 1 0 auto;
      }

      @media (min-width: $break-large) {
        width: 100%;
        position: absolute;
        bottom: -85px;
        transition: bottom 0.15s;
      }

      &:after {
        content: '';
        position: absolute;
        top: -10vw;
        left: 0;
        width: 100%;
        height: calc(100% + 10vw);
        z-index: 1;
        background: url('../assets/img/paper-textured-black-ripped--tall-card.png');
        background-position: top center;
        background-size: cover;

        @media screen and (min-width: $break-medium) {
          top: -6vw;
          height: calc(100% + 6vw);
        }
      }
    }

    &__stat {
      position: relative;
      z-index: 2;
      color: $color-white;
      line-height: 1;

      @media screen and (min-width: $break-medium) {
        margin-bottom: 1.6rem;
      }

      @media (min-width: $break-large) {
        opacity: 0;
        transition: opacity 0.15s;
      }

      &:not(:last-of-type) {
        margin-bottom: 1.2rem;
      }
    }

    &__number {
      font-family: $font-matrice;
      font-size: 2.5rem;
      font-weight: 800;
      margin-right: 0.3rem;

      @media screen and (min-width: $break-medium) {
        font-size: 3rem;
      }
    }

    &__text {
      font-family: $font-roboto;
      font-size: 1.6rem;
      font-weight: 400;

      @media screen and (min-width: $break-medium) {
        font-size: 1.8rem;
      }
    }
  }


  &.js-active {
    &--arrow {
      .tall-cards__top:after {
        opacity: 1;
      }
    }

    .tall-cards__top:after {
      opacity: 1;
    }

    .card {
      opacity: 1;
    }
  }
}