/**
 * @file
 * Form
 *
 * Contains styles for forms in main content region.
 */
.main .form {
  margin: 0 0 45px;

  @media (min-width: $break-medium) {
    margin: 0 0 60px;
  }

  @media (min-width: $break-large) {
    margin: 0 0 100px;
  }

  &__note {
    margin: 0 0 2.2rem;

    @media (min-width: $break-medium) {
      margin: 0 0 1.8rem;
      font-size: 1.8rem;
      line-height: 2.8rem; 
    }

    @media (min-width: $break-large) {
      margin: 0 0 1.9rem;
    }

    .marked {
      color: $color-orange;
    }
  }

  &__item {
    margin: 0 0 23px;

    @media (min-width: $break-medium) {
      margin: 0 0 30px;
    }

    &:last-child {
      margin: 0;
    }

    &:not(.form--fieldset) {
      label {
        @include body-bold;
        display: block;
        margin: 0 0 4px;
      }
    }

    &.form--fieldset {
      @media (min-width: $break-large) {
        margin: 0 0 36px;
      }

      legend {
        @include body-bold;
        display: block;
        margin: 0 0 6px;

        @media (min-width: $break-medium) {
          margin: 0 0 3px;
        }
      }

      label {
        @include body;
      }
    }

    &.form--select {
      margin: 0 0 32px;

      @media (min-width: $break-large) {
        margin: 0 0 30px;
      }
    }

    &.form--text,
    &.form--email,
    &.form--tel,
    &.form--select {
      max-width: 280px;
    }
  }

  &__checkbox {
    margin: 0 0 5px;

    @media (min-width: $break-medium) {
      margin: 0 0 2px;
    }

    &:last-of-type {
      margin: 0;
    }

    &-wrapper {
      display: inline-block;
      position: relative;

      svg {
        height: 8px;
        width: 10px;
        position: absolute;
        top: 10px;
        left: 2px;
        opacity: 0;
        transition: opacity 0.15s;

        @media (min-width: $break-medium) {
          top: 12px;
        }
      }
    }
  }

  &__radio {
    margin: 0 0 6px;

    @media (min-width: $break-medium) {
      margin: 0 0 3px;
    }

    &:last-of-type {
      margin: 0;
    }

    &-wrapper {
      display: inline-block;
      position: relative;

      svg {
        position: absolute;
        top: 11px;
        left: 4px;

        @media (min-width: $break-medium) {
          top: 13px;
        }
      }
    }
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
  @include body;
  display: block;
  padding: 7px 14px;
  width: 100%;
  border: solid 1px $color-gray;

  &:focus {
    @include light-bg-focus;
  }

  @media (min-width: $break-medium) {
    padding: 7px 14px 5px;
  }
}

textarea {
  min-height: 119px;
  min-width: 100%;
  max-width: 100%;
}

fieldset {
  padding: 0;
  border: none;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0 6px 0 0;
  position: relative;
  top: 2px;
  
  &:focus {
    @include light-bg-focus;
  }

  &:checked {
    &::before {
      background-color: $color-yellow;
      border-color: $color-yellow;
    }

    &::after {
      opacity: 1;
    }

    & + svg {
      opacity: 1;
    }
  }
  
  &::before {
    content: '';
    display: block;
    height: 14px;  
    width: 14px;
    background-color: $color-white;
    border: solid 1px $color-gray;
    box-sizing: border-box;
    transition: background-color 0.15s,
                border-color 0.15s;
  }

  & + svg {
    pointer-events: none;
  }
}

input[type="radio"] {
  &::before {
    border-radius: 100%;
  }

  & + svg {
    opacity: 0;
  }
}

.select__wrapper {
  position: relative;

  &::before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 17px;
    right: 14px;
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: rotate(135deg);
    pointer-events: none;
  }
}

select {
  @include body;
  padding: 7px 25px 9px 14px;
  width: 100%;
  color: $color-black;
  background-color: $color-white;
  border: solid 1px $color-gray;
  appearance: none;

  @media (min-width: $break-medium) {
    padding: 7px 25px 5px 14px;
  }

  &:focus {
    @include light-bg-focus;
  }
}

.submit__wrapper {
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 10px;
    width: 29px;
    position: absolute;
    top: 16px;
    right: 18px;
    background-image: url('./../assets/icons/arrow-right-black.svg');
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    transition: left 0.15s;
  }

  input[type="submit"] {
    margin: 0;
  }
}

.search__wrapper {
  position: relative;

  svg {
    display: block;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 11px;
    right: 9px;
    background-image: url('./../assets/icons/search-black.svg');
    background-size: cover;
  }

  input {
    padding-right: 40px;
  }
}