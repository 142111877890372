/**
 * Mixins
 * Contains global SCSS mixins
 */

/// Clearfix
@mixin clearfix() {
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

/// Image replacement
@mixin ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;                     // Remove the default border from elements like <button>
  font: 0/0 a;                   // Crush the text down to take up no space
  text-shadow: none;             // Remove any text shadows
  color: transparent;            // Hide any residual text in Safari 4 and any mobile devices that may need it
  background-color: transparent; // Hide the default background color on elements like <button>
}

/// Invisible elements
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

/// Show an invisible element
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/// Show invisible element when focused
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/// Full width mixin to break content out into gutters
@mixin full-width() {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
}

/// Content Gutter
@mixin gutter {
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: $break-medium) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (min-width: $break-large) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

/// Maximum width Object
@mixin content-full {
  max-width: $content-max;
  margin-left: auto;
  margin-right: auto;
}

/// Gradient overlay
@mixin gradient-overlay {
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba($color-white, 0) 0%, rgba($color-white, 0) 45%, $color-black 100%);
    content: '';
  }
}

/// Placeholder mixin
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}


/// CTA mixin
@mixin cta {
  margin: 1.5rem 0 0;

  a {
    display: inline-block;
    padding: 0.125rem 0.5rem;
    background: $color-black;
    color: $color-white;
  }
}

/// Font Mixins
@mixin h1 {
  font-family: $font-matrice;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 4.5rem;
  }

  @media (min-width: $break-large) {
    font-size: 7rem;
  }
}

@mixin h2 {
  font-family: $font-matrice;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.1;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 4rem;
  }

  @media (min-width: $break-large) {
    font-size: 5.5rem;
  }
}

@mixin h3 {
  font-family: $font-matrice;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 3rem;
  }

  @media (min-width: $break-large) {
    font-size: 4rem;
    line-height: 1.1;
  }
}

@mixin h4 {
  font-family: $font-matrice;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 2rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.8rem;
    line-height: 1.1;
  }
}

@mixin h5 {
  font-family: $font-matrice;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.8rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.5rem;
    line-height: 1.1;
  }
}

@mixin h6 {
  font-family: $font-matrice;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.4rem;
  }

  @media (min-width: $break-large) {
    font-size: 1.6rem;
  }
}

@mixin body {
  font-family: $font-roboto;
  font-weight: 400;
  line-height: 1.67;
}

@mixin body-bold {
  @include body;
  font-weight: 700;
}

@mixin body-link {
  position: relative;
  padding: 0 0 7px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 4px;
    background-image: url('./../assets/icons/underline-orange.svg');
    background-repeat: repeat-x;
    background-position: left bottom;
    transition: bottom 0.15s ease;
  }

  &:hover {
    &:after {
      bottom: 0;
    }
  }

  &.cta--stretched,
  &.cta--link--with-arrow {
    position: unset;

    &::after {
      content: none;
    }

    .cta__inner {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-image: url('./../assets/icons/underline-orange.svg');
        background-repeat: repeat-x;
        background-position: left bottom;
        transition: bottom 0.15s ease;
      }  
    }

    &:hover {
      .cta__inner {
        &:after {
          bottom: -8px;
        }
      }
    }
  }
}

@mixin body-link--thin {
  position: relative;
  padding: 0 0 7px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 4px;
    background-image: url('./../assets/icons/underline-orange-thin.svg');
    background-repeat: repeat-x;
    background-position: left bottom;
    transition: bottom 0.15s ease;
  }

  &:hover {
    &:after {
      bottom: 0;
    }
  }
}

@mixin body-text-intro {
  font-family: $font-matrice;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.97;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.6rem;
    line-height: 1.67;
  }

  @media (min-width: $break-large) {
    line-height: 1.6;
  }
}

@mixin intro-paragraph {
  font-family: $font-roboto;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;

  @media (min-width: $break-medium) {
    font-size: 2rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.7rem;
    line-height: 1.45;
  }
}

@mixin intro-first-line {
  font-family: $font-matrice;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 2.1rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.5rem;
    line-height: 1.45;
  }
}

@mixin eyebrow {
  font-family: $font-matrice;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.6;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.8rem;
    line-height: 1.5;
  }

  @media (min-width: $break-large) {
    font-size: 2.1rem;
    line-height: 1.8;
  }
}

@mixin quote {
  font-family: $font-matrice;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.8rem;
  }

  @media (min-width: $break-large) {
    font-size: 2rem;
    line-height: 1.5;
  }
}

@mixin caption {
  font-family: $font-roboto;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;

  @media (min-width: $break-medium) {
    font-size: 1.4rem;
  }

  @media (min-width: $break-large) {
    line-height: 1.3;
  }
}

@mixin content-card-title {
  font-family: $font-matrice;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 2rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.4rem;
  }
}

@mixin tape-callouts {
  // designed for SM and MD screens
  font-family: $font-matrice;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.25;
  text-transform: uppercase;
}

@mixin tape-callouts-small {
  // designed only to be used on LG screens
  font-family: $font-matrice;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.25;
  text-transform: uppercase;
}

@mixin tape-callouts-medium {
  // designed only to be used on LG screens
  font-family: $font-matrice;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase;
}

@mixin tape-callouts-large {
  font-family: $font-matrice;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.25;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.6rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.1rem;
    line-height: 1.2;
  }
}

@mixin number-callout {
  font-family: $font-matrice;
  font-size: 4rem;
  font-weight: 800;
  line-height: calc(30 / 40);

  @media (min-width: $break-large) {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@mixin date-tag {
  font-family: $font-roboto;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;

  @media (min-width: $break-medium) {
    font-size: 1.4rem;
  }

  @media (min-width: $break-large) {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

@mixin story-titles {
  font-family: $font-matrice;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.6rem;
    line-height: 1.3;
  }

  @media (min-width: $break-large) {
    line-height: 1.2;
  }
}

@mixin programs-names {
  font-family: $font-matrice;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase;

  @media (min-width: $break-medium) {
    font-size: 1.8rem;
  }

  @media (min-width: $break-large) {
    font-size: 2.1rem;
  }
}

/// Crumbled paper mixins
@mixin skewed-crumbled-paper-behind-image {
  position: relative;
  
  &::before {
    content: '';
    display: block;
    height: 94.7%;
    width: 98%;
    position: absolute;
    top: -2px;
    left: -6px;
    z-index: -1;
    background-image: url('./../assets/img/wrinkle-light.jpg');
    background-size: cover;
    transform: matrix(-1, 0.03, 0.03, 1, 0, 0);

    @media (min-width: $break-medium) {
      height: 95.25%;
      top: -5px;
      left: -13px;
    }

    @media (min-width: $break-large) {
      top: -8px;
      left: -17px;
    }
  }
}

/// Tape mixins
@mixin dark-tape-small($position: 'right') {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 40px;
    width: 163px;
    position: absolute;
    z-index: 1;
    background-image: url('./../assets/img/tape-black.png');
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $break-medium) {
      height: 57px;
      width: 230px;
      background-size: cover;
    }
  
    @if $position == 'right' {
      top: -8px;
      right: -88px;
      transform: rotate(-159deg);

      @media (min-width: $break-medium) {
        top: -34px;
        right: -101px;
        transform: rotate(-160deg);
      }

      @media (min-width: $break-large) {
        top: -30px;
        right: -155px;
        transform: rotate(-159.5deg);
      }
    }
  }
}

@mixin dark-tape-background {
  content: '';
  display: block;
  width: 104%;
  height: 101%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url('./../assets/img/tape-black.png');
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotate(-1deg);

  @media (min-width: $break-medium) {
    width: 100%;
  }
}

@mixin dark-tape-behind-heading {
  // should be applied to a heading that contains an anchor
  @include tape-callouts-large;
  display: flex;
  align-items: center;
  padding: 24px 55px 19px 11%;
  min-height: 78px;
  width: 100%;
  margin: 0;
  position: relative;
  z-index: 1;
  color: $color-white;
  transition: width 0.15s;

  @media (min-width: $break-medium) {
    padding: 14px 60px 12px 11.5%;
  }

  @media (min-width: $break-large) {
    padding: 27px 75px 21px 12%;
    min-height: 98px;
  }

  &::before {
    @include dark-tape-background;
  }

  &.dark-tape-link {
    &::before {
      content: none;
    }

    a, span {
      display: inline-block;
      padding: 0;
      font-weight: 800;
      background-image: none;

      &:hover {
        &::before {
          width: calc(104% + 12px);

          @media (min-width: $break-medium) {
            width: calc(100% + 12px);
          }

          @include iOSSafari {
            width: 104%;
          }
        }

        &::after {
          right: -2px;

          @media (min-width: $break-medium) {
            right: 9px;
          }

          @media (min-width: $break-large) {
            right: 13px;
          }

          @include iOSSafari {
            right: 10px;
          }
        }
      }

      &::before {
        @include dark-tape-background;
        transition: width 0.15s;
      }
    }
  }

  a, span {
    display: block;
    color: $color-white;
    text-decoration: none;
    background-image: none;

    &:focus {
      @include dark-bg-focus;
    }

    &::after {
      content: '';
      display: block;
      width: 34px;
      height: 10px;
      position: absolute;
      top: 41%;
      right: 10px;
      background-image: url('./../assets/icons/arrow-right-white.svg');
      background-size: cover;
      background-repeat: no-repeat;
      transition: right 0.15s;

      @media (min-width: $break-medium) {
        top: 40%;
        right: 21px;
      }

      @media (min-width: $break-large) {
        width: 42px;
        height: 12px;
        right: 25px;
      }
    }
  }
}

@mixin orange-background-bar {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 52.53%;
    min-height: 109px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: -20px;
    z-index: -2;
    background-color: $color-orange;

    @media (min-width: $break-medium) {
      height: 48.964%;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: $break-large) {
      height: 65.248%;
      min-height: 341px;
    }
  }
}

/// Other Cowley-specific mixins
@mixin cowley-sticker {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    left: -16px;
    width: 68px;
    height: 68px;
    background-image: url('../assets/img/cowley-sticker.png');
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (min-width: $break-medium) {
      top: 19px;
      left: -25px;
    }

    @media screen and (min-width: $break-large) {
      top: 25px;
      left: -32px;
      width: 86px;
      height: 86px;
    }
  }
}

/// Link mixins
@mixin cta {
  display: inline-block;
  margin: 18px 0;
  font-family: $font-matrice;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  color: $color-black;
  border: none;

  @media (min-width: $break-medium) {
    margin: 23px 0;
  }

  @media (min-width: $break-large) {
    margin: 30px 0;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    @include light-bg-focus;
  }
}

@mixin cta-button {
  padding: 15px 62px 15px 18px;
  font-size: 1.2rem;
  background-color: $color-orange-button;
  background-image: none;
  transition: padding 0.15s,
              background-color 0.15s;

  @media (min-width: $break-medium) {
    font-size: 1.4rem;
    padding: 16px 62px 16px 18px;
  }

  .last-word {
    span {
      @include after-arrow;
    }
  }

  &:hover {
    padding-right: 70px;
    background-color: $color-yellow;

    .last-word {
      span {
        &::before {
          left: 22px;
        }
      }
    }
  }
}

@mixin after-arrow {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 10px;
    width: 29px;
    position: absolute;
    top: 1px;
    left: 14px;
    background-image: url('./../assets/icons/arrow-right-black.svg');
    background-repeat: no-repeat;
    background-size: cover;
    transition: left 0.15s;

    @media (min-width: $break-medium) {
      top: 2px;
    }
  }
}

@mixin patternAfterArrow($arrowSm, $arrowMd, $arrowLg) {
  margin-right: calc(#{$arrowSm} * 0.75);

  @media screen and (min-width: $break-medium) {
    margin-right: calc(#{$arrowMd} * 0.75);
  }

  @media screen and (min-width: $break-large) {
    margin-right: calc(#{$arrowLg} * 0.75);
  }

  &.cta--link {
    .cta__inner {
      &::after {
        width: calc(100% - (#{$arrowSm} * 2) - (#{$arrowSm} / 1.2));
  
        @media (min-width: $break-medium) {
          width: calc(100% - (#{$arrowMd} * 2) - (#{$arrowMd} / 1.2));
        }
  
        @media (min-width: $break-large) {
          width: calc(100% - (#{$arrowLg} * 2) - (#{$arrowLg} / 1.2));
        }
      }
    }
  }

  .last-word {
    display: inline-block;

    span {
      position: relative;
      display: inline-block;
      width: calc(#{$arrowSm} * 2);
      height: calc(#{$arrowSm});
      margin-left: calc(#{$arrowSm} / 1.2);

      @media screen and (min-width: $break-medium) {
        width: calc(#{$arrowMd} * 2);
        height: calc(#{$arrowMd});
        margin-left: calc(#{$arrowMd} / 1.2);
      }

      @media screen and (min-width: $break-large) {
        width: calc(#{$arrowLg} * 2);
        height: calc(#{$arrowLg});
        margin-left: calc(#{$arrowLg} / 1.2);
      }

      &:before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: calc(50% + (#{$arrowSm} * 0.2));
        left: 0;
        background-image: url('./../assets/icons/arrow-right-black.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transition: left 0.15s;
        transform: translateY(-50%);

        @media screen and (min-width: $break-medium) {
          top: calc(50% + (#{$arrowMd} * 0.1));
        }

        @media screen and (min-width: $break-large) {
          top: calc(50% + (#{$arrowLg} * 0.1));
        }
      }
    }
  }

  &:hover {
    .last-word span:before {
      left: calc(#{$arrowSm} * 0.75);

      @media screen and (min-width: $break-medium) {
        left: calc(#{$arrowMd} * 0.75);
      }

      @media screen and (min-width: $break-large) {
        left: calc(#{$arrowLg} * 0.75);
      }
    }
  }
}

/// Focus mixins
@mixin light-bg-focus {
  outline: solid 2px $color-black;
  outline-offset: 2px;
}

@mixin dark-bg-focus {
  outline: solid 2px $color-white;
  outline-offset: 2px;
}

/// Browser-specific mixins
@mixin iOSSafari {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}