/**
 * @file
 * Page
 *
 * Contains styles for page wrapper layout.
 */

.container {
  @include gutter;
  max-width: $content-max;
  margin: 0 auto;
  margin-bottom: 60px;

  @media (min-width: $break-large) {
    margin-bottom: 100px;
  }

  @include iOSSafari {
    overflow-x: hidden;
  }

  &--even-gutter {
    @media screen and (min-width: $break-large) {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  & > *:last-child {
    margin-bottom: 0;

    & > *:last-child {
      margin-bottom: 0;

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  & + .footer-sock {
    margin-top: -60px;

    @media (min-width: $break-large) {
      margin-top: 6vw;
    }

    @media (min-width: $content-max) {
      margin-top: 7.5vw;
    }
  }
}

.container > .wysiwyg {
  max-width: 800px;
}

// Toggle left padding on when section navigation is active
.hero:not(.hero--no-sidenav) {
  + .container {
    @media screen and (min-width: $break-large) {
      padding-left: 300px;
    }
  }
}

.hero.hero--no-sidenav {
  + .container > .wysiwyg {
    margin: 0 auto;
  }
}

.foundation {
  .container {
    @media (min-width: $break-large) {
      padding-right: 80px;
      padding-left: 80px;
    }
  }
}