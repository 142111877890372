/**
 * @file
 * Student of the Month
 *
 * Contains styles for Student of the Month object.
 */

 .student-of-the-month {
  margin: 42px 0 35px;
  padding: 30px 0 39px;
  position: relative;

  @media (min-width: $break-medium) {
    margin: 87px 0 60px;
    padding: 58px 0 67px;
  }

  @media (min-width: $break-large) {
    margin: 154px 0 100px;
    padding: 83px 0 133px;
  }

  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: -20px;
    z-index: -1;
    background-image: url('../assets/img/paper-textured-white.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $break-medium) {
      left: -50px;
    }

    @media (min-width: $break-large) {      
      left: -300px;
    }

    @media screen and (min-width: $content-max) {
      left: calc(-300px - ((100vw - #{$content-max}) / 2));
    }
  }

  &__eyebrow {
    @include eyebrow;
    margin: 0 0 3px;

    @media (min-width: $break-medium) {
      margin: 0 0 18px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 28px;
    }
  }

  &__header {
    margin: 0 0 6px;

    @media (min-width: $break-medium) {
      margin: 0 0 10px;
    }

    @media (min-width: $break-large) {
      margin: 0;
    }
  }

  &__subtitle {
    margin: 0 0 22px;

    @media (min-width: $break-medium) {
      margin: 0 0 39px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 31px;
    }
  }

  &__wrapper {
    @media (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__image {
    margin: 0 0 16px;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 11px);
      margin: 0;
    }

    @media (min-width: $break-large) {
      flex-basis: calc(48% - 11px);
    }

    .student-of-the-month__links {
      @media (max-width: ($break-medium - 1)) {
        display: none;
      }
    }
  }

  &__img {
    @include cowley-sticker;
    padding: 100% 0 0;
    height: 0;

    @media (min-width: $break-medium) {
      margin: 0 0 30px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 34px;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content {
    position: relative;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 12px);
    }

    @media (min-width: $break-large) {
      flex-basis: calc(48% - 17px);
    }

    .student-of-the-month__links {
      @media (min-width: $break-medium) {
        display: none;
      }
    }
  }

  &__quote {
    margin: 0 0 13px;

    @media (min-width: $break-medium) {
      margin: 0 0 21px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 10px;
    }

    .quote {
      margin: 0;
      padding: 13px 28px 6px;

      @media (min-width: $break-large) {
        padding: 26px 28px 13px;
      }

      &::before,
      &::after {
        @media (min-width: $break-medium) {
          height: 93%;
          top: 0;
        }

        @media (min-width: $break-large) {
          height: 98.5%;
          top: 3px;
        }
      }

      &::before {
        @media (min-width: $break-large) {
          left: -7px;
        }
      }

      &::after {
        @media (min-width: $break-medium) {
          right: -9px;
        }

        @media (min-width: $break-large) {
          right: -3px;
        }
      }

      &__copy {
        &::before {
          top: -5px;
          left: -40px;

          @media (min-width: $break-large) {
            top: -7px;
            left: -33px;
          }
        }

        &::after {
          top: 2px;
          left: -38px;

          @media (min-width: $break-large) {
            top: 0;
            left: -41px;
          }
        }
      }

    }
  }

  &__body {
    margin: 0 0 18px;

    @media (min-width: $break-medium) {
      margin: 0 0 31px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 36px;
      padding: 0 28px;
    }

    &--large {
      @include body-text-intro;
    }
  }

  &__cta {
    margin: 0 0 32px;

    @media (min-width: $break-medium) {
      margin: 0;
    }

    @media (min-width: $break-large) {
      padding: 0 28px;
    }

    .cta {
      margin: 0;
    }
  }

  &__links {
    .cta {
      margin: 0;
    }
  }
  
  &__link {
    margin: 0 0 22px;

    @media (min-width: $break-medium) {
      margin: 0 0 25px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 16px;
    }
  
    &:last-child {
      margin: 0;
    }
  }
}