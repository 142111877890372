.home-nae {
  &.js-active {
    .home-nae__top {
      &::after {
        opacity: 1;
      }
    }
  }

  &__top {
    position: relative;
    margin-bottom: 1rem;

    @media screen and (min-width: $break-medium) {
      margin-bottom: 3rem;
    }

    @media screen and (min-width: $break-large) {
      margin-bottom: 4.9rem;
    }

    &:after {
      content: '';
      position: absolute;
      right: -13px;
      bottom: -19px;
      width: 61px;
      height: 61px;
      background-image: url('../assets/img/footer-sock-arrow-perforated.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      filter: invert(1);
      transform: rotate(52deg);
      z-index: 1;
      opacity: 0;
      transition: opacity 0.15s;

      @media screen and (min-width: $break-medium) {
        right: -24px;
        bottom: -43px;
        width: 125px;
        height: 125px;
        transform: rotate(45deg);
      }

      @media screen and (min-width: $break-large) {
        right: -30px;
        bottom: -64px;
        width: 140px;
        height: 140px;
      }
    }
  }

  &__header {
    line-height: 1;
    margin: 0 0 0.7rem;
    max-width: 340px;

    @media screen and (min-width: $break-medium) {
      line-height: 1.1;
      margin: 0 0 1.1rem;
      max-width: calc(100% - 170px);
    }

    @media screen and (min-width: $break-large) {
      max-width: calc(100% - 67px);
    }
  }

  &__content {
    @media screen and (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__subtitle {
    position: relative;
    font-family: $font-matrice;
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.3;
    display: inline-block;
    padding: 0 11px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: calc(100% + 10px);
      transform: translateY(-50%);
      background-image: url('../assets/icons/bracket-tall-cards--left.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media screen and (min-width: $break-medium) {
        width: 17px;
        height: calc(100% + 20px);
      }

      @media screen and (min-width: $break-large) {
        width: 11px;
        height: calc(100% + 14px);    
      }
    }

    &:after {
      left: auto;
      right: 0;
      background-image: url('../assets/icons/bracket-tall-cards--right.svg');
    }

    @media screen and (min-width: $break-medium) {
      width: auto;
      max-width: calc(100% - 170px);
      font-size: 2rem;
      padding: 0 22px;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2.8rem;
      padding: 0 17px;
    }
  }

  &__col {
    &:not(:last-of-type) {
      @media screen and (max-width: $break-medium - 1) {
        margin-bottom: 3rem;
      }

      @media screen and (min-width: $break-large) {
        flex: 0 0 50%;
      }
    }

    @media screen and (min-width: $break-medium) {
      flex: 0 0 48%;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 45%;
    }
  }

  .news {
    display: block;
    position: relative;
    background-color: $color-orange;
    transition: background-color 0.3s ease;

    &__img {
      position: relative;
      transition: opacity 0.3s ease;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -112px;
        width: 175px;
        height: 41px;
        background-image: url('../assets/img/tape-black.png');
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%) rotate(-200.5deg);
        z-index: 1;

        @media screen and (min-width: $break-medium) {
          top: calc(50% + 7px);
          left: -134px;
          width: 211px;
          height: 68px;
        }

        @media screen and (min-width: $break-large) {
          top: calc(50% + 14px);
          left: -154px;
        }
      }

      img {
        width: 100%;
      }
    }

    &__content {
      padding: 2.2rem 20px;

      @media screen and (min-width: $break-large) {
        padding: 4.2rem 23px;
      }
    }

    &__header,
    &__header a {
      font-size: 1.5rem;
      line-height: 1.2;
      font-weight: 800;
      margin: 0 0 1.2rem;
      transition: opacity 0.3s ease;

      @media screen and (min-width: $break-medium) {
        font-size: 2rem;
        margin: 0 0 0.9rem;
        line-height: 1.3;
      }

      @media screen and (min-width: $break-large) {
        font-size: 2.8rem;
        margin: 0 0 1.4rem;
      }
    }

    &__header {
      a {
        &:hover {
          opacity: 0.5;
        }

        &:focus {
          @include dark-bg-focus;
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }

    &__date {
      font-family: $font-roboto;
      font-size: 1.2rem;
      font-weight: 400;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }
    }

    &:hover {
      background-color: $color-orange-dark;

      .news__img {
        opacity: 0.8;
      }
    }
  }

  &__events {
    .slick-arrow {
      top: auto;
      bottom: 0;
      background: $color-orange-button;

      &:hover {
        background: $color-yellow;
      }
    }
  }

  .event {
    position: relative;

    &:not(:last-of-type) {
      @media screen and (min-width: $break-medium) {
        margin-bottom: 1.8rem;
      }
    }

    &__inner {
      display: flex;

      @media screen and (max-width: $break-medium - 1) {
        min-height: 148px;
      }
    }

    &__left {
      flex: 0 0 92px;
      text-align: center;
      background-color: $color-yellow;
      padding: 1.5rem 0;

      @media screen and (min-width: $break-large) {
        flex: 0 0 106px;
        padding: 2.4rem 0;
      }
    }

    &__right {
      flex: 1 1 auto;
      padding: 1.5rem 15px;
      background-image: url('../assets/img/wrinkle-light.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media screen and (min-width: $break-large) {
        padding: 2.4rem 15px;
      }
    }

    &__date {
      font-family: $font-matrice;
      font-weight: 800;
      font-size: 4rem;
      line-height: 1;

      @media screen and (min-width: $break-medium) {
        font-size: 4.5rem;
      }
    }

    &__month {
      font-family: $font-roboto;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }
    }

    &__header {
      margin: 0 0 0.3rem;
      font-family: $font-matrice;
      font-size: 1.2rem;
      font-weight: 800;
      line-height: 1.4;
      transition: opacity 0.3s ease;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }

      a {
        font-family: $font-matrice;
        font-size: 1.2rem;
        font-weight: 800;
        line-height: 1.4;

        @media screen and (min-width: $break-medium) {
          font-size: 1.4rem;
        }
  
        @media screen and (min-width: $break-large) {
          font-size: 1.6rem;
        }

        &:hover {
          span::before {
            opacity: 1;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        span {
          display: inline-block;
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 6px;
            position: absolute;
            bottom: -3px;
            left: 0;
            background-image: url('./../assets/icons/underline-black--repeating.svg');
            background-size: contain;
            background-repeat: repeat-x;
            opacity: 0;
            transition: opacity 0.15s;
          }
        }
      }
    }

    &__loc {
      font-family: $font-roboto;
      font-weight: 400;
      font-size: 1.2rem;

      @media screen and (min-width: $break-medium) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: $break-large) {
        font-size: 1.6rem;
      }
    }
  }

  .orange {
    color: $color-orange;
  }

  .cta {
    margin-bottom: 0;
  }
}