.home-support {
  margin-bottom: 4.4rem;

  @media screen and (min-width: $break-medium) {
    margin-bottom: 6.1rem;
  }

  @media screen and (min-width: $break-large) {
    margin-bottom: 9.8rem;
  }

  &__top {
    position: relative;
    text-align: right;
    padding-bottom: 1rem;

    @media screen and (min-width: $break-large) {
      padding-bottom: 6.4rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100vw;
      height: 100%;
      background-image: url('../assets/img/rip-orange-hero-home.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      z-index: -1;
      transform: translateX(-50%);
    }
  }

  &__header {
    color: $color-white;
    line-height: 1;
    margin: 0 0 0.7rem;
    max-width: 340px;
    padding-top: 15rem;
    margin-left: auto;

    @media screen and (min-width: $break-medium) {
      line-height: 1.1;
      margin: 0 0 1.1rem auto;
      max-width: 440px;
    }

    @media screen and (min-width: $break-large) {
      padding-top: 17vw;
      margin: 0 0 2.1rem auto;
      max-width: 510px;
    }
  }

  &__subtitle {
    position: relative;
    font-family: $font-matrice;
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.3;
    display: inline-block;
    padding: 0 11px;
    width: 206px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: calc(100% + 10px);
      transform: translateY(-50%);
      background-image: url('../assets/icons/parentheses-left-white.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media screen and (min-width: $break-medium) {
        width: 17px;
        height: calc(100% + 20px);
      }

      @media screen and (min-width: $break-large) {
        width: 11px;
        height: calc(100% + 14px);
        background-image: url('../assets/icons/parentheses-left-white--lg.svg');
      }
    }

    &:after {
      left: auto;
      right: 0;
      transform: scaleX(-1) translateY(-50%);
    }

    @media screen and (min-width: $break-medium) {
      width: auto;
      max-width: calc(100% - 100px);
      font-size: 2rem;
      padding: 0 22px;
    }

    @media screen and (min-width: $break-large) {
      font-size: 2.8rem;
      max-width: calc(100% - 170px);
      padding: 0 17px;
    }
  }

  &__cards {
    position: relative;
    margin-top: 4.3rem;
    background-color: $color-yellow-light;

    @media screen and (min-width: $break-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5.7rem;
    }

    @media screen and (min-width: $break-large) {
      flex-wrap: unset;
      margin: 0 -50px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: -4.3rem;
      width: 100vw;
      height: 102px;
      background-color: $color-orange-dark;
      transform: translateX(-50%);
      z-index: -1;

      @media screen and (min-width: $break-medium) {
        top: -5.7rem;
        height: 156px;
      }

      @media screen and (min-width: $break-large) {
        height: 171px;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 162px;
      height: 40px;
      position: absolute;
      top: -2px;
      right: -89px;
      z-index: 1;
      background-image: url('./../assets/img/tape-black.png');
      background-size: cover;
      transform: rotate(201deg);

      @media screen and (min-width: $break-medium) {
        width: 227px;
        height: 58px;
        top: -5px;
        right: -146px;
      }

      @media screen and (min-width: $break-large) {
        top: -12px;
        right: -106px;
      }
    }
  }

  .black {
    color: $color-black;
  }

  .card {
    position: relative;
    padding: 4.2rem 20px 6.9rem;

    @media screen and (min-width: $break-medium) {
      flex: 0 0 50%;
      padding: 5.2rem 30px 5.9rem;
    }

    @media screen and (min-width: $break-large) {
      flex: 0 0 33%;
      padding: 6.5rem 30px 5.9rem;
    }

    &:first-of-type {
      @media screen and (min-width: $break-large) {
        padding-left: 5rem;

        .card__icon {
          left: 50px;
        }
      }
    }

    &:last-of-type {
      @media screen and (max-width: $break-medium - 1) {
        padding-bottom: 2rem;
      }

      @media screen and (min-width: $break-large) {
        padding-right: 5rem;
      }
    }

    &__icon {
      position: absolute;
      top: -31px;
      left: 7px;
      width: 60px;
      height: 60px;
      background-color: $color-yellow;
      border-radius: 60px;

      @media screen and (min-width: $break-medium) {
        top: -39px;
        left: 18px;
        width: 76px;
        height: 76px;
      }

      @media screen and (min-width: $break-large) {
        top: -30px;
        left: 30px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px;
        height: 34px;
        transform: translate(-50%, -50%);

        @media screen and (min-width: $break-medium) {
          width: 44px;
          height: 44px;
        }
      }
    }

    &__body {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 1rem;

      @media screen and (min-width: $break-medium) {
        font-size: 1.8rem;
      }

      @media screen and (min-width: $break-large) {
        margin-bottom: 1.7rem;
      }

      strong {
        font-family: $font-matrice;
        font-size: 1.4rem;
        line-height: 2;
        font-weight: 800;
        text-transform: uppercase;

        @media screen and (min-width: $break-medium) {
          font-size: 1.6rem;
        }
      }

      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 1rem;
      }

      h3 {
        @include h4;
      }

      h4 {
        @include h5;
      }

      h5 {
        @include h6;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;

      a {
        font-family: $font-matrice;
        display: inline-block;
        font-weight: 700;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-right: auto;

        @media screen and (min-width: $break-medium) {
          font-size: 1.6rem;
        }

        @media screen and (min-width: $break-large) {
          margin-bottom: 1rem;
        }

        &:focus {
          @include light-bg-focus;
        }
      }
    }
  }
}