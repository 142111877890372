/**
 * @file
 * Generic Block: Image list
 *
 * Styles for Generic Block: Image list object.
 */

.genimglist {
  margin: 71px 0 60px;

  @media (min-width: $break-medium) {
    margin: 93px 0 60px;
  }

  @media (min-width: $break-large) {
    margin: 100px 0;
  }

  &__intro {
    margin: 20px 0 40px;

    @media (min-width: $break-medium) {
      margin: 20px 0 32px;
    }

    @media (min-width: $break-large) {
      margin: -5px 0 40px;
    }
  }

  &__item {
    margin: 0 0 43px;

    @media (min-width: $break-medium) {
      display: flex;
      justify-content: space-between;
      margin: 0 0 21px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 40px;
    }
  }

  &__img {
    margin: 0 0 19px;
    padding: 75.445% 0 0;
    height: 0;
    position: relative;

    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 11px);
      margin: 0;
      padding: 36.334% 0 0;
    }

    @media (min-width: $break-large) {
      flex-basis: calc(45.625% - 15px);
      padding: 32.75% 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content {
    @media (min-width: $break-medium) {
      flex-basis: calc(50% - 11px);
    }

    @media (min-width: $break-large) {
      flex-basis: calc(54.375% - 15px);
    }
  }

  &__title {
    margin: 0 0 27px;

    @media (min-width: $break-medium) {
      margin: 0 0 15px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 8px;
    }

    h3 {
      @include h4;
      margin: 0;

      a {
        @include patternAfterArrow(1.5rem, 1.4rem, 1.4rem);
        padding: 0;
        font-weight: 800;
        background: none;

        &:focus {
          @include light-bg-focus;
        }
      }
    }
  }

  &__subtitle {
    @include body-text-intro;
    margin: 0 0 13px;

    @media (min-width: $break-medium) {
      margin: 0 0 9px;
    }

    @media (min-width: $break-large) {
      margin: 0 0 14px;
    }
  }

  &__body {
    a {
      &:hover {
        background-position-y: 24px;

        @media (min-width: $break-medium) {
          background-position-y: 26px;
        }
      }

      background-position-y: 20px;

      @media (min-width: $break-medium) {
        background-position-y: 22px;
      }

      &:focus {
        @include light-bg-focus;
      }
    }
  }
}
